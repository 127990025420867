
@media (min-width: 992px) {
  .container-lg, .container-mdlg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-mdlg, .container-md, .container-sm, .container {
    max-width: 1028px;
  }
}

@media (min-width: 1600px) {
  .container-xxl, .container-xl, .container-lg, .container-mdlg, .container-md, .container-sm, .container {
    max-width: 1432px;
  }
}

@media (min-width: 769px) {
  .col-mdlg {
    flex: 1 0 0%;
  }
  .row-cols-mdlg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-mdlg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-mdlg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-mdlg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-mdlg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-mdlg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-mdlg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-mdlg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-mdlg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-mdlg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-mdlg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-mdlg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-mdlg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-mdlg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-mdlg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-mdlg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-mdlg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-mdlg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-mdlg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-mdlg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-mdlg-0 {
    margin-left: 0;
  }
  .offset-mdlg-1 {
    margin-left: 8.33333333%;
  }
  .offset-mdlg-2 {
    margin-left: 16.66666667%;
  }
  .offset-mdlg-3 {
    margin-left: 25%;
  }
  .offset-mdlg-4 {
    margin-left: 33.33333333%;
  }
  .offset-mdlg-5 {
    margin-left: 41.66666667%;
  }
  .offset-mdlg-6 {
    margin-left: 50%;
  }
  .offset-mdlg-7 {
    margin-left: 58.33333333%;
  }
  .offset-mdlg-8 {
    margin-left: 66.66666667%;
  }
  .offset-mdlg-9 {
    margin-left: 75%;
  }
  .offset-mdlg-10 {
    margin-left: 83.33333333%;
  }
  .offset-mdlg-11 {
    margin-left: 91.66666667%;
  }
  .g-mdlg-sm,
.gx-mdlg-sm {
    --bs-gutter-x: 0.33335rem;
  }
  .g-mdlg-sm,
.gy-mdlg-sm {
    --bs-gutter-y: 0.33335rem;
  }
  .g-mdlg-3px,
.gx-mdlg-3px {
    --bs-gutter-x: 3px;
  }
  .g-mdlg-3px,
.gy-mdlg-3px {
    --bs-gutter-y: 3px;
  }
  .g-mdlg-4px,
.gx-mdlg-4px {
    --bs-gutter-x: 4px;
  }
  .g-mdlg-4px,
.gy-mdlg-4px {
    --bs-gutter-y: 4px;
  }
  .g-mdlg-8px,
.gx-mdlg-8px {
    --bs-gutter-x: 8px;
  }
  .g-mdlg-8px,
.gy-mdlg-8px {
    --bs-gutter-y: 8px;
  }
  .g-mdlg-12px,
.gx-mdlg-12px {
    --bs-gutter-x: 12px;
  }
  .g-mdlg-12px,
.gy-mdlg-12px {
    --bs-gutter-y: 12px;
  }
  .g-mdlg-15px,
.gx-mdlg-15px {
    --bs-gutter-x: 15px;
  }
  .g-mdlg-15px,
.gy-mdlg-15px {
    --bs-gutter-y: 15px;
  }
  .g-mdlg-16px,
.gx-mdlg-16px {
    --bs-gutter-x: 16px;
  }
  .g-mdlg-16px,
.gy-mdlg-16px {
    --bs-gutter-y: 16px;
  }
  .g-mdlg-18px,
.gx-mdlg-18px {
    --bs-gutter-x: 18px;
  }
  .g-mdlg-18px,
.gy-mdlg-18px {
    --bs-gutter-y: 18px;
  }
  .g-mdlg-24px,
.gx-mdlg-24px {
    --bs-gutter-x: 24px;
  }
  .g-mdlg-24px,
.gy-mdlg-24px {
    --bs-gutter-y: 24px;
  }
  .g-mdlg-25px,
.gx-mdlg-25px {
    --bs-gutter-x: 25px;
  }
  .g-mdlg-25px,
.gy-mdlg-25px {
    --bs-gutter-y: 25px;
  }
  .g-mdlg-30px,
.gx-mdlg-30px {
    --bs-gutter-x: 30px;
  }
  .g-mdlg-30px,
.gy-mdlg-30px {
    --bs-gutter-y: 30px;
  }
  .g-mdlg-32px,
.gx-mdlg-32px {
    --bs-gutter-x: 32px;
  }
  .g-mdlg-32px,
.gy-mdlg-32px {
    --bs-gutter-y: 32px;
  }
  .g-mdlg-57px,
.gx-mdlg-57px {
    --bs-gutter-x: 57px;
  }
  .g-mdlg-57px,
.gy-mdlg-57px {
    --bs-gutter-y: 57px;
  }
  .g-mdlg-n15,
.gx-mdlg-n15 {
    --bs-gutter-x: -15px;
  }
  .g-mdlg-n15,
.gy-mdlg-n15 {
    --bs-gutter-y: -15px;
  }
  .g-mdlg-n5,
.gx-mdlg-n5 {
    --bs-gutter-x: -5px;
  }
  .g-mdlg-n5,
.gy-mdlg-n5 {
    --bs-gutter-y: -5px;
  }
  .g-mdlg-3vh,
.gx-mdlg-3vh {
    --bs-gutter-x: 3vh;
  }
  .g-mdlg-3vh,
.gy-mdlg-3vh {
    --bs-gutter-y: 3vh;
  }
  .g-mdlg-10vh,
.gx-mdlg-10vh {
    --bs-gutter-x: 10vh;
  }
  .g-mdlg-10vh,
.gy-mdlg-10vh {
    --bs-gutter-y: 10vh;
  }
  .g-mdlg-0,
.gx-mdlg-0 {
    --bs-gutter-x: 0;
  }
  .g-mdlg-0,
.gy-mdlg-0 {
    --bs-gutter-y: 0;
  }
  .g-mdlg-1,
.gx-mdlg-1 {
    --bs-gutter-x: 0.6667rem;
  }
  .g-mdlg-1,
.gy-mdlg-1 {
    --bs-gutter-y: 0.6667rem;
  }
  .g-mdlg-2,
.gx-mdlg-2 {
    --bs-gutter-x: 1.3334rem;
  }
  .g-mdlg-2,
.gy-mdlg-2 {
    --bs-gutter-y: 1.3334rem;
  }
  .g-mdlg-3,
.gx-mdlg-3 {
    --bs-gutter-x: 2.0001rem;
  }
  .g-mdlg-3,
.gy-mdlg-3 {
    --bs-gutter-y: 2.0001rem;
  }
  .g-mdlg-4,
.gx-mdlg-4 {
    --bs-gutter-x: 2.6668rem;
  }
  .g-mdlg-4,
.gy-mdlg-4 {
    --bs-gutter-y: 2.6668rem;
  }
  .g-mdlg-5,
.gx-mdlg-5 {
    --bs-gutter-x: 3.3335rem;
  }
  .g-mdlg-5,
.gy-mdlg-5 {
    --bs-gutter-y: 3.3335rem;
  }
  .g-mdlg-6,
.gx-mdlg-6 {
    --bs-gutter-x: 4.0002rem;
  }
  .g-mdlg-6,
.gy-mdlg-6 {
    --bs-gutter-y: 4.0002rem;
  }
  .g-mdlg-7,
.gx-mdlg-7 {
    --bs-gutter-x: 4.6669rem;
  }
  .g-mdlg-7,
.gy-mdlg-7 {
    --bs-gutter-y: 4.6669rem;
  }
  .g-mdlg-8,
.gx-mdlg-8 {
    --bs-gutter-x: 5.3336rem;
  }
  .g-mdlg-8,
.gy-mdlg-8 {
    --bs-gutter-y: 5.3336rem;
  }
  .g-mdlg-9,
.gx-mdlg-9 {
    --bs-gutter-x: 6.0003rem;
  }
  .g-mdlg-9,
.gy-mdlg-9 {
    --bs-gutter-y: 6.0003rem;
  }
  .g-mdlg-10,
.gx-mdlg-10 {
    --bs-gutter-x: 6.667rem;
  }
  .g-mdlg-10,
.gy-mdlg-10 {
    --bs-gutter-y: 6.667rem;
  }
  .g-mdlg-gutter,
.gx-mdlg-gutter {
    --bs-gutter-x: 24px;
  }
  .g-mdlg-gutter,
.gy-mdlg-gutter {
    --bs-gutter-y: 24px;
  }
  .g-mdlg-out-container,
.gx-mdlg-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }
  .g-mdlg-out-container,
.gy-mdlg-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-sm,
.gx-lg-sm {
    --bs-gutter-x: 0.33335rem;
  }
  .g-lg-sm,
.gy-lg-sm {
    --bs-gutter-y: 0.33335rem;
  }
  .g-lg-3px,
.gx-lg-3px {
    --bs-gutter-x: 3px;
  }
  .g-lg-3px,
.gy-lg-3px {
    --bs-gutter-y: 3px;
  }
  .g-lg-4px,
.gx-lg-4px {
    --bs-gutter-x: 4px;
  }
  .g-lg-4px,
.gy-lg-4px {
    --bs-gutter-y: 4px;
  }
  .g-lg-8px,
.gx-lg-8px {
    --bs-gutter-x: 8px;
  }
  .g-lg-8px,
.gy-lg-8px {
    --bs-gutter-y: 8px;
  }
  .g-lg-12px,
.gx-lg-12px {
    --bs-gutter-x: 12px;
  }
  .g-lg-12px,
.gy-lg-12px {
    --bs-gutter-y: 12px;
  }
  .g-lg-15px,
.gx-lg-15px {
    --bs-gutter-x: 15px;
  }
  .g-lg-15px,
.gy-lg-15px {
    --bs-gutter-y: 15px;
  }
  .g-lg-16px,
.gx-lg-16px {
    --bs-gutter-x: 16px;
  }
  .g-lg-16px,
.gy-lg-16px {
    --bs-gutter-y: 16px;
  }
  .g-lg-18px,
.gx-lg-18px {
    --bs-gutter-x: 18px;
  }
  .g-lg-18px,
.gy-lg-18px {
    --bs-gutter-y: 18px;
  }
  .g-lg-24px,
.gx-lg-24px {
    --bs-gutter-x: 24px;
  }
  .g-lg-24px,
.gy-lg-24px {
    --bs-gutter-y: 24px;
  }
  .g-lg-25px,
.gx-lg-25px {
    --bs-gutter-x: 25px;
  }
  .g-lg-25px,
.gy-lg-25px {
    --bs-gutter-y: 25px;
  }
  .g-lg-30px,
.gx-lg-30px {
    --bs-gutter-x: 30px;
  }
  .g-lg-30px,
.gy-lg-30px {
    --bs-gutter-y: 30px;
  }
  .g-lg-32px,
.gx-lg-32px {
    --bs-gutter-x: 32px;
  }
  .g-lg-32px,
.gy-lg-32px {
    --bs-gutter-y: 32px;
  }
  .g-lg-57px,
.gx-lg-57px {
    --bs-gutter-x: 57px;
  }
  .g-lg-57px,
.gy-lg-57px {
    --bs-gutter-y: 57px;
  }
  .g-lg-n15,
.gx-lg-n15 {
    --bs-gutter-x: -15px;
  }
  .g-lg-n15,
.gy-lg-n15 {
    --bs-gutter-y: -15px;
  }
  .g-lg-n5,
.gx-lg-n5 {
    --bs-gutter-x: -5px;
  }
  .g-lg-n5,
.gy-lg-n5 {
    --bs-gutter-y: -5px;
  }
  .g-lg-3vh,
.gx-lg-3vh {
    --bs-gutter-x: 3vh;
  }
  .g-lg-3vh,
.gy-lg-3vh {
    --bs-gutter-y: 3vh;
  }
  .g-lg-10vh,
.gx-lg-10vh {
    --bs-gutter-x: 10vh;
  }
  .g-lg-10vh,
.gy-lg-10vh {
    --bs-gutter-y: 10vh;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.6667rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.6667rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 1.3334rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 1.3334rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 2.0001rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 2.0001rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 2.6668rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 2.6668rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3.3335rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3.3335rem;
  }
  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 4.0002rem;
  }
  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 4.0002rem;
  }
  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 4.6669rem;
  }
  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 4.6669rem;
  }
  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 5.3336rem;
  }
  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 5.3336rem;
  }
  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 6.0003rem;
  }
  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 6.0003rem;
  }
  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 6.667rem;
  }
  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 6.667rem;
  }
  .g-lg-gutter,
.gx-lg-gutter {
    --bs-gutter-x: 24px;
  }
  .g-lg-gutter,
.gy-lg-gutter {
    --bs-gutter-y: 24px;
  }
  .g-lg-out-container,
.gx-lg-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }
  .g-lg-out-container,
.gy-lg-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-sm,
.gx-xl-sm {
    --bs-gutter-x: 0.33335rem;
  }
  .g-xl-sm,
.gy-xl-sm {
    --bs-gutter-y: 0.33335rem;
  }
  .g-xl-3px,
.gx-xl-3px {
    --bs-gutter-x: 3px;
  }
  .g-xl-3px,
.gy-xl-3px {
    --bs-gutter-y: 3px;
  }
  .g-xl-4px,
.gx-xl-4px {
    --bs-gutter-x: 4px;
  }
  .g-xl-4px,
.gy-xl-4px {
    --bs-gutter-y: 4px;
  }
  .g-xl-8px,
.gx-xl-8px {
    --bs-gutter-x: 8px;
  }
  .g-xl-8px,
.gy-xl-8px {
    --bs-gutter-y: 8px;
  }
  .g-xl-12px,
.gx-xl-12px {
    --bs-gutter-x: 12px;
  }
  .g-xl-12px,
.gy-xl-12px {
    --bs-gutter-y: 12px;
  }
  .g-xl-15px,
.gx-xl-15px {
    --bs-gutter-x: 15px;
  }
  .g-xl-15px,
.gy-xl-15px {
    --bs-gutter-y: 15px;
  }
  .g-xl-16px,
.gx-xl-16px {
    --bs-gutter-x: 16px;
  }
  .g-xl-16px,
.gy-xl-16px {
    --bs-gutter-y: 16px;
  }
  .g-xl-18px,
.gx-xl-18px {
    --bs-gutter-x: 18px;
  }
  .g-xl-18px,
.gy-xl-18px {
    --bs-gutter-y: 18px;
  }
  .g-xl-24px,
.gx-xl-24px {
    --bs-gutter-x: 24px;
  }
  .g-xl-24px,
.gy-xl-24px {
    --bs-gutter-y: 24px;
  }
  .g-xl-25px,
.gx-xl-25px {
    --bs-gutter-x: 25px;
  }
  .g-xl-25px,
.gy-xl-25px {
    --bs-gutter-y: 25px;
  }
  .g-xl-30px,
.gx-xl-30px {
    --bs-gutter-x: 30px;
  }
  .g-xl-30px,
.gy-xl-30px {
    --bs-gutter-y: 30px;
  }
  .g-xl-32px,
.gx-xl-32px {
    --bs-gutter-x: 32px;
  }
  .g-xl-32px,
.gy-xl-32px {
    --bs-gutter-y: 32px;
  }
  .g-xl-57px,
.gx-xl-57px {
    --bs-gutter-x: 57px;
  }
  .g-xl-57px,
.gy-xl-57px {
    --bs-gutter-y: 57px;
  }
  .g-xl-n15,
.gx-xl-n15 {
    --bs-gutter-x: -15px;
  }
  .g-xl-n15,
.gy-xl-n15 {
    --bs-gutter-y: -15px;
  }
  .g-xl-n5,
.gx-xl-n5 {
    --bs-gutter-x: -5px;
  }
  .g-xl-n5,
.gy-xl-n5 {
    --bs-gutter-y: -5px;
  }
  .g-xl-3vh,
.gx-xl-3vh {
    --bs-gutter-x: 3vh;
  }
  .g-xl-3vh,
.gy-xl-3vh {
    --bs-gutter-y: 3vh;
  }
  .g-xl-10vh,
.gx-xl-10vh {
    --bs-gutter-x: 10vh;
  }
  .g-xl-10vh,
.gy-xl-10vh {
    --bs-gutter-y: 10vh;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.6667rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.6667rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 1.3334rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 1.3334rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 2.0001rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 2.0001rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 2.6668rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 2.6668rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3.3335rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3.3335rem;
  }
  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 4.0002rem;
  }
  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 4.0002rem;
  }
  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 4.6669rem;
  }
  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 4.6669rem;
  }
  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 5.3336rem;
  }
  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 5.3336rem;
  }
  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 6.0003rem;
  }
  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 6.0003rem;
  }
  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 6.667rem;
  }
  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 6.667rem;
  }
  .g-xl-gutter,
.gx-xl-gutter {
    --bs-gutter-x: 24px;
  }
  .g-xl-gutter,
.gy-xl-gutter {
    --bs-gutter-y: 24px;
  }
  .g-xl-out-container,
.gx-xl-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }
  .g-xl-out-container,
.gy-xl-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 1600px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-sm,
.gx-xxl-sm {
    --bs-gutter-x: 0.33335rem;
  }
  .g-xxl-sm,
.gy-xxl-sm {
    --bs-gutter-y: 0.33335rem;
  }
  .g-xxl-3px,
.gx-xxl-3px {
    --bs-gutter-x: 3px;
  }
  .g-xxl-3px,
.gy-xxl-3px {
    --bs-gutter-y: 3px;
  }
  .g-xxl-4px,
.gx-xxl-4px {
    --bs-gutter-x: 4px;
  }
  .g-xxl-4px,
.gy-xxl-4px {
    --bs-gutter-y: 4px;
  }
  .g-xxl-8px,
.gx-xxl-8px {
    --bs-gutter-x: 8px;
  }
  .g-xxl-8px,
.gy-xxl-8px {
    --bs-gutter-y: 8px;
  }
  .g-xxl-12px,
.gx-xxl-12px {
    --bs-gutter-x: 12px;
  }
  .g-xxl-12px,
.gy-xxl-12px {
    --bs-gutter-y: 12px;
  }
  .g-xxl-15px,
.gx-xxl-15px {
    --bs-gutter-x: 15px;
  }
  .g-xxl-15px,
.gy-xxl-15px {
    --bs-gutter-y: 15px;
  }
  .g-xxl-16px,
.gx-xxl-16px {
    --bs-gutter-x: 16px;
  }
  .g-xxl-16px,
.gy-xxl-16px {
    --bs-gutter-y: 16px;
  }
  .g-xxl-18px,
.gx-xxl-18px {
    --bs-gutter-x: 18px;
  }
  .g-xxl-18px,
.gy-xxl-18px {
    --bs-gutter-y: 18px;
  }
  .g-xxl-24px,
.gx-xxl-24px {
    --bs-gutter-x: 24px;
  }
  .g-xxl-24px,
.gy-xxl-24px {
    --bs-gutter-y: 24px;
  }
  .g-xxl-25px,
.gx-xxl-25px {
    --bs-gutter-x: 25px;
  }
  .g-xxl-25px,
.gy-xxl-25px {
    --bs-gutter-y: 25px;
  }
  .g-xxl-30px,
.gx-xxl-30px {
    --bs-gutter-x: 30px;
  }
  .g-xxl-30px,
.gy-xxl-30px {
    --bs-gutter-y: 30px;
  }
  .g-xxl-32px,
.gx-xxl-32px {
    --bs-gutter-x: 32px;
  }
  .g-xxl-32px,
.gy-xxl-32px {
    --bs-gutter-y: 32px;
  }
  .g-xxl-57px,
.gx-xxl-57px {
    --bs-gutter-x: 57px;
  }
  .g-xxl-57px,
.gy-xxl-57px {
    --bs-gutter-y: 57px;
  }
  .g-xxl-n15,
.gx-xxl-n15 {
    --bs-gutter-x: -15px;
  }
  .g-xxl-n15,
.gy-xxl-n15 {
    --bs-gutter-y: -15px;
  }
  .g-xxl-n5,
.gx-xxl-n5 {
    --bs-gutter-x: -5px;
  }
  .g-xxl-n5,
.gy-xxl-n5 {
    --bs-gutter-y: -5px;
  }
  .g-xxl-3vh,
.gx-xxl-3vh {
    --bs-gutter-x: 3vh;
  }
  .g-xxl-3vh,
.gy-xxl-3vh {
    --bs-gutter-y: 3vh;
  }
  .g-xxl-10vh,
.gx-xxl-10vh {
    --bs-gutter-x: 10vh;
  }
  .g-xxl-10vh,
.gy-xxl-10vh {
    --bs-gutter-y: 10vh;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.6667rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.6667rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 1.3334rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 1.3334rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 2.0001rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 2.0001rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 2.6668rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 2.6668rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3.3335rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3.3335rem;
  }
  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 4.0002rem;
  }
  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 4.0002rem;
  }
  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 4.6669rem;
  }
  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 4.6669rem;
  }
  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 5.3336rem;
  }
  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 5.3336rem;
  }
  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 6.0003rem;
  }
  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 6.0003rem;
  }
  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 6.667rem;
  }
  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 6.667rem;
  }
  .g-xxl-gutter,
.gx-xxl-gutter {
    --bs-gutter-x: 24px;
  }
  .g-xxl-gutter,
.gy-xxl-gutter {
    --bs-gutter-y: 24px;
  }
  .g-xxl-out-container,
.gx-xxl-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }
  .g-xxl-out-container,
.gy-xxl-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 769px) {
  .font-mdlg-sans-serif {
    font-family: "arial-regular", sans-serif !important;
  }
  .font-mdlg-sans-serif-italic {
    font-family: "arial-italic", sans-serif !important;
  }
  .font-mdlg-sans-serif-bolditalic {
    font-family: "arial-bolditalic", sans-serif !important;
  }
  .font-mdlg-sans-serif-bold {
    font-family: "arial-bold", sans-serif !important;
  }
  .d-mdlg-inline {
    display: inline !important;
  }
  .d-mdlg-inline-block {
    display: inline-block !important;
  }
  .d-mdlg-block {
    display: block !important;
  }
  .d-mdlg-grid {
    display: grid !important;
  }
  .d-mdlg-table {
    display: table !important;
  }
  .d-mdlg-table-row {
    display: table-row !important;
  }
  .d-mdlg-table-cell {
    display: table-cell !important;
  }
  .d-mdlg-flex {
    display: flex !important;
  }
  .d-mdlg-inline-flex {
    display: inline-flex !important;
  }
  .d-mdlg-none {
    display: none !important;
  }
  .bg-mdlg-primary {
    --bs-bg-opacity: 1;
    background-color: #0a3255 !important;
  }
  .bg-mdlg-primary-light {
    --bs-bg-opacity: 1;
    background-color: rgba(10, 50, 85, 0.5) !important;
  }
  .bg-mdlg-secondary {
    --bs-bg-opacity: 1;
    background-color: #da262e !important;
  }
  .bg-mdlg-secondary-light {
    --bs-bg-opacity: 1;
    background-color: rgba(218, 38, 46, 0.5) !important;
  }
  .bg-mdlg-westar {
    --bs-bg-opacity: 1;
    background-color: #ddd8ce !important;
  }
  .bg-mdlg-swirl {
    --bs-bg-opacity: 1;
    background-color: #d7cdca !important;
  }
  .bg-mdlg-light {
    --bs-bg-opacity: 1;
    background-color: #fbfbfb !important;
  }
  .bg-mdlg-success {
    --bs-bg-opacity: 1;
    background-color: #1acf82 !important;
  }
  .bg-mdlg-gray-100 {
    --bs-bg-opacity: 1;
    background-color: #9a9ea6 !important;
  }
  .bg-mdlg-gray-200 {
    --bs-bg-opacity: 1;
    background-color: #f6f5f5 !important;
  }
  .bg-mdlg-gray-300 {
    --bs-bg-opacity: 1;
    background-color: #d0cfcd !important;
  }
  .bg-mdlg-gray-400 {
    --bs-bg-opacity: 1;
    background-color: #efefef !important;
  }
  .bg-mdlg-gray-500 {
    --bs-bg-opacity: 1;
    background-color: #2a2644 !important;
  }
  .bg-mdlg-gray-600 {
    --bs-bg-opacity: 1;
    background-color: #e3e3e3 !important;
  }
  .bg-mdlg-gray-800 {
    --bs-bg-opacity: 1;
    background-color: #e0e0e0 !important;
  }
  .bg-mdlg-gray-900 {
    --bs-bg-opacity: 1;
    background-color: #c4c4c4 !important;
  }
  .bg-mdlg-body {
    --bs-bg-opacity: 1;
    background-color: #0a3255 !important;
  }
  .bg-mdlg-info {
    --bs-bg-opacity: 1;
    background-color: #60b8d0 !important;
  }
  .bg-mdlg-info-100 {
    --bs-bg-opacity: 1;
    background-color: #0083c1 !important;
  }
  .bg-mdlg-info-200 {
    --bs-bg-opacity: 1;
    background-color: #52b1c5 !important;
  }
  .bg-mdlg-info-300 {
    --bs-bg-opacity: 1;
    background-color: #97d1dc !important;
  }
  .bg-mdlg-info-400 {
    --bs-bg-opacity: 1;
    background-color: #40a7be !important;
  }
  .bg-mdlg-warning {
    --bs-bg-opacity: 1;
    background-color: #d3ad57 !important;
  }
  .bg-mdlg-danger {
    --bs-bg-opacity: 1;
    background-color: #d7282f !important;
  }
  .bg-mdlg-dark {
    --bs-bg-opacity: 1;
    background-color: #e9e9e9 !important;
  }
  .bg-mdlg-black {
    --bs-bg-opacity: 1;
    background-color: #000 !important;
  }
  .bg-mdlg-red {
    --bs-bg-opacity: 1;
    background-color: #d7282f !important;
  }
  .bg-mdlg-white {
    --bs-bg-opacity: 1;
    background-color: #ffffff !important;
  }
  .bg-mdlg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .text-mdlg-primary {
    color: #0a3255 !important;
  }
  .text-mdlg-primary-light {
    color: rgba(10, 50, 85, 0.5) !important;
  }
  .text-mdlg-secondary {
    color: #da262e !important;
  }
  .text-mdlg-secondary-light {
    color: rgba(218, 38, 46, 0.5) !important;
  }
  .text-mdlg-westar {
    color: #ddd8ce !important;
  }
  .text-mdlg-swirl {
    color: #d7cdca !important;
  }
  .text-mdlg-light {
    color: #fbfbfb !important;
  }
  .text-mdlg-success {
    color: #1acf82 !important;
  }
  .text-mdlg-gray-100 {
    color: #9a9ea6 !important;
  }
  .text-mdlg-gray-200 {
    color: #f6f5f5 !important;
  }
  .text-mdlg-gray-300 {
    color: #d0cfcd !important;
  }
  .text-mdlg-gray-400 {
    color: #efefef !important;
  }
  .text-mdlg-gray-500 {
    color: #2a2644 !important;
  }
  .text-mdlg-gray-600 {
    color: #e3e3e3 !important;
  }
  .text-mdlg-gray-800 {
    color: #e0e0e0 !important;
  }
  .text-mdlg-gray-900 {
    color: #c4c4c4 !important;
  }
  .text-mdlg-body {
    color: #0a3255 !important;
  }
  .text-mdlg-info {
    color: #60b8d0 !important;
  }
  .text-mdlg-info-100 {
    color: #0083c1 !important;
  }
  .text-mdlg-info-200 {
    color: #52b1c5 !important;
  }
  .text-mdlg-info-300 {
    color: #97d1dc !important;
  }
  .text-mdlg-info-400 {
    color: #40a7be !important;
  }
  .text-mdlg-warning {
    color: #d3ad57 !important;
  }
  .text-mdlg-danger {
    color: #d7282f !important;
  }
  .text-mdlg-dark {
    color: #e9e9e9 !important;
  }
  .text-mdlg-black {
    color: #000 !important;
  }
  .text-mdlg-red {
    color: #d7282f !important;
  }
  .text-mdlg-white {
    color: #ffffff !important;
  }
  .text-mdlg-transparent {
    color: transparent !important;
  }
  .m-mdlg-sm {
    margin: 0.33335rem !important;
  }
  .m-mdlg-3px {
    margin: 3px !important;
  }
  .m-mdlg-4px {
    margin: 4px !important;
  }
  .m-mdlg-8px {
    margin: 8px !important;
  }
  .m-mdlg-12px {
    margin: 12px !important;
  }
  .m-mdlg-15px {
    margin: 15px !important;
  }
  .m-mdlg-16px {
    margin: 16px !important;
  }
  .m-mdlg-18px {
    margin: 18px !important;
  }
  .m-mdlg-24px {
    margin: 24px !important;
  }
  .m-mdlg-25px {
    margin: 25px !important;
  }
  .m-mdlg-30px {
    margin: 30px !important;
  }
  .m-mdlg-32px {
    margin: 32px !important;
  }
  .m-mdlg-57px {
    margin: 57px !important;
  }
  .m-mdlg-n15 {
    margin: -15px !important;
  }
  .m-mdlg-n5 {
    margin: -5px !important;
  }
  .m-mdlg-3vh {
    margin: 3vh !important;
  }
  .m-mdlg-10vh {
    margin: 10vh !important;
  }
  .m-mdlg-0 {
    margin: 0 !important;
  }
  .m-mdlg-1 {
    margin: 0.6667rem !important;
  }
  .m-mdlg-2 {
    margin: 1.3334rem !important;
  }
  .m-mdlg-3 {
    margin: 2.0001rem !important;
  }
  .m-mdlg-4 {
    margin: 2.6668rem !important;
  }
  .m-mdlg-5 {
    margin: 3.3335rem !important;
  }
  .m-mdlg-6 {
    margin: 4.0002rem !important;
  }
  .m-mdlg-7 {
    margin: 4.6669rem !important;
  }
  .m-mdlg-8 {
    margin: 5.3336rem !important;
  }
  .m-mdlg-9 {
    margin: 6.0003rem !important;
  }
  .m-mdlg-10 {
    margin: 6.667rem !important;
  }
  .m-mdlg-gutter {
    margin: 24px !important;
  }
  .m-mdlg-out-container {
    margin: calc(50% - 50vw) !important;
  }
  .m-mdlg-auto {
    margin: auto !important;
  }
  .mx-mdlg-sm {
    margin-right: 0.33335rem !important;
    margin-left: 0.33335rem !important;
  }
  .mx-mdlg-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-mdlg-4px {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .mx-mdlg-8px {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .mx-mdlg-12px {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .mx-mdlg-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-mdlg-16px {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .mx-mdlg-18px {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
  .mx-mdlg-24px {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  .mx-mdlg-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-mdlg-30px {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-mdlg-32px {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
  .mx-mdlg-57px {
    margin-right: 57px !important;
    margin-left: 57px !important;
  }
  .mx-mdlg-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-mdlg-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-mdlg-3vh {
    margin-right: 3vh !important;
    margin-left: 3vh !important;
  }
  .mx-mdlg-10vh {
    margin-right: 10vh !important;
    margin-left: 10vh !important;
  }
  .mx-mdlg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-mdlg-1 {
    margin-right: 0.6667rem !important;
    margin-left: 0.6667rem !important;
  }
  .mx-mdlg-2 {
    margin-right: 1.3334rem !important;
    margin-left: 1.3334rem !important;
  }
  .mx-mdlg-3 {
    margin-right: 2.0001rem !important;
    margin-left: 2.0001rem !important;
  }
  .mx-mdlg-4 {
    margin-right: 2.6668rem !important;
    margin-left: 2.6668rem !important;
  }
  .mx-mdlg-5 {
    margin-right: 3.3335rem !important;
    margin-left: 3.3335rem !important;
  }
  .mx-mdlg-6 {
    margin-right: 4.0002rem !important;
    margin-left: 4.0002rem !important;
  }
  .mx-mdlg-7 {
    margin-right: 4.6669rem !important;
    margin-left: 4.6669rem !important;
  }
  .mx-mdlg-8 {
    margin-right: 5.3336rem !important;
    margin-left: 5.3336rem !important;
  }
  .mx-mdlg-9 {
    margin-right: 6.0003rem !important;
    margin-left: 6.0003rem !important;
  }
  .mx-mdlg-10 {
    margin-right: 6.667rem !important;
    margin-left: 6.667rem !important;
  }
  .mx-mdlg-gutter {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  .mx-mdlg-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }
  .mx-mdlg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-mdlg-sm {
    margin-top: 0.33335rem !important;
    margin-bottom: 0.33335rem !important;
  }
  .my-mdlg-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-mdlg-4px {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .my-mdlg-8px {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .my-mdlg-12px {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .my-mdlg-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-mdlg-16px {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .my-mdlg-18px {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .my-mdlg-24px {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .my-mdlg-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-mdlg-30px {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-mdlg-32px {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .my-mdlg-57px {
    margin-top: 57px !important;
    margin-bottom: 57px !important;
  }
  .my-mdlg-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-mdlg-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-mdlg-3vh {
    margin-top: 3vh !important;
    margin-bottom: 3vh !important;
  }
  .my-mdlg-10vh {
    margin-top: 10vh !important;
    margin-bottom: 10vh !important;
  }
  .my-mdlg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-mdlg-1 {
    margin-top: 0.6667rem !important;
    margin-bottom: 0.6667rem !important;
  }
  .my-mdlg-2 {
    margin-top: 1.3334rem !important;
    margin-bottom: 1.3334rem !important;
  }
  .my-mdlg-3 {
    margin-top: 2.0001rem !important;
    margin-bottom: 2.0001rem !important;
  }
  .my-mdlg-4 {
    margin-top: 2.6668rem !important;
    margin-bottom: 2.6668rem !important;
  }
  .my-mdlg-5 {
    margin-top: 3.3335rem !important;
    margin-bottom: 3.3335rem !important;
  }
  .my-mdlg-6 {
    margin-top: 4.0002rem !important;
    margin-bottom: 4.0002rem !important;
  }
  .my-mdlg-7 {
    margin-top: 4.6669rem !important;
    margin-bottom: 4.6669rem !important;
  }
  .my-mdlg-8 {
    margin-top: 5.3336rem !important;
    margin-bottom: 5.3336rem !important;
  }
  .my-mdlg-9 {
    margin-top: 6.0003rem !important;
    margin-bottom: 6.0003rem !important;
  }
  .my-mdlg-10 {
    margin-top: 6.667rem !important;
    margin-bottom: 6.667rem !important;
  }
  .my-mdlg-gutter {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .my-mdlg-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }
  .my-mdlg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-mdlg-sm {
    margin-top: 0.33335rem !important;
  }
  .mt-mdlg-3px {
    margin-top: 3px !important;
  }
  .mt-mdlg-4px {
    margin-top: 4px !important;
  }
  .mt-mdlg-8px {
    margin-top: 8px !important;
  }
  .mt-mdlg-12px {
    margin-top: 12px !important;
  }
  .mt-mdlg-15px {
    margin-top: 15px !important;
  }
  .mt-mdlg-16px {
    margin-top: 16px !important;
  }
  .mt-mdlg-18px {
    margin-top: 18px !important;
  }
  .mt-mdlg-24px {
    margin-top: 24px !important;
  }
  .mt-mdlg-25px {
    margin-top: 25px !important;
  }
  .mt-mdlg-30px {
    margin-top: 30px !important;
  }
  .mt-mdlg-32px {
    margin-top: 32px !important;
  }
  .mt-mdlg-57px {
    margin-top: 57px !important;
  }
  .mt-mdlg-n15 {
    margin-top: -15px !important;
  }
  .mt-mdlg-n5 {
    margin-top: -5px !important;
  }
  .mt-mdlg-3vh {
    margin-top: 3vh !important;
  }
  .mt-mdlg-10vh {
    margin-top: 10vh !important;
  }
  .mt-mdlg-0 {
    margin-top: 0 !important;
  }
  .mt-mdlg-1 {
    margin-top: 0.6667rem !important;
  }
  .mt-mdlg-2 {
    margin-top: 1.3334rem !important;
  }
  .mt-mdlg-3 {
    margin-top: 2.0001rem !important;
  }
  .mt-mdlg-4 {
    margin-top: 2.6668rem !important;
  }
  .mt-mdlg-5 {
    margin-top: 3.3335rem !important;
  }
  .mt-mdlg-6 {
    margin-top: 4.0002rem !important;
  }
  .mt-mdlg-7 {
    margin-top: 4.6669rem !important;
  }
  .mt-mdlg-8 {
    margin-top: 5.3336rem !important;
  }
  .mt-mdlg-9 {
    margin-top: 6.0003rem !important;
  }
  .mt-mdlg-10 {
    margin-top: 6.667rem !important;
  }
  .mt-mdlg-gutter {
    margin-top: 24px !important;
  }
  .mt-mdlg-out-container {
    margin-top: calc(50% - 50vw) !important;
  }
  .mt-mdlg-auto {
    margin-top: auto !important;
  }
  .me-mdlg-sm {
    margin-right: 0.33335rem !important;
  }
  .me-mdlg-3px {
    margin-right: 3px !important;
  }
  .me-mdlg-4px {
    margin-right: 4px !important;
  }
  .me-mdlg-8px {
    margin-right: 8px !important;
  }
  .me-mdlg-12px {
    margin-right: 12px !important;
  }
  .me-mdlg-15px {
    margin-right: 15px !important;
  }
  .me-mdlg-16px {
    margin-right: 16px !important;
  }
  .me-mdlg-18px {
    margin-right: 18px !important;
  }
  .me-mdlg-24px {
    margin-right: 24px !important;
  }
  .me-mdlg-25px {
    margin-right: 25px !important;
  }
  .me-mdlg-30px {
    margin-right: 30px !important;
  }
  .me-mdlg-32px {
    margin-right: 32px !important;
  }
  .me-mdlg-57px {
    margin-right: 57px !important;
  }
  .me-mdlg-n15 {
    margin-right: -15px !important;
  }
  .me-mdlg-n5 {
    margin-right: -5px !important;
  }
  .me-mdlg-3vh {
    margin-right: 3vh !important;
  }
  .me-mdlg-10vh {
    margin-right: 10vh !important;
  }
  .me-mdlg-0 {
    margin-right: 0 !important;
  }
  .me-mdlg-1 {
    margin-right: 0.6667rem !important;
  }
  .me-mdlg-2 {
    margin-right: 1.3334rem !important;
  }
  .me-mdlg-3 {
    margin-right: 2.0001rem !important;
  }
  .me-mdlg-4 {
    margin-right: 2.6668rem !important;
  }
  .me-mdlg-5 {
    margin-right: 3.3335rem !important;
  }
  .me-mdlg-6 {
    margin-right: 4.0002rem !important;
  }
  .me-mdlg-7 {
    margin-right: 4.6669rem !important;
  }
  .me-mdlg-8 {
    margin-right: 5.3336rem !important;
  }
  .me-mdlg-9 {
    margin-right: 6.0003rem !important;
  }
  .me-mdlg-10 {
    margin-right: 6.667rem !important;
  }
  .me-mdlg-gutter {
    margin-right: 24px !important;
  }
  .me-mdlg-out-container {
    margin-right: calc(50% - 50vw) !important;
  }
  .me-mdlg-auto {
    margin-right: auto !important;
  }
  .mb-mdlg-sm {
    margin-bottom: 0.33335rem !important;
  }
  .mb-mdlg-3px {
    margin-bottom: 3px !important;
  }
  .mb-mdlg-4px {
    margin-bottom: 4px !important;
  }
  .mb-mdlg-8px {
    margin-bottom: 8px !important;
  }
  .mb-mdlg-12px {
    margin-bottom: 12px !important;
  }
  .mb-mdlg-15px {
    margin-bottom: 15px !important;
  }
  .mb-mdlg-16px {
    margin-bottom: 16px !important;
  }
  .mb-mdlg-18px {
    margin-bottom: 18px !important;
  }
  .mb-mdlg-24px {
    margin-bottom: 24px !important;
  }
  .mb-mdlg-25px {
    margin-bottom: 25px !important;
  }
  .mb-mdlg-30px {
    margin-bottom: 30px !important;
  }
  .mb-mdlg-32px {
    margin-bottom: 32px !important;
  }
  .mb-mdlg-57px {
    margin-bottom: 57px !important;
  }
  .mb-mdlg-n15 {
    margin-bottom: -15px !important;
  }
  .mb-mdlg-n5 {
    margin-bottom: -5px !important;
  }
  .mb-mdlg-3vh {
    margin-bottom: 3vh !important;
  }
  .mb-mdlg-10vh {
    margin-bottom: 10vh !important;
  }
  .mb-mdlg-0 {
    margin-bottom: 0 !important;
  }
  .mb-mdlg-1 {
    margin-bottom: 0.6667rem !important;
  }
  .mb-mdlg-2 {
    margin-bottom: 1.3334rem !important;
  }
  .mb-mdlg-3 {
    margin-bottom: 2.0001rem !important;
  }
  .mb-mdlg-4 {
    margin-bottom: 2.6668rem !important;
  }
  .mb-mdlg-5 {
    margin-bottom: 3.3335rem !important;
  }
  .mb-mdlg-6 {
    margin-bottom: 4.0002rem !important;
  }
  .mb-mdlg-7 {
    margin-bottom: 4.6669rem !important;
  }
  .mb-mdlg-8 {
    margin-bottom: 5.3336rem !important;
  }
  .mb-mdlg-9 {
    margin-bottom: 6.0003rem !important;
  }
  .mb-mdlg-10 {
    margin-bottom: 6.667rem !important;
  }
  .mb-mdlg-gutter {
    margin-bottom: 24px !important;
  }
  .mb-mdlg-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }
  .mb-mdlg-auto {
    margin-bottom: auto !important;
  }
  .ms-mdlg-sm {
    margin-left: 0.33335rem !important;
  }
  .ms-mdlg-3px {
    margin-left: 3px !important;
  }
  .ms-mdlg-4px {
    margin-left: 4px !important;
  }
  .ms-mdlg-8px {
    margin-left: 8px !important;
  }
  .ms-mdlg-12px {
    margin-left: 12px !important;
  }
  .ms-mdlg-15px {
    margin-left: 15px !important;
  }
  .ms-mdlg-16px {
    margin-left: 16px !important;
  }
  .ms-mdlg-18px {
    margin-left: 18px !important;
  }
  .ms-mdlg-24px {
    margin-left: 24px !important;
  }
  .ms-mdlg-25px {
    margin-left: 25px !important;
  }
  .ms-mdlg-30px {
    margin-left: 30px !important;
  }
  .ms-mdlg-32px {
    margin-left: 32px !important;
  }
  .ms-mdlg-57px {
    margin-left: 57px !important;
  }
  .ms-mdlg-n15 {
    margin-left: -15px !important;
  }
  .ms-mdlg-n5 {
    margin-left: -5px !important;
  }
  .ms-mdlg-3vh {
    margin-left: 3vh !important;
  }
  .ms-mdlg-10vh {
    margin-left: 10vh !important;
  }
  .ms-mdlg-0 {
    margin-left: 0 !important;
  }
  .ms-mdlg-1 {
    margin-left: 0.6667rem !important;
  }
  .ms-mdlg-2 {
    margin-left: 1.3334rem !important;
  }
  .ms-mdlg-3 {
    margin-left: 2.0001rem !important;
  }
  .ms-mdlg-4 {
    margin-left: 2.6668rem !important;
  }
  .ms-mdlg-5 {
    margin-left: 3.3335rem !important;
  }
  .ms-mdlg-6 {
    margin-left: 4.0002rem !important;
  }
  .ms-mdlg-7 {
    margin-left: 4.6669rem !important;
  }
  .ms-mdlg-8 {
    margin-left: 5.3336rem !important;
  }
  .ms-mdlg-9 {
    margin-left: 6.0003rem !important;
  }
  .ms-mdlg-10 {
    margin-left: 6.667rem !important;
  }
  .ms-mdlg-gutter {
    margin-left: 24px !important;
  }
  .ms-mdlg-out-container {
    margin-left: calc(50% - 50vw) !important;
  }
  .ms-mdlg-auto {
    margin-left: auto !important;
  }
  .p-mdlg-sm {
    padding: 0.33335rem !important;
  }
  .p-mdlg-3px {
    padding: 3px !important;
  }
  .p-mdlg-4px {
    padding: 4px !important;
  }
  .p-mdlg-8px {
    padding: 8px !important;
  }
  .p-mdlg-12px {
    padding: 12px !important;
  }
  .p-mdlg-15px {
    padding: 15px !important;
  }
  .p-mdlg-16px {
    padding: 16px !important;
  }
  .p-mdlg-18px {
    padding: 18px !important;
  }
  .p-mdlg-24px {
    padding: 24px !important;
  }
  .p-mdlg-25px {
    padding: 25px !important;
  }
  .p-mdlg-30px {
    padding: 30px !important;
  }
  .p-mdlg-32px {
    padding: 32px !important;
  }
  .p-mdlg-57px {
    padding: 57px !important;
  }
  .p-mdlg-n15 {
    padding: -15px !important;
  }
  .p-mdlg-n5 {
    padding: -5px !important;
  }
  .p-mdlg-3vh {
    padding: 3vh !important;
  }
  .p-mdlg-10vh {
    padding: 10vh !important;
  }
  .p-mdlg-0 {
    padding: 0 !important;
  }
  .p-mdlg-1 {
    padding: 0.6667rem !important;
  }
  .p-mdlg-2 {
    padding: 1.3334rem !important;
  }
  .p-mdlg-3 {
    padding: 2.0001rem !important;
  }
  .p-mdlg-4 {
    padding: 2.6668rem !important;
  }
  .p-mdlg-5 {
    padding: 3.3335rem !important;
  }
  .p-mdlg-6 {
    padding: 4.0002rem !important;
  }
  .p-mdlg-7 {
    padding: 4.6669rem !important;
  }
  .p-mdlg-8 {
    padding: 5.3336rem !important;
  }
  .p-mdlg-9 {
    padding: 6.0003rem !important;
  }
  .p-mdlg-10 {
    padding: 6.667rem !important;
  }
  .p-mdlg-gutter {
    padding: 24px !important;
  }
  .p-mdlg-out-container {
    padding: calc(50% - 50vw) !important;
  }
  .px-mdlg-sm {
    padding-right: 0.33335rem !important;
    padding-left: 0.33335rem !important;
  }
  .px-mdlg-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-mdlg-4px {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .px-mdlg-8px {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .px-mdlg-12px {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .px-mdlg-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-mdlg-16px {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .px-mdlg-18px {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
  .px-mdlg-24px {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .px-mdlg-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-mdlg-30px {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-mdlg-32px {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
  .px-mdlg-57px {
    padding-right: 57px !important;
    padding-left: 57px !important;
  }
  .px-mdlg-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }
  .px-mdlg-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }
  .px-mdlg-3vh {
    padding-right: 3vh !important;
    padding-left: 3vh !important;
  }
  .px-mdlg-10vh {
    padding-right: 10vh !important;
    padding-left: 10vh !important;
  }
  .px-mdlg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-mdlg-1 {
    padding-right: 0.6667rem !important;
    padding-left: 0.6667rem !important;
  }
  .px-mdlg-2 {
    padding-right: 1.3334rem !important;
    padding-left: 1.3334rem !important;
  }
  .px-mdlg-3 {
    padding-right: 2.0001rem !important;
    padding-left: 2.0001rem !important;
  }
  .px-mdlg-4 {
    padding-right: 2.6668rem !important;
    padding-left: 2.6668rem !important;
  }
  .px-mdlg-5 {
    padding-right: 3.3335rem !important;
    padding-left: 3.3335rem !important;
  }
  .px-mdlg-6 {
    padding-right: 4.0002rem !important;
    padding-left: 4.0002rem !important;
  }
  .px-mdlg-7 {
    padding-right: 4.6669rem !important;
    padding-left: 4.6669rem !important;
  }
  .px-mdlg-8 {
    padding-right: 5.3336rem !important;
    padding-left: 5.3336rem !important;
  }
  .px-mdlg-9 {
    padding-right: 6.0003rem !important;
    padding-left: 6.0003rem !important;
  }
  .px-mdlg-10 {
    padding-right: 6.667rem !important;
    padding-left: 6.667rem !important;
  }
  .px-mdlg-gutter {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .px-mdlg-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }
  .py-mdlg-sm {
    padding-top: 0.33335rem !important;
    padding-bottom: 0.33335rem !important;
  }
  .py-mdlg-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-mdlg-4px {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .py-mdlg-8px {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .py-mdlg-12px {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .py-mdlg-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-mdlg-16px {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .py-mdlg-18px {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .py-mdlg-24px {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-mdlg-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-mdlg-30px {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-mdlg-32px {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .py-mdlg-57px {
    padding-top: 57px !important;
    padding-bottom: 57px !important;
  }
  .py-mdlg-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }
  .py-mdlg-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }
  .py-mdlg-3vh {
    padding-top: 3vh !important;
    padding-bottom: 3vh !important;
  }
  .py-mdlg-10vh {
    padding-top: 10vh !important;
    padding-bottom: 10vh !important;
  }
  .py-mdlg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-mdlg-1 {
    padding-top: 0.6667rem !important;
    padding-bottom: 0.6667rem !important;
  }
  .py-mdlg-2 {
    padding-top: 1.3334rem !important;
    padding-bottom: 1.3334rem !important;
  }
  .py-mdlg-3 {
    padding-top: 2.0001rem !important;
    padding-bottom: 2.0001rem !important;
  }
  .py-mdlg-4 {
    padding-top: 2.6668rem !important;
    padding-bottom: 2.6668rem !important;
  }
  .py-mdlg-5 {
    padding-top: 3.3335rem !important;
    padding-bottom: 3.3335rem !important;
  }
  .py-mdlg-6 {
    padding-top: 4.0002rem !important;
    padding-bottom: 4.0002rem !important;
  }
  .py-mdlg-7 {
    padding-top: 4.6669rem !important;
    padding-bottom: 4.6669rem !important;
  }
  .py-mdlg-8 {
    padding-top: 5.3336rem !important;
    padding-bottom: 5.3336rem !important;
  }
  .py-mdlg-9 {
    padding-top: 6.0003rem !important;
    padding-bottom: 6.0003rem !important;
  }
  .py-mdlg-10 {
    padding-top: 6.667rem !important;
    padding-bottom: 6.667rem !important;
  }
  .py-mdlg-gutter {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-mdlg-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }
  .pt-mdlg-sm {
    padding-top: 0.33335rem !important;
  }
  .pt-mdlg-3px {
    padding-top: 3px !important;
  }
  .pt-mdlg-4px {
    padding-top: 4px !important;
  }
  .pt-mdlg-8px {
    padding-top: 8px !important;
  }
  .pt-mdlg-12px {
    padding-top: 12px !important;
  }
  .pt-mdlg-15px {
    padding-top: 15px !important;
  }
  .pt-mdlg-16px {
    padding-top: 16px !important;
  }
  .pt-mdlg-18px {
    padding-top: 18px !important;
  }
  .pt-mdlg-24px {
    padding-top: 24px !important;
  }
  .pt-mdlg-25px {
    padding-top: 25px !important;
  }
  .pt-mdlg-30px {
    padding-top: 30px !important;
  }
  .pt-mdlg-32px {
    padding-top: 32px !important;
  }
  .pt-mdlg-57px {
    padding-top: 57px !important;
  }
  .pt-mdlg-n15 {
    padding-top: -15px !important;
  }
  .pt-mdlg-n5 {
    padding-top: -5px !important;
  }
  .pt-mdlg-3vh {
    padding-top: 3vh !important;
  }
  .pt-mdlg-10vh {
    padding-top: 10vh !important;
  }
  .pt-mdlg-0 {
    padding-top: 0 !important;
  }
  .pt-mdlg-1 {
    padding-top: 0.6667rem !important;
  }
  .pt-mdlg-2 {
    padding-top: 1.3334rem !important;
  }
  .pt-mdlg-3 {
    padding-top: 2.0001rem !important;
  }
  .pt-mdlg-4 {
    padding-top: 2.6668rem !important;
  }
  .pt-mdlg-5 {
    padding-top: 3.3335rem !important;
  }
  .pt-mdlg-6 {
    padding-top: 4.0002rem !important;
  }
  .pt-mdlg-7 {
    padding-top: 4.6669rem !important;
  }
  .pt-mdlg-8 {
    padding-top: 5.3336rem !important;
  }
  .pt-mdlg-9 {
    padding-top: 6.0003rem !important;
  }
  .pt-mdlg-10 {
    padding-top: 6.667rem !important;
  }
  .pt-mdlg-gutter {
    padding-top: 24px !important;
  }
  .pt-mdlg-out-container {
    padding-top: calc(50% - 50vw) !important;
  }
  .pe-mdlg-sm {
    padding-right: 0.33335rem !important;
  }
  .pe-mdlg-3px {
    padding-right: 3px !important;
  }
  .pe-mdlg-4px {
    padding-right: 4px !important;
  }
  .pe-mdlg-8px {
    padding-right: 8px !important;
  }
  .pe-mdlg-12px {
    padding-right: 12px !important;
  }
  .pe-mdlg-15px {
    padding-right: 15px !important;
  }
  .pe-mdlg-16px {
    padding-right: 16px !important;
  }
  .pe-mdlg-18px {
    padding-right: 18px !important;
  }
  .pe-mdlg-24px {
    padding-right: 24px !important;
  }
  .pe-mdlg-25px {
    padding-right: 25px !important;
  }
  .pe-mdlg-30px {
    padding-right: 30px !important;
  }
  .pe-mdlg-32px {
    padding-right: 32px !important;
  }
  .pe-mdlg-57px {
    padding-right: 57px !important;
  }
  .pe-mdlg-n15 {
    padding-right: -15px !important;
  }
  .pe-mdlg-n5 {
    padding-right: -5px !important;
  }
  .pe-mdlg-3vh {
    padding-right: 3vh !important;
  }
  .pe-mdlg-10vh {
    padding-right: 10vh !important;
  }
  .pe-mdlg-0 {
    padding-right: 0 !important;
  }
  .pe-mdlg-1 {
    padding-right: 0.6667rem !important;
  }
  .pe-mdlg-2 {
    padding-right: 1.3334rem !important;
  }
  .pe-mdlg-3 {
    padding-right: 2.0001rem !important;
  }
  .pe-mdlg-4 {
    padding-right: 2.6668rem !important;
  }
  .pe-mdlg-5 {
    padding-right: 3.3335rem !important;
  }
  .pe-mdlg-6 {
    padding-right: 4.0002rem !important;
  }
  .pe-mdlg-7 {
    padding-right: 4.6669rem !important;
  }
  .pe-mdlg-8 {
    padding-right: 5.3336rem !important;
  }
  .pe-mdlg-9 {
    padding-right: 6.0003rem !important;
  }
  .pe-mdlg-10 {
    padding-right: 6.667rem !important;
  }
  .pe-mdlg-gutter {
    padding-right: 24px !important;
  }
  .pe-mdlg-out-container {
    padding-right: calc(50% - 50vw) !important;
  }
  .pb-mdlg-sm {
    padding-bottom: 0.33335rem !important;
  }
  .pb-mdlg-3px {
    padding-bottom: 3px !important;
  }
  .pb-mdlg-4px {
    padding-bottom: 4px !important;
  }
  .pb-mdlg-8px {
    padding-bottom: 8px !important;
  }
  .pb-mdlg-12px {
    padding-bottom: 12px !important;
  }
  .pb-mdlg-15px {
    padding-bottom: 15px !important;
  }
  .pb-mdlg-16px {
    padding-bottom: 16px !important;
  }
  .pb-mdlg-18px {
    padding-bottom: 18px !important;
  }
  .pb-mdlg-24px {
    padding-bottom: 24px !important;
  }
  .pb-mdlg-25px {
    padding-bottom: 25px !important;
  }
  .pb-mdlg-30px {
    padding-bottom: 30px !important;
  }
  .pb-mdlg-32px {
    padding-bottom: 32px !important;
  }
  .pb-mdlg-57px {
    padding-bottom: 57px !important;
  }
  .pb-mdlg-n15 {
    padding-bottom: -15px !important;
  }
  .pb-mdlg-n5 {
    padding-bottom: -5px !important;
  }
  .pb-mdlg-3vh {
    padding-bottom: 3vh !important;
  }
  .pb-mdlg-10vh {
    padding-bottom: 10vh !important;
  }
  .pb-mdlg-0 {
    padding-bottom: 0 !important;
  }
  .pb-mdlg-1 {
    padding-bottom: 0.6667rem !important;
  }
  .pb-mdlg-2 {
    padding-bottom: 1.3334rem !important;
  }
  .pb-mdlg-3 {
    padding-bottom: 2.0001rem !important;
  }
  .pb-mdlg-4 {
    padding-bottom: 2.6668rem !important;
  }
  .pb-mdlg-5 {
    padding-bottom: 3.3335rem !important;
  }
  .pb-mdlg-6 {
    padding-bottom: 4.0002rem !important;
  }
  .pb-mdlg-7 {
    padding-bottom: 4.6669rem !important;
  }
  .pb-mdlg-8 {
    padding-bottom: 5.3336rem !important;
  }
  .pb-mdlg-9 {
    padding-bottom: 6.0003rem !important;
  }
  .pb-mdlg-10 {
    padding-bottom: 6.667rem !important;
  }
  .pb-mdlg-gutter {
    padding-bottom: 24px !important;
  }
  .pb-mdlg-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }
  .ps-mdlg-sm {
    padding-left: 0.33335rem !important;
  }
  .ps-mdlg-3px {
    padding-left: 3px !important;
  }
  .ps-mdlg-4px {
    padding-left: 4px !important;
  }
  .ps-mdlg-8px {
    padding-left: 8px !important;
  }
  .ps-mdlg-12px {
    padding-left: 12px !important;
  }
  .ps-mdlg-15px {
    padding-left: 15px !important;
  }
  .ps-mdlg-16px {
    padding-left: 16px !important;
  }
  .ps-mdlg-18px {
    padding-left: 18px !important;
  }
  .ps-mdlg-24px {
    padding-left: 24px !important;
  }
  .ps-mdlg-25px {
    padding-left: 25px !important;
  }
  .ps-mdlg-30px {
    padding-left: 30px !important;
  }
  .ps-mdlg-32px {
    padding-left: 32px !important;
  }
  .ps-mdlg-57px {
    padding-left: 57px !important;
  }
  .ps-mdlg-n15 {
    padding-left: -15px !important;
  }
  .ps-mdlg-n5 {
    padding-left: -5px !important;
  }
  .ps-mdlg-3vh {
    padding-left: 3vh !important;
  }
  .ps-mdlg-10vh {
    padding-left: 10vh !important;
  }
  .ps-mdlg-0 {
    padding-left: 0 !important;
  }
  .ps-mdlg-1 {
    padding-left: 0.6667rem !important;
  }
  .ps-mdlg-2 {
    padding-left: 1.3334rem !important;
  }
  .ps-mdlg-3 {
    padding-left: 2.0001rem !important;
  }
  .ps-mdlg-4 {
    padding-left: 2.6668rem !important;
  }
  .ps-mdlg-5 {
    padding-left: 3.3335rem !important;
  }
  .ps-mdlg-6 {
    padding-left: 4.0002rem !important;
  }
  .ps-mdlg-7 {
    padding-left: 4.6669rem !important;
  }
  .ps-mdlg-8 {
    padding-left: 5.3336rem !important;
  }
  .ps-mdlg-9 {
    padding-left: 6.0003rem !important;
  }
  .ps-mdlg-10 {
    padding-left: 6.667rem !important;
  }
  .ps-mdlg-gutter {
    padding-left: 24px !important;
  }
  .ps-mdlg-out-container {
    padding-left: calc(50% - 50vw) !important;
  }
  .flex-mdlg-fill {
    flex: 1 1 auto !important;
  }
  .flex-mdlg-row {
    flex-direction: row !important;
  }
  .flex-mdlg-column {
    flex-direction: column !important;
  }
  .flex-mdlg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-mdlg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-mdlg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-mdlg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-mdlg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-mdlg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-mdlg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-mdlg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-mdlg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-mdlg-sm {
    gap: 0.33335rem !important;
  }
  .gap-mdlg-3px {
    gap: 3px !important;
  }
  .gap-mdlg-4px {
    gap: 4px !important;
  }
  .gap-mdlg-8px {
    gap: 8px !important;
  }
  .gap-mdlg-12px {
    gap: 12px !important;
  }
  .gap-mdlg-15px {
    gap: 15px !important;
  }
  .gap-mdlg-16px {
    gap: 16px !important;
  }
  .gap-mdlg-18px {
    gap: 18px !important;
  }
  .gap-mdlg-24px {
    gap: 24px !important;
  }
  .gap-mdlg-25px {
    gap: 25px !important;
  }
  .gap-mdlg-30px {
    gap: 30px !important;
  }
  .gap-mdlg-32px {
    gap: 32px !important;
  }
  .gap-mdlg-57px {
    gap: 57px !important;
  }
  .gap-mdlg-n15 {
    gap: -15px !important;
  }
  .gap-mdlg-n5 {
    gap: -5px !important;
  }
  .gap-mdlg-3vh {
    gap: 3vh !important;
  }
  .gap-mdlg-10vh {
    gap: 10vh !important;
  }
  .gap-mdlg-0 {
    gap: 0 !important;
  }
  .gap-mdlg-1 {
    gap: 0.6667rem !important;
  }
  .gap-mdlg-2 {
    gap: 1.3334rem !important;
  }
  .gap-mdlg-3 {
    gap: 2.0001rem !important;
  }
  .gap-mdlg-4 {
    gap: 2.6668rem !important;
  }
  .gap-mdlg-5 {
    gap: 3.3335rem !important;
  }
  .gap-mdlg-6 {
    gap: 4.0002rem !important;
  }
  .gap-mdlg-7 {
    gap: 4.6669rem !important;
  }
  .gap-mdlg-8 {
    gap: 5.3336rem !important;
  }
  .gap-mdlg-9 {
    gap: 6.0003rem !important;
  }
  .gap-mdlg-10 {
    gap: 6.667rem !important;
  }
  .gap-mdlg-gutter {
    gap: 24px !important;
  }
  .gap-mdlg-out-container {
    gap: calc(50% - 50vw) !important;
  }
  .justify-content-mdlg-start {
    justify-content: flex-start !important;
  }
  .justify-content-mdlg-end {
    justify-content: flex-end !important;
  }
  .justify-content-mdlg-center {
    justify-content: center !important;
  }
  .justify-content-mdlg-between {
    justify-content: space-between !important;
  }
  .justify-content-mdlg-around {
    justify-content: space-around !important;
  }
  .justify-content-mdlg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-mdlg-start {
    align-items: flex-start !important;
  }
  .align-items-mdlg-end {
    align-items: flex-end !important;
  }
  .align-items-mdlg-center {
    align-items: center !important;
  }
  .align-items-mdlg-baseline {
    align-items: baseline !important;
  }
  .align-items-mdlg-stretch {
    align-items: stretch !important;
  }
  .align-content-mdlg-start {
    align-content: flex-start !important;
  }
  .align-content-mdlg-end {
    align-content: flex-end !important;
  }
  .align-content-mdlg-center {
    align-content: center !important;
  }
  .align-content-mdlg-between {
    align-content: space-between !important;
  }
  .align-content-mdlg-around {
    align-content: space-around !important;
  }
  .align-content-mdlg-stretch {
    align-content: stretch !important;
  }
  .align-self-mdlg-auto {
    align-self: auto !important;
  }
  .align-self-mdlg-start {
    align-self: flex-start !important;
  }
  .align-self-mdlg-end {
    align-self: flex-end !important;
  }
  .align-self-mdlg-center {
    align-self: center !important;
  }
  .align-self-mdlg-baseline {
    align-self: baseline !important;
  }
  .align-self-mdlg-stretch {
    align-self: stretch !important;
  }
  .vh-mdlg-30 {
    height: 30vh !important;
  }
  .vh-mdlg-40 {
    height: 40vh !important;
  }
  .vh-mdlg-50 {
    height: 50vh !important;
  }
  .vh-mdlg-60 {
    height: 60vh !important;
  }
  .vh-mdlg-65 {
    height: 65vh !important;
  }
  .vh-mdlg-70 {
    height: 70vh !important;
  }
  .vh-mdlg-80 {
    height: 80vh !important;
  }
  .vh-mdlg-100 {
    height: 100vh !important;
  }
  .w-mdlg-10 {
    width: 10% !important;
  }
  .w-mdlg-20 {
    width: 20% !important;
  }
  .w-mdlg-25 {
    width: 25% !important;
  }
  .w-mdlg-30 {
    width: 30% !important;
  }
  .w-mdlg-35 {
    width: 35% !important;
  }
  .w-mdlg-40 {
    width: 40% !important;
  }
  .w-mdlg-45 {
    width: 45% !important;
  }
  .w-mdlg-50 {
    width: 50% !important;
  }
  .w-mdlg-55 {
    width: 55% !important;
  }
  .w-mdlg-60 {
    width: 60% !important;
  }
  .w-mdlg-64 {
    width: 64% !important;
  }
  .w-mdlg-65 {
    width: 65% !important;
  }
  .w-mdlg-70 {
    width: 70% !important;
  }
  .w-mdlg-75 {
    width: 75% !important;
  }
  .w-mdlg-80 {
    width: 80% !important;
  }
  .w-mdlg-82 {
    width: 82% !important;
  }
  .w-mdlg-90 {
    width: 90% !important;
  }
  .w-mdlg-100 {
    width: 100% !important;
  }
  .w-mdlg-auto {
    width: auto !important;
  }
  .w-mdlg-20px {
    width: 20px !important;
  }
  .w-mdlg-80px {
    width: 80px !important;
  }
  .w-mdlg-icon {
    width: 24px !important;
  }
  .w-mdlg-fit {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .h-mdlg-25 {
    height: 25% !important;
  }
  .h-mdlg-50 {
    height: 50% !important;
  }
  .h-mdlg-75 {
    height: 75% !important;
  }
  .h-mdlg-100 {
    height: 100% !important;
  }
  .h-mdlg-20px {
    height: 20px !important;
  }
  .h-mdlg-auto {
    height: auto !important;
  }
  .h-mdlg-icon {
    height: 24px !important;
  }
  .h-mdlg-400 {
    height: 400px !important;
  }
  .h-mdlg-550 {
    height: 550px !important;
  }
  .h-mdlg-fit {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .fs-mdlg-1 {
    font-size: 3.5rem !important;
  }
  .fs-mdlg-2 {
    font-size: 2rem !important;
  }
  .fs-mdlg-3 {
    font-size: 1.5rem !important;
  }
  .fs-mdlg-4 {
    font-size: 1.25rem !important;
  }
  .fs-mdlg-5 {
    font-size: 1.125rem !important;
  }
  .fs-mdlg-6 {
    font-size: 1rem !important;
  }
  .fs-mdlg-7 {
    font-size: 0.875rem !important;
  }
  .fs-mdlg-22 {
    font-size: 1.375rem !important;
  }
  .fs-mdlg-40 {
    font-size: 2.5rem !important;
  }
  .fs-mdlg-48 {
    font-size: 3rem !important;
  }
  .fs-mdlg-lg {
    font-size: 3.375rem !important;
  }
  .fs-mdlg-xl {
    font-size: 5rem !important;
  }
  .fs-mdlg-sm {
    font-size: 0.75rem !important;
  }
  .fs-mdlg-icon {
    font-size: 1.5625rem !important;
  }
  .lh-mdlg-1 {
    line-height: 1 !important;
  }
  .lh-mdlg-sm {
    line-height: 1.25 !important;
  }
  .lh-mdlg-base {
    line-height: 1.5 !important;
  }
  .lh-mdlg-md {
    line-height: 1.65 !important;
  }
  .lh-mdlg-lg {
    line-height: 2 !important;
  }
  .lh-mdlg-3 {
    line-height: 3 !important;
  }
  .order-mdlg-first {
    order: -1 !important;
  }
  .order-mdlg-0 {
    order: 0 !important;
  }
  .order-mdlg-1 {
    order: 1 !important;
  }
  .order-mdlg-2 {
    order: 2 !important;
  }
  .order-mdlg-3 {
    order: 3 !important;
  }
  .order-mdlg-4 {
    order: 4 !important;
  }
  .order-mdlg-5 {
    order: 5 !important;
  }
  .order-mdlg-last {
    order: 6 !important;
  }
  .order-mdlg-unset {
    order: unset !important;
  }
  .cursor-mdlg-auto {
    cursor: auto !important;
  }
  .cursor-mdlg-pointer {
    cursor: pointer !important;
  }
  .cursor-mdlg-grab {
    cursor: -webkit-grab !important;
    cursor: grab !important;
  }
  .border-mdlg {
    border: 1px solid #d0cfcd !important;
  }
  .border-mdlg-0 {
    border: 0 !important;
  }
  .border-mdlg-2 {
    border: 2px solid !important;
  }
  .border-top-mdlg {
    border-top: 1px solid #d0cfcd !important;
  }
  .border-top-mdlg-0 {
    border-top: 0 !important;
  }
  .border-start-mdlg {
    border-left: 1px solid #d0cfcd !important;
  }
  .border-start-mdlg-0 {
    border-left: 0 !important;
  }
  .border-end-mdlg {
    border-right: 1px solid #d0cfcd !important;
  }
  .border-end-mdlg-0 {
    border-right: 0 !important;
  }
  .border-bottom-mdlg {
    border-bottom: 1px solid #d0cfcd !important;
  }
  .border-bottom-mdlg-0 {
    border-bottom: 0 !important;
  }
  .shadow-mdlg {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  }
  .shadow-mdlg-small {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-mdlg-large {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05) !important;
  }
  .shadow-mdlg-none {
    box-shadow: none !important;
  }
  .text-mdlg-start {
    text-align: left !important;
  }
  .text-mdlg-end {
    text-align: right !important;
  }
  .text-mdlg-center {
    text-align: center !important;
  }
  .text-decoration-mdlg-none {
    text-decoration: none !important;
  }
  .text-decoration-mdlg-underline {
    text-decoration: underline !important;
  }
  .text-decoration-mdlg-line-through {
    text-decoration: line-through !important;
  }
  .text-mdlg-lowercase {
    text-transform: lowercase !important;
  }
  .text-mdlg-uppercase {
    text-transform: uppercase !important;
  }
  .text-mdlg-capitalize {
    text-transform: capitalize !important;
  }
  .position-mdlg-static {
    position: static !important;
  }
  .position-mdlg-relative {
    position: relative !important;
  }
  .position-mdlg-absolute {
    position: absolute !important;
  }
  .position-mdlg-fixed {
    position: fixed !important;
  }
  .position-mdlg-sticky {
    position: sticky !important;
  }
  .top-mdlg-0 {
    top: 0 !important;
  }
  .top-mdlg-25 {
    top: 25% !important;
  }
  .top-mdlg-50 {
    top: 50% !important;
  }
  .top-mdlg-75 {
    top: 75% !important;
  }
  .top-mdlg-100 {
    top: 100% !important;
  }
  .top-mdlg-10 {
    top: 10px !important;
  }
  .top-mdlg-20 {
    top: 20px !important;
  }
  .top-mdlg-30 {
    top: 30px !important;
  }
  .top-mdlg-n10 {
    top: -10px !important;
  }
  .top-mdlg-n20 {
    top: -20px !important;
  }
  .top-mdlg-n8 {
    top: -8px !important;
  }
  .top-mdlg-7 {
    top: 7px !important;
  }
  .top-mdlg-13 {
    top: 13px !important;
  }
  .top-mdlg-15 {
    top: 15px !important;
  }
  .top-mdlg-n3 {
    top: -3px !important;
  }
  .top-mdlg-5 {
    top: 5px !important;
  }
  .top-mdlg-2 {
    top: 2px !important;
  }
  .bottom-mdlg-0 {
    bottom: 0 !important;
  }
  .bottom-mdlg-25 {
    bottom: 25% !important;
  }
  .bottom-mdlg-50 {
    bottom: 50% !important;
  }
  .bottom-mdlg-75 {
    bottom: 75% !important;
  }
  .bottom-mdlg-100 {
    bottom: 100% !important;
  }
  .bottom-mdlg-10 {
    bottom: 10px !important;
  }
  .bottom-mdlg-20 {
    bottom: 20px !important;
  }
  .bottom-mdlg-30 {
    bottom: 30px !important;
  }
  .bottom-mdlg-n10 {
    bottom: -10px !important;
  }
  .bottom-mdlg-n20 {
    bottom: -20px !important;
  }
  .bottom-mdlg-n8 {
    bottom: -8px !important;
  }
  .bottom-mdlg-7 {
    bottom: 7px !important;
  }
  .bottom-mdlg-13 {
    bottom: 13px !important;
  }
  .bottom-mdlg-15 {
    bottom: 15px !important;
  }
  .bottom-mdlg-n3 {
    bottom: -3px !important;
  }
  .bottom-mdlg-5 {
    bottom: 5px !important;
  }
  .bottom-mdlg-2 {
    bottom: 2px !important;
  }
  .end-mdlg-0 {
    right: 0 !important;
  }
  .end-mdlg-25 {
    right: 25% !important;
  }
  .end-mdlg-50 {
    right: 50% !important;
  }
  .end-mdlg-75 {
    right: 75% !important;
  }
  .end-mdlg-100 {
    right: 100% !important;
  }
  .end-mdlg-10 {
    right: 10px !important;
  }
  .end-mdlg-20 {
    right: 20px !important;
  }
  .end-mdlg-30 {
    right: 30px !important;
  }
  .end-mdlg-n10 {
    right: -10px !important;
  }
  .end-mdlg-n20 {
    right: -20px !important;
  }
  .end-mdlg-n8 {
    right: -8px !important;
  }
  .end-mdlg-7 {
    right: 7px !important;
  }
  .end-mdlg-13 {
    right: 13px !important;
  }
  .end-mdlg-15 {
    right: 15px !important;
  }
  .end-mdlg-n3 {
    right: -3px !important;
  }
  .end-mdlg-5 {
    right: 5px !important;
  }
  .end-mdlg-2 {
    right: 2px !important;
  }
  .translate-middle-mdlg {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-mdlg-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-mdlg-y {
    transform: translateY(-50%) !important;
  }
  .ls-mdlg-1 {
    letter-spacing: 1px !important;
  }
  .ls-mdlg-sm {
    letter-spacing: 1.4px !important;
  }
  .ls-mdlg-button {
    letter-spacing: 1.5px !important;
  }
  .ls-mdlg-2 {
    letter-spacing: 2px !important;
  }
  .ls-mdlg-3 {
    letter-spacing: 3px !important;
  }
  .ls-mdlg-4 {
    letter-spacing: 4px !important;
  }
  .ls-mdlg-5 {
    letter-spacing: 5px !important;
  }
  .mx-mdlg-nsm {
    margin-right: -0.33335rem !important;
    margin-left: -0.33335rem !important;
  }
  .mx-mdlg-n3px {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .mx-mdlg-n4px {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }
  .mx-mdlg-n8px {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }
  .mx-mdlg-n12px {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }
  .mx-mdlg-n15px {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-mdlg-n16px {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }
  .mx-mdlg-n18px {
    margin-right: -18px !important;
    margin-left: -18px !important;
  }
  .mx-mdlg-n24px {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }
  .mx-mdlg-n25px {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .mx-mdlg-n30px {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .mx-mdlg-n32px {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }
  .mx-mdlg-n57px {
    margin-right: -57px !important;
    margin-left: -57px !important;
  }
  .mx-mdlg-nn15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-mdlg-nn5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-mdlg-n3vh {
    margin-right: -3vh !important;
    margin-left: -3vh !important;
  }
  .mx-mdlg-n10vh {
    margin-right: -10vh !important;
    margin-left: -10vh !important;
  }
  .mx-mdlg-n1 {
    margin-right: -0.6667rem !important;
    margin-left: -0.6667rem !important;
  }
  .mx-mdlg-n2 {
    margin-right: -1.3334rem !important;
    margin-left: -1.3334rem !important;
  }
  .mx-mdlg-n3 {
    margin-right: -2.0001rem !important;
    margin-left: -2.0001rem !important;
  }
  .mx-mdlg-n4 {
    margin-right: -2.6668rem !important;
    margin-left: -2.6668rem !important;
  }
  .mx-mdlg-n5 {
    margin-right: -3.3335rem !important;
    margin-left: -3.3335rem !important;
  }
  .mx-mdlg-n6 {
    margin-right: -4.0002rem !important;
    margin-left: -4.0002rem !important;
  }
  .mx-mdlg-n7 {
    margin-right: -4.6669rem !important;
    margin-left: -4.6669rem !important;
  }
  .mx-mdlg-n8 {
    margin-right: -5.3336rem !important;
    margin-left: -5.3336rem !important;
  }
  .mx-mdlg-n9 {
    margin-right: -6.0003rem !important;
    margin-left: -6.0003rem !important;
  }
  .mx-mdlg-n10 {
    margin-right: -6.667rem !important;
    margin-left: -6.667rem !important;
  }
  .mx-mdlg-ngutter {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }
  .mx-mdlg-nout-container {
    margin-right: -calc(50% - 50vw) !important;
    margin-left: -calc(50% - 50vw) !important;
  }
  .my-mdlg-nsm {
    margin-top: -0.33335rem !important;
    margin-bottom: -0.33335rem !important;
  }
  .my-mdlg-n3px {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .my-mdlg-n4px {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .my-mdlg-n8px {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .my-mdlg-n12px {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .my-mdlg-n15px {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-mdlg-n16px {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .my-mdlg-n18px {
    margin-top: -18px !important;
    margin-bottom: -18px !important;
  }
  .my-mdlg-n24px {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .my-mdlg-n25px {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .my-mdlg-n30px {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .my-mdlg-n32px {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .my-mdlg-n57px {
    margin-top: -57px !important;
    margin-bottom: -57px !important;
  }
  .my-mdlg-nn15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-mdlg-nn5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-mdlg-n3vh {
    margin-top: -3vh !important;
    margin-bottom: -3vh !important;
  }
  .my-mdlg-n10vh {
    margin-top: -10vh !important;
    margin-bottom: -10vh !important;
  }
  .my-mdlg-n1 {
    margin-top: -0.6667rem !important;
    margin-bottom: -0.6667rem !important;
  }
  .my-mdlg-n2 {
    margin-top: -1.3334rem !important;
    margin-bottom: -1.3334rem !important;
  }
  .my-mdlg-n3 {
    margin-top: -2.0001rem !important;
    margin-bottom: -2.0001rem !important;
  }
  .my-mdlg-n4 {
    margin-top: -2.6668rem !important;
    margin-bottom: -2.6668rem !important;
  }
  .my-mdlg-n5 {
    margin-top: -3.3335rem !important;
    margin-bottom: -3.3335rem !important;
  }
  .my-mdlg-n6 {
    margin-top: -4.0002rem !important;
    margin-bottom: -4.0002rem !important;
  }
  .my-mdlg-n7 {
    margin-top: -4.6669rem !important;
    margin-bottom: -4.6669rem !important;
  }
  .my-mdlg-n8 {
    margin-top: -5.3336rem !important;
    margin-bottom: -5.3336rem !important;
  }
  .my-mdlg-n9 {
    margin-top: -6.0003rem !important;
    margin-bottom: -6.0003rem !important;
  }
  .my-mdlg-n10 {
    margin-top: -6.667rem !important;
    margin-bottom: -6.667rem !important;
  }
  .my-mdlg-ngutter {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .my-mdlg-nout-container {
    margin-top: -calc(50% - 50vw) !important;
    margin-bottom: -calc(50% - 50vw) !important;
  }
  .mt-mdlg-nsm {
    margin-top: -0.33335rem !important;
  }
  .mt-mdlg-n3px {
    margin-top: -3px !important;
  }
  .mt-mdlg-n4px {
    margin-top: -4px !important;
  }
  .mt-mdlg-n8px {
    margin-top: -8px !important;
  }
  .mt-mdlg-n12px {
    margin-top: -12px !important;
  }
  .mt-mdlg-n15px {
    margin-top: -15px !important;
  }
  .mt-mdlg-n16px {
    margin-top: -16px !important;
  }
  .mt-mdlg-n18px {
    margin-top: -18px !important;
  }
  .mt-mdlg-n24px {
    margin-top: -24px !important;
  }
  .mt-mdlg-n25px {
    margin-top: -25px !important;
  }
  .mt-mdlg-n30px {
    margin-top: -30px !important;
  }
  .mt-mdlg-n32px {
    margin-top: -32px !important;
  }
  .mt-mdlg-n57px {
    margin-top: -57px !important;
  }
  .mt-mdlg-nn15 {
    margin-top: 15px !important;
  }
  .mt-mdlg-nn5 {
    margin-top: 5px !important;
  }
  .mt-mdlg-n3vh {
    margin-top: -3vh !important;
  }
  .mt-mdlg-n10vh {
    margin-top: -10vh !important;
  }
  .mt-mdlg-n1 {
    margin-top: -0.6667rem !important;
  }
  .mt-mdlg-n2 {
    margin-top: -1.3334rem !important;
  }
  .mt-mdlg-n3 {
    margin-top: -2.0001rem !important;
  }
  .mt-mdlg-n4 {
    margin-top: -2.6668rem !important;
  }
  .mt-mdlg-n5 {
    margin-top: -3.3335rem !important;
  }
  .mt-mdlg-n6 {
    margin-top: -4.0002rem !important;
  }
  .mt-mdlg-n7 {
    margin-top: -4.6669rem !important;
  }
  .mt-mdlg-n8 {
    margin-top: -5.3336rem !important;
  }
  .mt-mdlg-n9 {
    margin-top: -6.0003rem !important;
  }
  .mt-mdlg-n10 {
    margin-top: -6.667rem !important;
  }
  .mt-mdlg-ngutter {
    margin-top: -24px !important;
  }
  .mt-mdlg-nout-container {
    margin-top: -calc(50% - 50vw) !important;
  }
  .me-mdlg-nsm {
    margin-right: -0.33335rem !important;
  }
  .me-mdlg-n3px {
    margin-right: -3px !important;
  }
  .me-mdlg-n4px {
    margin-right: -4px !important;
  }
  .me-mdlg-n8px {
    margin-right: -8px !important;
  }
  .me-mdlg-n12px {
    margin-right: -12px !important;
  }
  .me-mdlg-n15px {
    margin-right: -15px !important;
  }
  .me-mdlg-n16px {
    margin-right: -16px !important;
  }
  .me-mdlg-n18px {
    margin-right: -18px !important;
  }
  .me-mdlg-n24px {
    margin-right: -24px !important;
  }
  .me-mdlg-n25px {
    margin-right: -25px !important;
  }
  .me-mdlg-n30px {
    margin-right: -30px !important;
  }
  .me-mdlg-n32px {
    margin-right: -32px !important;
  }
  .me-mdlg-n57px {
    margin-right: -57px !important;
  }
  .me-mdlg-nn15 {
    margin-right: 15px !important;
  }
  .me-mdlg-nn5 {
    margin-right: 5px !important;
  }
  .me-mdlg-n3vh {
    margin-right: -3vh !important;
  }
  .me-mdlg-n10vh {
    margin-right: -10vh !important;
  }
  .me-mdlg-n1 {
    margin-right: -0.6667rem !important;
  }
  .me-mdlg-n2 {
    margin-right: -1.3334rem !important;
  }
  .me-mdlg-n3 {
    margin-right: -2.0001rem !important;
  }
  .me-mdlg-n4 {
    margin-right: -2.6668rem !important;
  }
  .me-mdlg-n5 {
    margin-right: -3.3335rem !important;
  }
  .me-mdlg-n6 {
    margin-right: -4.0002rem !important;
  }
  .me-mdlg-n7 {
    margin-right: -4.6669rem !important;
  }
  .me-mdlg-n8 {
    margin-right: -5.3336rem !important;
  }
  .me-mdlg-n9 {
    margin-right: -6.0003rem !important;
  }
  .me-mdlg-n10 {
    margin-right: -6.667rem !important;
  }
  .me-mdlg-ngutter {
    margin-right: -24px !important;
  }
  .me-mdlg-nout-container {
    margin-right: -calc(50% - 50vw) !important;
  }
  .mb-mdlg-nsm {
    margin-bottom: -0.33335rem !important;
  }
  .mb-mdlg-n3px {
    margin-bottom: -3px !important;
  }
  .mb-mdlg-n4px {
    margin-bottom: -4px !important;
  }
  .mb-mdlg-n8px {
    margin-bottom: -8px !important;
  }
  .mb-mdlg-n12px {
    margin-bottom: -12px !important;
  }
  .mb-mdlg-n15px {
    margin-bottom: -15px !important;
  }
  .mb-mdlg-n16px {
    margin-bottom: -16px !important;
  }
  .mb-mdlg-n18px {
    margin-bottom: -18px !important;
  }
  .mb-mdlg-n24px {
    margin-bottom: -24px !important;
  }
  .mb-mdlg-n25px {
    margin-bottom: -25px !important;
  }
  .mb-mdlg-n30px {
    margin-bottom: -30px !important;
  }
  .mb-mdlg-n32px {
    margin-bottom: -32px !important;
  }
  .mb-mdlg-n57px {
    margin-bottom: -57px !important;
  }
  .mb-mdlg-nn15 {
    margin-bottom: 15px !important;
  }
  .mb-mdlg-nn5 {
    margin-bottom: 5px !important;
  }
  .mb-mdlg-n3vh {
    margin-bottom: -3vh !important;
  }
  .mb-mdlg-n10vh {
    margin-bottom: -10vh !important;
  }
  .mb-mdlg-n1 {
    margin-bottom: -0.6667rem !important;
  }
  .mb-mdlg-n2 {
    margin-bottom: -1.3334rem !important;
  }
  .mb-mdlg-n3 {
    margin-bottom: -2.0001rem !important;
  }
  .mb-mdlg-n4 {
    margin-bottom: -2.6668rem !important;
  }
  .mb-mdlg-n5 {
    margin-bottom: -3.3335rem !important;
  }
  .mb-mdlg-n6 {
    margin-bottom: -4.0002rem !important;
  }
  .mb-mdlg-n7 {
    margin-bottom: -4.6669rem !important;
  }
  .mb-mdlg-n8 {
    margin-bottom: -5.3336rem !important;
  }
  .mb-mdlg-n9 {
    margin-bottom: -6.0003rem !important;
  }
  .mb-mdlg-n10 {
    margin-bottom: -6.667rem !important;
  }
  .mb-mdlg-ngutter {
    margin-bottom: -24px !important;
  }
  .mb-mdlg-nout-container {
    margin-bottom: -calc(50% - 50vw) !important;
  }
  .ms-mdlg-nsm {
    margin-left: -0.33335rem !important;
  }
  .ms-mdlg-n3px {
    margin-left: -3px !important;
  }
  .ms-mdlg-n4px {
    margin-left: -4px !important;
  }
  .ms-mdlg-n8px {
    margin-left: -8px !important;
  }
  .ms-mdlg-n12px {
    margin-left: -12px !important;
  }
  .ms-mdlg-n15px {
    margin-left: -15px !important;
  }
  .ms-mdlg-n16px {
    margin-left: -16px !important;
  }
  .ms-mdlg-n18px {
    margin-left: -18px !important;
  }
  .ms-mdlg-n24px {
    margin-left: -24px !important;
  }
  .ms-mdlg-n25px {
    margin-left: -25px !important;
  }
  .ms-mdlg-n30px {
    margin-left: -30px !important;
  }
  .ms-mdlg-n32px {
    margin-left: -32px !important;
  }
  .ms-mdlg-n57px {
    margin-left: -57px !important;
  }
  .ms-mdlg-nn15 {
    margin-left: 15px !important;
  }
  .ms-mdlg-nn5 {
    margin-left: 5px !important;
  }
  .ms-mdlg-n3vh {
    margin-left: -3vh !important;
  }
  .ms-mdlg-n10vh {
    margin-left: -10vh !important;
  }
  .ms-mdlg-n1 {
    margin-left: -0.6667rem !important;
  }
  .ms-mdlg-n2 {
    margin-left: -1.3334rem !important;
  }
  .ms-mdlg-n3 {
    margin-left: -2.0001rem !important;
  }
  .ms-mdlg-n4 {
    margin-left: -2.6668rem !important;
  }
  .ms-mdlg-n5 {
    margin-left: -3.3335rem !important;
  }
  .ms-mdlg-n6 {
    margin-left: -4.0002rem !important;
  }
  .ms-mdlg-n7 {
    margin-left: -4.6669rem !important;
  }
  .ms-mdlg-n8 {
    margin-left: -5.3336rem !important;
  }
  .ms-mdlg-n9 {
    margin-left: -6.0003rem !important;
  }
  .ms-mdlg-n10 {
    margin-left: -6.667rem !important;
  }
  .ms-mdlg-ngutter {
    margin-left: -24px !important;
  }
  .ms-mdlg-nout-container {
    margin-left: -calc(50% - 50vw) !important;
  }
  .list-style-mdlg-none {
    list-style: none !important;
  }
  .list-style-mdlg-circle {
    list-style: circle !important;
  }
  .list-style-mdlg-disc {
    list-style: disc !important;
  }
  .float-mdlg-start {
    float: left !important;
  }
  .float-mdlg-end {
    float: right !important;
  }
  .float-mdlg-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .font-lg-sans-serif {
    font-family: "arial-regular", sans-serif !important;
  }
  .font-lg-sans-serif-italic {
    font-family: "arial-italic", sans-serif !important;
  }
  .font-lg-sans-serif-bolditalic {
    font-family: "arial-bolditalic", sans-serif !important;
  }
  .font-lg-sans-serif-bold {
    font-family: "arial-bold", sans-serif !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .bg-lg-primary {
    --bs-bg-opacity: 1;
    background-color: #0a3255 !important;
  }
  .bg-lg-primary-light {
    --bs-bg-opacity: 1;
    background-color: rgba(10, 50, 85, 0.5) !important;
  }
  .bg-lg-secondary {
    --bs-bg-opacity: 1;
    background-color: #da262e !important;
  }
  .bg-lg-secondary-light {
    --bs-bg-opacity: 1;
    background-color: rgba(218, 38, 46, 0.5) !important;
  }
  .bg-lg-westar {
    --bs-bg-opacity: 1;
    background-color: #ddd8ce !important;
  }
  .bg-lg-swirl {
    --bs-bg-opacity: 1;
    background-color: #d7cdca !important;
  }
  .bg-lg-light {
    --bs-bg-opacity: 1;
    background-color: #fbfbfb !important;
  }
  .bg-lg-success {
    --bs-bg-opacity: 1;
    background-color: #1acf82 !important;
  }
  .bg-lg-gray-100 {
    --bs-bg-opacity: 1;
    background-color: #9a9ea6 !important;
  }
  .bg-lg-gray-200 {
    --bs-bg-opacity: 1;
    background-color: #f6f5f5 !important;
  }
  .bg-lg-gray-300 {
    --bs-bg-opacity: 1;
    background-color: #d0cfcd !important;
  }
  .bg-lg-gray-400 {
    --bs-bg-opacity: 1;
    background-color: #efefef !important;
  }
  .bg-lg-gray-500 {
    --bs-bg-opacity: 1;
    background-color: #2a2644 !important;
  }
  .bg-lg-gray-600 {
    --bs-bg-opacity: 1;
    background-color: #e3e3e3 !important;
  }
  .bg-lg-gray-800 {
    --bs-bg-opacity: 1;
    background-color: #e0e0e0 !important;
  }
  .bg-lg-gray-900 {
    --bs-bg-opacity: 1;
    background-color: #c4c4c4 !important;
  }
  .bg-lg-body {
    --bs-bg-opacity: 1;
    background-color: #0a3255 !important;
  }
  .bg-lg-info {
    --bs-bg-opacity: 1;
    background-color: #60b8d0 !important;
  }
  .bg-lg-info-100 {
    --bs-bg-opacity: 1;
    background-color: #0083c1 !important;
  }
  .bg-lg-info-200 {
    --bs-bg-opacity: 1;
    background-color: #52b1c5 !important;
  }
  .bg-lg-info-300 {
    --bs-bg-opacity: 1;
    background-color: #97d1dc !important;
  }
  .bg-lg-info-400 {
    --bs-bg-opacity: 1;
    background-color: #40a7be !important;
  }
  .bg-lg-warning {
    --bs-bg-opacity: 1;
    background-color: #d3ad57 !important;
  }
  .bg-lg-danger {
    --bs-bg-opacity: 1;
    background-color: #d7282f !important;
  }
  .bg-lg-dark {
    --bs-bg-opacity: 1;
    background-color: #e9e9e9 !important;
  }
  .bg-lg-black {
    --bs-bg-opacity: 1;
    background-color: #000 !important;
  }
  .bg-lg-red {
    --bs-bg-opacity: 1;
    background-color: #d7282f !important;
  }
  .bg-lg-white {
    --bs-bg-opacity: 1;
    background-color: #ffffff !important;
  }
  .bg-lg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .text-lg-primary {
    color: #0a3255 !important;
  }
  .text-lg-primary-light {
    color: rgba(10, 50, 85, 0.5) !important;
  }
  .text-lg-secondary {
    color: #da262e !important;
  }
  .text-lg-secondary-light {
    color: rgba(218, 38, 46, 0.5) !important;
  }
  .text-lg-westar {
    color: #ddd8ce !important;
  }
  .text-lg-swirl {
    color: #d7cdca !important;
  }
  .text-lg-light {
    color: #fbfbfb !important;
  }
  .text-lg-success {
    color: #1acf82 !important;
  }
  .text-lg-gray-100 {
    color: #9a9ea6 !important;
  }
  .text-lg-gray-200 {
    color: #f6f5f5 !important;
  }
  .text-lg-gray-300 {
    color: #d0cfcd !important;
  }
  .text-lg-gray-400 {
    color: #efefef !important;
  }
  .text-lg-gray-500 {
    color: #2a2644 !important;
  }
  .text-lg-gray-600 {
    color: #e3e3e3 !important;
  }
  .text-lg-gray-800 {
    color: #e0e0e0 !important;
  }
  .text-lg-gray-900 {
    color: #c4c4c4 !important;
  }
  .text-lg-body {
    color: #0a3255 !important;
  }
  .text-lg-info {
    color: #60b8d0 !important;
  }
  .text-lg-info-100 {
    color: #0083c1 !important;
  }
  .text-lg-info-200 {
    color: #52b1c5 !important;
  }
  .text-lg-info-300 {
    color: #97d1dc !important;
  }
  .text-lg-info-400 {
    color: #40a7be !important;
  }
  .text-lg-warning {
    color: #d3ad57 !important;
  }
  .text-lg-danger {
    color: #d7282f !important;
  }
  .text-lg-dark {
    color: #e9e9e9 !important;
  }
  .text-lg-black {
    color: #000 !important;
  }
  .text-lg-red {
    color: #d7282f !important;
  }
  .text-lg-white {
    color: #ffffff !important;
  }
  .text-lg-transparent {
    color: transparent !important;
  }
  .m-lg-sm {
    margin: 0.33335rem !important;
  }
  .m-lg-3px {
    margin: 3px !important;
  }
  .m-lg-4px {
    margin: 4px !important;
  }
  .m-lg-8px {
    margin: 8px !important;
  }
  .m-lg-12px {
    margin: 12px !important;
  }
  .m-lg-15px {
    margin: 15px !important;
  }
  .m-lg-16px {
    margin: 16px !important;
  }
  .m-lg-18px {
    margin: 18px !important;
  }
  .m-lg-24px {
    margin: 24px !important;
  }
  .m-lg-25px {
    margin: 25px !important;
  }
  .m-lg-30px {
    margin: 30px !important;
  }
  .m-lg-32px {
    margin: 32px !important;
  }
  .m-lg-57px {
    margin: 57px !important;
  }
  .m-lg-n15 {
    margin: -15px !important;
  }
  .m-lg-n5 {
    margin: -5px !important;
  }
  .m-lg-3vh {
    margin: 3vh !important;
  }
  .m-lg-10vh {
    margin: 10vh !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.6667rem !important;
  }
  .m-lg-2 {
    margin: 1.3334rem !important;
  }
  .m-lg-3 {
    margin: 2.0001rem !important;
  }
  .m-lg-4 {
    margin: 2.6668rem !important;
  }
  .m-lg-5 {
    margin: 3.3335rem !important;
  }
  .m-lg-6 {
    margin: 4.0002rem !important;
  }
  .m-lg-7 {
    margin: 4.6669rem !important;
  }
  .m-lg-8 {
    margin: 5.3336rem !important;
  }
  .m-lg-9 {
    margin: 6.0003rem !important;
  }
  .m-lg-10 {
    margin: 6.667rem !important;
  }
  .m-lg-gutter {
    margin: 24px !important;
  }
  .m-lg-out-container {
    margin: calc(50% - 50vw) !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-sm {
    margin-right: 0.33335rem !important;
    margin-left: 0.33335rem !important;
  }
  .mx-lg-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-lg-4px {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .mx-lg-8px {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .mx-lg-12px {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .mx-lg-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-lg-16px {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .mx-lg-18px {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
  .mx-lg-24px {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  .mx-lg-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-lg-30px {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-lg-32px {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
  .mx-lg-57px {
    margin-right: 57px !important;
    margin-left: 57px !important;
  }
  .mx-lg-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-lg-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-lg-3vh {
    margin-right: 3vh !important;
    margin-left: 3vh !important;
  }
  .mx-lg-10vh {
    margin-right: 10vh !important;
    margin-left: 10vh !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.6667rem !important;
    margin-left: 0.6667rem !important;
  }
  .mx-lg-2 {
    margin-right: 1.3334rem !important;
    margin-left: 1.3334rem !important;
  }
  .mx-lg-3 {
    margin-right: 2.0001rem !important;
    margin-left: 2.0001rem !important;
  }
  .mx-lg-4 {
    margin-right: 2.6668rem !important;
    margin-left: 2.6668rem !important;
  }
  .mx-lg-5 {
    margin-right: 3.3335rem !important;
    margin-left: 3.3335rem !important;
  }
  .mx-lg-6 {
    margin-right: 4.0002rem !important;
    margin-left: 4.0002rem !important;
  }
  .mx-lg-7 {
    margin-right: 4.6669rem !important;
    margin-left: 4.6669rem !important;
  }
  .mx-lg-8 {
    margin-right: 5.3336rem !important;
    margin-left: 5.3336rem !important;
  }
  .mx-lg-9 {
    margin-right: 6.0003rem !important;
    margin-left: 6.0003rem !important;
  }
  .mx-lg-10 {
    margin-right: 6.667rem !important;
    margin-left: 6.667rem !important;
  }
  .mx-lg-gutter {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  .mx-lg-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-sm {
    margin-top: 0.33335rem !important;
    margin-bottom: 0.33335rem !important;
  }
  .my-lg-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-lg-4px {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .my-lg-8px {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .my-lg-12px {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .my-lg-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-lg-16px {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .my-lg-18px {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .my-lg-24px {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .my-lg-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-lg-30px {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-lg-32px {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .my-lg-57px {
    margin-top: 57px !important;
    margin-bottom: 57px !important;
  }
  .my-lg-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-lg-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-lg-3vh {
    margin-top: 3vh !important;
    margin-bottom: 3vh !important;
  }
  .my-lg-10vh {
    margin-top: 10vh !important;
    margin-bottom: 10vh !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.6667rem !important;
    margin-bottom: 0.6667rem !important;
  }
  .my-lg-2 {
    margin-top: 1.3334rem !important;
    margin-bottom: 1.3334rem !important;
  }
  .my-lg-3 {
    margin-top: 2.0001rem !important;
    margin-bottom: 2.0001rem !important;
  }
  .my-lg-4 {
    margin-top: 2.6668rem !important;
    margin-bottom: 2.6668rem !important;
  }
  .my-lg-5 {
    margin-top: 3.3335rem !important;
    margin-bottom: 3.3335rem !important;
  }
  .my-lg-6 {
    margin-top: 4.0002rem !important;
    margin-bottom: 4.0002rem !important;
  }
  .my-lg-7 {
    margin-top: 4.6669rem !important;
    margin-bottom: 4.6669rem !important;
  }
  .my-lg-8 {
    margin-top: 5.3336rem !important;
    margin-bottom: 5.3336rem !important;
  }
  .my-lg-9 {
    margin-top: 6.0003rem !important;
    margin-bottom: 6.0003rem !important;
  }
  .my-lg-10 {
    margin-top: 6.667rem !important;
    margin-bottom: 6.667rem !important;
  }
  .my-lg-gutter {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .my-lg-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-sm {
    margin-top: 0.33335rem !important;
  }
  .mt-lg-3px {
    margin-top: 3px !important;
  }
  .mt-lg-4px {
    margin-top: 4px !important;
  }
  .mt-lg-8px {
    margin-top: 8px !important;
  }
  .mt-lg-12px {
    margin-top: 12px !important;
  }
  .mt-lg-15px {
    margin-top: 15px !important;
  }
  .mt-lg-16px {
    margin-top: 16px !important;
  }
  .mt-lg-18px {
    margin-top: 18px !important;
  }
  .mt-lg-24px {
    margin-top: 24px !important;
  }
  .mt-lg-25px {
    margin-top: 25px !important;
  }
  .mt-lg-30px {
    margin-top: 30px !important;
  }
  .mt-lg-32px {
    margin-top: 32px !important;
  }
  .mt-lg-57px {
    margin-top: 57px !important;
  }
  .mt-lg-n15 {
    margin-top: -15px !important;
  }
  .mt-lg-n5 {
    margin-top: -5px !important;
  }
  .mt-lg-3vh {
    margin-top: 3vh !important;
  }
  .mt-lg-10vh {
    margin-top: 10vh !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.6667rem !important;
  }
  .mt-lg-2 {
    margin-top: 1.3334rem !important;
  }
  .mt-lg-3 {
    margin-top: 2.0001rem !important;
  }
  .mt-lg-4 {
    margin-top: 2.6668rem !important;
  }
  .mt-lg-5 {
    margin-top: 3.3335rem !important;
  }
  .mt-lg-6 {
    margin-top: 4.0002rem !important;
  }
  .mt-lg-7 {
    margin-top: 4.6669rem !important;
  }
  .mt-lg-8 {
    margin-top: 5.3336rem !important;
  }
  .mt-lg-9 {
    margin-top: 6.0003rem !important;
  }
  .mt-lg-10 {
    margin-top: 6.667rem !important;
  }
  .mt-lg-gutter {
    margin-top: 24px !important;
  }
  .mt-lg-out-container {
    margin-top: calc(50% - 50vw) !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-sm {
    margin-right: 0.33335rem !important;
  }
  .me-lg-3px {
    margin-right: 3px !important;
  }
  .me-lg-4px {
    margin-right: 4px !important;
  }
  .me-lg-8px {
    margin-right: 8px !important;
  }
  .me-lg-12px {
    margin-right: 12px !important;
  }
  .me-lg-15px {
    margin-right: 15px !important;
  }
  .me-lg-16px {
    margin-right: 16px !important;
  }
  .me-lg-18px {
    margin-right: 18px !important;
  }
  .me-lg-24px {
    margin-right: 24px !important;
  }
  .me-lg-25px {
    margin-right: 25px !important;
  }
  .me-lg-30px {
    margin-right: 30px !important;
  }
  .me-lg-32px {
    margin-right: 32px !important;
  }
  .me-lg-57px {
    margin-right: 57px !important;
  }
  .me-lg-n15 {
    margin-right: -15px !important;
  }
  .me-lg-n5 {
    margin-right: -5px !important;
  }
  .me-lg-3vh {
    margin-right: 3vh !important;
  }
  .me-lg-10vh {
    margin-right: 10vh !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.6667rem !important;
  }
  .me-lg-2 {
    margin-right: 1.3334rem !important;
  }
  .me-lg-3 {
    margin-right: 2.0001rem !important;
  }
  .me-lg-4 {
    margin-right: 2.6668rem !important;
  }
  .me-lg-5 {
    margin-right: 3.3335rem !important;
  }
  .me-lg-6 {
    margin-right: 4.0002rem !important;
  }
  .me-lg-7 {
    margin-right: 4.6669rem !important;
  }
  .me-lg-8 {
    margin-right: 5.3336rem !important;
  }
  .me-lg-9 {
    margin-right: 6.0003rem !important;
  }
  .me-lg-10 {
    margin-right: 6.667rem !important;
  }
  .me-lg-gutter {
    margin-right: 24px !important;
  }
  .me-lg-out-container {
    margin-right: calc(50% - 50vw) !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-sm {
    margin-bottom: 0.33335rem !important;
  }
  .mb-lg-3px {
    margin-bottom: 3px !important;
  }
  .mb-lg-4px {
    margin-bottom: 4px !important;
  }
  .mb-lg-8px {
    margin-bottom: 8px !important;
  }
  .mb-lg-12px {
    margin-bottom: 12px !important;
  }
  .mb-lg-15px {
    margin-bottom: 15px !important;
  }
  .mb-lg-16px {
    margin-bottom: 16px !important;
  }
  .mb-lg-18px {
    margin-bottom: 18px !important;
  }
  .mb-lg-24px {
    margin-bottom: 24px !important;
  }
  .mb-lg-25px {
    margin-bottom: 25px !important;
  }
  .mb-lg-30px {
    margin-bottom: 30px !important;
  }
  .mb-lg-32px {
    margin-bottom: 32px !important;
  }
  .mb-lg-57px {
    margin-bottom: 57px !important;
  }
  .mb-lg-n15 {
    margin-bottom: -15px !important;
  }
  .mb-lg-n5 {
    margin-bottom: -5px !important;
  }
  .mb-lg-3vh {
    margin-bottom: 3vh !important;
  }
  .mb-lg-10vh {
    margin-bottom: 10vh !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.6667rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 1.3334rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 2.0001rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 2.6668rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3.3335rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 4.0002rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 4.6669rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 5.3336rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 6.0003rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 6.667rem !important;
  }
  .mb-lg-gutter {
    margin-bottom: 24px !important;
  }
  .mb-lg-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-sm {
    margin-left: 0.33335rem !important;
  }
  .ms-lg-3px {
    margin-left: 3px !important;
  }
  .ms-lg-4px {
    margin-left: 4px !important;
  }
  .ms-lg-8px {
    margin-left: 8px !important;
  }
  .ms-lg-12px {
    margin-left: 12px !important;
  }
  .ms-lg-15px {
    margin-left: 15px !important;
  }
  .ms-lg-16px {
    margin-left: 16px !important;
  }
  .ms-lg-18px {
    margin-left: 18px !important;
  }
  .ms-lg-24px {
    margin-left: 24px !important;
  }
  .ms-lg-25px {
    margin-left: 25px !important;
  }
  .ms-lg-30px {
    margin-left: 30px !important;
  }
  .ms-lg-32px {
    margin-left: 32px !important;
  }
  .ms-lg-57px {
    margin-left: 57px !important;
  }
  .ms-lg-n15 {
    margin-left: -15px !important;
  }
  .ms-lg-n5 {
    margin-left: -5px !important;
  }
  .ms-lg-3vh {
    margin-left: 3vh !important;
  }
  .ms-lg-10vh {
    margin-left: 10vh !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.6667rem !important;
  }
  .ms-lg-2 {
    margin-left: 1.3334rem !important;
  }
  .ms-lg-3 {
    margin-left: 2.0001rem !important;
  }
  .ms-lg-4 {
    margin-left: 2.6668rem !important;
  }
  .ms-lg-5 {
    margin-left: 3.3335rem !important;
  }
  .ms-lg-6 {
    margin-left: 4.0002rem !important;
  }
  .ms-lg-7 {
    margin-left: 4.6669rem !important;
  }
  .ms-lg-8 {
    margin-left: 5.3336rem !important;
  }
  .ms-lg-9 {
    margin-left: 6.0003rem !important;
  }
  .ms-lg-10 {
    margin-left: 6.667rem !important;
  }
  .ms-lg-gutter {
    margin-left: 24px !important;
  }
  .ms-lg-out-container {
    margin-left: calc(50% - 50vw) !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-sm {
    padding: 0.33335rem !important;
  }
  .p-lg-3px {
    padding: 3px !important;
  }
  .p-lg-4px {
    padding: 4px !important;
  }
  .p-lg-8px {
    padding: 8px !important;
  }
  .p-lg-12px {
    padding: 12px !important;
  }
  .p-lg-15px {
    padding: 15px !important;
  }
  .p-lg-16px {
    padding: 16px !important;
  }
  .p-lg-18px {
    padding: 18px !important;
  }
  .p-lg-24px {
    padding: 24px !important;
  }
  .p-lg-25px {
    padding: 25px !important;
  }
  .p-lg-30px {
    padding: 30px !important;
  }
  .p-lg-32px {
    padding: 32px !important;
  }
  .p-lg-57px {
    padding: 57px !important;
  }
  .p-lg-n15 {
    padding: -15px !important;
  }
  .p-lg-n5 {
    padding: -5px !important;
  }
  .p-lg-3vh {
    padding: 3vh !important;
  }
  .p-lg-10vh {
    padding: 10vh !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.6667rem !important;
  }
  .p-lg-2 {
    padding: 1.3334rem !important;
  }
  .p-lg-3 {
    padding: 2.0001rem !important;
  }
  .p-lg-4 {
    padding: 2.6668rem !important;
  }
  .p-lg-5 {
    padding: 3.3335rem !important;
  }
  .p-lg-6 {
    padding: 4.0002rem !important;
  }
  .p-lg-7 {
    padding: 4.6669rem !important;
  }
  .p-lg-8 {
    padding: 5.3336rem !important;
  }
  .p-lg-9 {
    padding: 6.0003rem !important;
  }
  .p-lg-10 {
    padding: 6.667rem !important;
  }
  .p-lg-gutter {
    padding: 24px !important;
  }
  .p-lg-out-container {
    padding: calc(50% - 50vw) !important;
  }
  .px-lg-sm {
    padding-right: 0.33335rem !important;
    padding-left: 0.33335rem !important;
  }
  .px-lg-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-lg-4px {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .px-lg-8px {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .px-lg-12px {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .px-lg-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-lg-16px {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .px-lg-18px {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
  .px-lg-24px {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .px-lg-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-lg-30px {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-lg-32px {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
  .px-lg-57px {
    padding-right: 57px !important;
    padding-left: 57px !important;
  }
  .px-lg-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }
  .px-lg-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }
  .px-lg-3vh {
    padding-right: 3vh !important;
    padding-left: 3vh !important;
  }
  .px-lg-10vh {
    padding-right: 10vh !important;
    padding-left: 10vh !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.6667rem !important;
    padding-left: 0.6667rem !important;
  }
  .px-lg-2 {
    padding-right: 1.3334rem !important;
    padding-left: 1.3334rem !important;
  }
  .px-lg-3 {
    padding-right: 2.0001rem !important;
    padding-left: 2.0001rem !important;
  }
  .px-lg-4 {
    padding-right: 2.6668rem !important;
    padding-left: 2.6668rem !important;
  }
  .px-lg-5 {
    padding-right: 3.3335rem !important;
    padding-left: 3.3335rem !important;
  }
  .px-lg-6 {
    padding-right: 4.0002rem !important;
    padding-left: 4.0002rem !important;
  }
  .px-lg-7 {
    padding-right: 4.6669rem !important;
    padding-left: 4.6669rem !important;
  }
  .px-lg-8 {
    padding-right: 5.3336rem !important;
    padding-left: 5.3336rem !important;
  }
  .px-lg-9 {
    padding-right: 6.0003rem !important;
    padding-left: 6.0003rem !important;
  }
  .px-lg-10 {
    padding-right: 6.667rem !important;
    padding-left: 6.667rem !important;
  }
  .px-lg-gutter {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .px-lg-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }
  .py-lg-sm {
    padding-top: 0.33335rem !important;
    padding-bottom: 0.33335rem !important;
  }
  .py-lg-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-lg-4px {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .py-lg-8px {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .py-lg-12px {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .py-lg-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-lg-16px {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .py-lg-18px {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .py-lg-24px {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-lg-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-lg-30px {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-lg-32px {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .py-lg-57px {
    padding-top: 57px !important;
    padding-bottom: 57px !important;
  }
  .py-lg-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }
  .py-lg-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }
  .py-lg-3vh {
    padding-top: 3vh !important;
    padding-bottom: 3vh !important;
  }
  .py-lg-10vh {
    padding-top: 10vh !important;
    padding-bottom: 10vh !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.6667rem !important;
    padding-bottom: 0.6667rem !important;
  }
  .py-lg-2 {
    padding-top: 1.3334rem !important;
    padding-bottom: 1.3334rem !important;
  }
  .py-lg-3 {
    padding-top: 2.0001rem !important;
    padding-bottom: 2.0001rem !important;
  }
  .py-lg-4 {
    padding-top: 2.6668rem !important;
    padding-bottom: 2.6668rem !important;
  }
  .py-lg-5 {
    padding-top: 3.3335rem !important;
    padding-bottom: 3.3335rem !important;
  }
  .py-lg-6 {
    padding-top: 4.0002rem !important;
    padding-bottom: 4.0002rem !important;
  }
  .py-lg-7 {
    padding-top: 4.6669rem !important;
    padding-bottom: 4.6669rem !important;
  }
  .py-lg-8 {
    padding-top: 5.3336rem !important;
    padding-bottom: 5.3336rem !important;
  }
  .py-lg-9 {
    padding-top: 6.0003rem !important;
    padding-bottom: 6.0003rem !important;
  }
  .py-lg-10 {
    padding-top: 6.667rem !important;
    padding-bottom: 6.667rem !important;
  }
  .py-lg-gutter {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-lg-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }
  .pt-lg-sm {
    padding-top: 0.33335rem !important;
  }
  .pt-lg-3px {
    padding-top: 3px !important;
  }
  .pt-lg-4px {
    padding-top: 4px !important;
  }
  .pt-lg-8px {
    padding-top: 8px !important;
  }
  .pt-lg-12px {
    padding-top: 12px !important;
  }
  .pt-lg-15px {
    padding-top: 15px !important;
  }
  .pt-lg-16px {
    padding-top: 16px !important;
  }
  .pt-lg-18px {
    padding-top: 18px !important;
  }
  .pt-lg-24px {
    padding-top: 24px !important;
  }
  .pt-lg-25px {
    padding-top: 25px !important;
  }
  .pt-lg-30px {
    padding-top: 30px !important;
  }
  .pt-lg-32px {
    padding-top: 32px !important;
  }
  .pt-lg-57px {
    padding-top: 57px !important;
  }
  .pt-lg-n15 {
    padding-top: -15px !important;
  }
  .pt-lg-n5 {
    padding-top: -5px !important;
  }
  .pt-lg-3vh {
    padding-top: 3vh !important;
  }
  .pt-lg-10vh {
    padding-top: 10vh !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.6667rem !important;
  }
  .pt-lg-2 {
    padding-top: 1.3334rem !important;
  }
  .pt-lg-3 {
    padding-top: 2.0001rem !important;
  }
  .pt-lg-4 {
    padding-top: 2.6668rem !important;
  }
  .pt-lg-5 {
    padding-top: 3.3335rem !important;
  }
  .pt-lg-6 {
    padding-top: 4.0002rem !important;
  }
  .pt-lg-7 {
    padding-top: 4.6669rem !important;
  }
  .pt-lg-8 {
    padding-top: 5.3336rem !important;
  }
  .pt-lg-9 {
    padding-top: 6.0003rem !important;
  }
  .pt-lg-10 {
    padding-top: 6.667rem !important;
  }
  .pt-lg-gutter {
    padding-top: 24px !important;
  }
  .pt-lg-out-container {
    padding-top: calc(50% - 50vw) !important;
  }
  .pe-lg-sm {
    padding-right: 0.33335rem !important;
  }
  .pe-lg-3px {
    padding-right: 3px !important;
  }
  .pe-lg-4px {
    padding-right: 4px !important;
  }
  .pe-lg-8px {
    padding-right: 8px !important;
  }
  .pe-lg-12px {
    padding-right: 12px !important;
  }
  .pe-lg-15px {
    padding-right: 15px !important;
  }
  .pe-lg-16px {
    padding-right: 16px !important;
  }
  .pe-lg-18px {
    padding-right: 18px !important;
  }
  .pe-lg-24px {
    padding-right: 24px !important;
  }
  .pe-lg-25px {
    padding-right: 25px !important;
  }
  .pe-lg-30px {
    padding-right: 30px !important;
  }
  .pe-lg-32px {
    padding-right: 32px !important;
  }
  .pe-lg-57px {
    padding-right: 57px !important;
  }
  .pe-lg-n15 {
    padding-right: -15px !important;
  }
  .pe-lg-n5 {
    padding-right: -5px !important;
  }
  .pe-lg-3vh {
    padding-right: 3vh !important;
  }
  .pe-lg-10vh {
    padding-right: 10vh !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.6667rem !important;
  }
  .pe-lg-2 {
    padding-right: 1.3334rem !important;
  }
  .pe-lg-3 {
    padding-right: 2.0001rem !important;
  }
  .pe-lg-4 {
    padding-right: 2.6668rem !important;
  }
  .pe-lg-5 {
    padding-right: 3.3335rem !important;
  }
  .pe-lg-6 {
    padding-right: 4.0002rem !important;
  }
  .pe-lg-7 {
    padding-right: 4.6669rem !important;
  }
  .pe-lg-8 {
    padding-right: 5.3336rem !important;
  }
  .pe-lg-9 {
    padding-right: 6.0003rem !important;
  }
  .pe-lg-10 {
    padding-right: 6.667rem !important;
  }
  .pe-lg-gutter {
    padding-right: 24px !important;
  }
  .pe-lg-out-container {
    padding-right: calc(50% - 50vw) !important;
  }
  .pb-lg-sm {
    padding-bottom: 0.33335rem !important;
  }
  .pb-lg-3px {
    padding-bottom: 3px !important;
  }
  .pb-lg-4px {
    padding-bottom: 4px !important;
  }
  .pb-lg-8px {
    padding-bottom: 8px !important;
  }
  .pb-lg-12px {
    padding-bottom: 12px !important;
  }
  .pb-lg-15px {
    padding-bottom: 15px !important;
  }
  .pb-lg-16px {
    padding-bottom: 16px !important;
  }
  .pb-lg-18px {
    padding-bottom: 18px !important;
  }
  .pb-lg-24px {
    padding-bottom: 24px !important;
  }
  .pb-lg-25px {
    padding-bottom: 25px !important;
  }
  .pb-lg-30px {
    padding-bottom: 30px !important;
  }
  .pb-lg-32px {
    padding-bottom: 32px !important;
  }
  .pb-lg-57px {
    padding-bottom: 57px !important;
  }
  .pb-lg-n15 {
    padding-bottom: -15px !important;
  }
  .pb-lg-n5 {
    padding-bottom: -5px !important;
  }
  .pb-lg-3vh {
    padding-bottom: 3vh !important;
  }
  .pb-lg-10vh {
    padding-bottom: 10vh !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.6667rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 1.3334rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 2.0001rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 2.6668rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3.3335rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 4.0002rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 4.6669rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 5.3336rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 6.0003rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 6.667rem !important;
  }
  .pb-lg-gutter {
    padding-bottom: 24px !important;
  }
  .pb-lg-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }
  .ps-lg-sm {
    padding-left: 0.33335rem !important;
  }
  .ps-lg-3px {
    padding-left: 3px !important;
  }
  .ps-lg-4px {
    padding-left: 4px !important;
  }
  .ps-lg-8px {
    padding-left: 8px !important;
  }
  .ps-lg-12px {
    padding-left: 12px !important;
  }
  .ps-lg-15px {
    padding-left: 15px !important;
  }
  .ps-lg-16px {
    padding-left: 16px !important;
  }
  .ps-lg-18px {
    padding-left: 18px !important;
  }
  .ps-lg-24px {
    padding-left: 24px !important;
  }
  .ps-lg-25px {
    padding-left: 25px !important;
  }
  .ps-lg-30px {
    padding-left: 30px !important;
  }
  .ps-lg-32px {
    padding-left: 32px !important;
  }
  .ps-lg-57px {
    padding-left: 57px !important;
  }
  .ps-lg-n15 {
    padding-left: -15px !important;
  }
  .ps-lg-n5 {
    padding-left: -5px !important;
  }
  .ps-lg-3vh {
    padding-left: 3vh !important;
  }
  .ps-lg-10vh {
    padding-left: 10vh !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.6667rem !important;
  }
  .ps-lg-2 {
    padding-left: 1.3334rem !important;
  }
  .ps-lg-3 {
    padding-left: 2.0001rem !important;
  }
  .ps-lg-4 {
    padding-left: 2.6668rem !important;
  }
  .ps-lg-5 {
    padding-left: 3.3335rem !important;
  }
  .ps-lg-6 {
    padding-left: 4.0002rem !important;
  }
  .ps-lg-7 {
    padding-left: 4.6669rem !important;
  }
  .ps-lg-8 {
    padding-left: 5.3336rem !important;
  }
  .ps-lg-9 {
    padding-left: 6.0003rem !important;
  }
  .ps-lg-10 {
    padding-left: 6.667rem !important;
  }
  .ps-lg-gutter {
    padding-left: 24px !important;
  }
  .ps-lg-out-container {
    padding-left: calc(50% - 50vw) !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-sm {
    gap: 0.33335rem !important;
  }
  .gap-lg-3px {
    gap: 3px !important;
  }
  .gap-lg-4px {
    gap: 4px !important;
  }
  .gap-lg-8px {
    gap: 8px !important;
  }
  .gap-lg-12px {
    gap: 12px !important;
  }
  .gap-lg-15px {
    gap: 15px !important;
  }
  .gap-lg-16px {
    gap: 16px !important;
  }
  .gap-lg-18px {
    gap: 18px !important;
  }
  .gap-lg-24px {
    gap: 24px !important;
  }
  .gap-lg-25px {
    gap: 25px !important;
  }
  .gap-lg-30px {
    gap: 30px !important;
  }
  .gap-lg-32px {
    gap: 32px !important;
  }
  .gap-lg-57px {
    gap: 57px !important;
  }
  .gap-lg-n15 {
    gap: -15px !important;
  }
  .gap-lg-n5 {
    gap: -5px !important;
  }
  .gap-lg-3vh {
    gap: 3vh !important;
  }
  .gap-lg-10vh {
    gap: 10vh !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.6667rem !important;
  }
  .gap-lg-2 {
    gap: 1.3334rem !important;
  }
  .gap-lg-3 {
    gap: 2.0001rem !important;
  }
  .gap-lg-4 {
    gap: 2.6668rem !important;
  }
  .gap-lg-5 {
    gap: 3.3335rem !important;
  }
  .gap-lg-6 {
    gap: 4.0002rem !important;
  }
  .gap-lg-7 {
    gap: 4.6669rem !important;
  }
  .gap-lg-8 {
    gap: 5.3336rem !important;
  }
  .gap-lg-9 {
    gap: 6.0003rem !important;
  }
  .gap-lg-10 {
    gap: 6.667rem !important;
  }
  .gap-lg-gutter {
    gap: 24px !important;
  }
  .gap-lg-out-container {
    gap: calc(50% - 50vw) !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .vh-lg-30 {
    height: 30vh !important;
  }
  .vh-lg-40 {
    height: 40vh !important;
  }
  .vh-lg-50 {
    height: 50vh !important;
  }
  .vh-lg-60 {
    height: 60vh !important;
  }
  .vh-lg-65 {
    height: 65vh !important;
  }
  .vh-lg-70 {
    height: 70vh !important;
  }
  .vh-lg-80 {
    height: 80vh !important;
  }
  .vh-lg-100 {
    height: 100vh !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-35 {
    width: 35% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-45 {
    width: 45% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-55 {
    width: 55% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-64 {
    width: 64% !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-82 {
    width: 82% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-20px {
    width: 20px !important;
  }
  .w-lg-80px {
    width: 80px !important;
  }
  .w-lg-icon {
    width: 24px !important;
  }
  .w-lg-fit {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-20px {
    height: 20px !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .h-lg-icon {
    height: 24px !important;
  }
  .h-lg-400 {
    height: 400px !important;
  }
  .h-lg-550 {
    height: 550px !important;
  }
  .h-lg-fit {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .fs-lg-1 {
    font-size: 3.5rem !important;
  }
  .fs-lg-2 {
    font-size: 2rem !important;
  }
  .fs-lg-3 {
    font-size: 1.5rem !important;
  }
  .fs-lg-4 {
    font-size: 1.25rem !important;
  }
  .fs-lg-5 {
    font-size: 1.125rem !important;
  }
  .fs-lg-6 {
    font-size: 1rem !important;
  }
  .fs-lg-7 {
    font-size: 0.875rem !important;
  }
  .fs-lg-22 {
    font-size: 1.375rem !important;
  }
  .fs-lg-40 {
    font-size: 2.5rem !important;
  }
  .fs-lg-48 {
    font-size: 3rem !important;
  }
  .fs-lg-lg {
    font-size: 3.375rem !important;
  }
  .fs-lg-xl {
    font-size: 5rem !important;
  }
  .fs-lg-sm {
    font-size: 0.75rem !important;
  }
  .fs-lg-icon {
    font-size: 1.5625rem !important;
  }
  .lh-lg-1 {
    line-height: 1 !important;
  }
  .lh-lg-sm {
    line-height: 1.25 !important;
  }
  .lh-lg-base {
    line-height: 1.5 !important;
  }
  .lh-lg-md {
    line-height: 1.65 !important;
  }
  .lh-lg-lg {
    line-height: 2 !important;
  }
  .lh-lg-3 {
    line-height: 3 !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .order-lg-unset {
    order: unset !important;
  }
  .cursor-lg-auto {
    cursor: auto !important;
  }
  .cursor-lg-pointer {
    cursor: pointer !important;
  }
  .cursor-lg-grab {
    cursor: -webkit-grab !important;
    cursor: grab !important;
  }
  .border-lg {
    border: 1px solid #d0cfcd !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-lg-2 {
    border: 2px solid !important;
  }
  .border-top-lg {
    border-top: 1px solid #d0cfcd !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-start-lg {
    border-left: 1px solid #d0cfcd !important;
  }
  .border-start-lg-0 {
    border-left: 0 !important;
  }
  .border-end-lg {
    border-right: 1px solid #d0cfcd !important;
  }
  .border-end-lg-0 {
    border-right: 0 !important;
  }
  .border-bottom-lg {
    border-bottom: 1px solid #d0cfcd !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .shadow-lg {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  }
  .shadow-lg-small {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-lg-large {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05) !important;
  }
  .shadow-lg-none {
    box-shadow: none !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .text-decoration-lg-none {
    text-decoration: none !important;
  }
  .text-decoration-lg-underline {
    text-decoration: underline !important;
  }
  .text-decoration-lg-line-through {
    text-decoration: line-through !important;
  }
  .text-lg-lowercase {
    text-transform: lowercase !important;
  }
  .text-lg-uppercase {
    text-transform: uppercase !important;
  }
  .text-lg-capitalize {
    text-transform: capitalize !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
  .top-lg-0 {
    top: 0 !important;
  }
  .top-lg-25 {
    top: 25% !important;
  }
  .top-lg-50 {
    top: 50% !important;
  }
  .top-lg-75 {
    top: 75% !important;
  }
  .top-lg-100 {
    top: 100% !important;
  }
  .top-lg-10 {
    top: 10px !important;
  }
  .top-lg-20 {
    top: 20px !important;
  }
  .top-lg-30 {
    top: 30px !important;
  }
  .top-lg-n10 {
    top: -10px !important;
  }
  .top-lg-n20 {
    top: -20px !important;
  }
  .top-lg-n8 {
    top: -8px !important;
  }
  .top-lg-7 {
    top: 7px !important;
  }
  .top-lg-13 {
    top: 13px !important;
  }
  .top-lg-15 {
    top: 15px !important;
  }
  .top-lg-n3 {
    top: -3px !important;
  }
  .top-lg-5 {
    top: 5px !important;
  }
  .top-lg-2 {
    top: 2px !important;
  }
  .bottom-lg-0 {
    bottom: 0 !important;
  }
  .bottom-lg-25 {
    bottom: 25% !important;
  }
  .bottom-lg-50 {
    bottom: 50% !important;
  }
  .bottom-lg-75 {
    bottom: 75% !important;
  }
  .bottom-lg-100 {
    bottom: 100% !important;
  }
  .bottom-lg-10 {
    bottom: 10px !important;
  }
  .bottom-lg-20 {
    bottom: 20px !important;
  }
  .bottom-lg-30 {
    bottom: 30px !important;
  }
  .bottom-lg-n10 {
    bottom: -10px !important;
  }
  .bottom-lg-n20 {
    bottom: -20px !important;
  }
  .bottom-lg-n8 {
    bottom: -8px !important;
  }
  .bottom-lg-7 {
    bottom: 7px !important;
  }
  .bottom-lg-13 {
    bottom: 13px !important;
  }
  .bottom-lg-15 {
    bottom: 15px !important;
  }
  .bottom-lg-n3 {
    bottom: -3px !important;
  }
  .bottom-lg-5 {
    bottom: 5px !important;
  }
  .bottom-lg-2 {
    bottom: 2px !important;
  }
  .end-lg-0 {
    right: 0 !important;
  }
  .end-lg-25 {
    right: 25% !important;
  }
  .end-lg-50 {
    right: 50% !important;
  }
  .end-lg-75 {
    right: 75% !important;
  }
  .end-lg-100 {
    right: 100% !important;
  }
  .end-lg-10 {
    right: 10px !important;
  }
  .end-lg-20 {
    right: 20px !important;
  }
  .end-lg-30 {
    right: 30px !important;
  }
  .end-lg-n10 {
    right: -10px !important;
  }
  .end-lg-n20 {
    right: -20px !important;
  }
  .end-lg-n8 {
    right: -8px !important;
  }
  .end-lg-7 {
    right: 7px !important;
  }
  .end-lg-13 {
    right: 13px !important;
  }
  .end-lg-15 {
    right: 15px !important;
  }
  .end-lg-n3 {
    right: -3px !important;
  }
  .end-lg-5 {
    right: 5px !important;
  }
  .end-lg-2 {
    right: 2px !important;
  }
  .translate-middle-lg {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-lg-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-lg-y {
    transform: translateY(-50%) !important;
  }
  .ls-lg-1 {
    letter-spacing: 1px !important;
  }
  .ls-lg-sm {
    letter-spacing: 1.4px !important;
  }
  .ls-lg-button {
    letter-spacing: 1.5px !important;
  }
  .ls-lg-2 {
    letter-spacing: 2px !important;
  }
  .ls-lg-3 {
    letter-spacing: 3px !important;
  }
  .ls-lg-4 {
    letter-spacing: 4px !important;
  }
  .ls-lg-5 {
    letter-spacing: 5px !important;
  }
  .mx-lg-nsm {
    margin-right: -0.33335rem !important;
    margin-left: -0.33335rem !important;
  }
  .mx-lg-n3px {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .mx-lg-n4px {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }
  .mx-lg-n8px {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }
  .mx-lg-n12px {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }
  .mx-lg-n15px {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-lg-n16px {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }
  .mx-lg-n18px {
    margin-right: -18px !important;
    margin-left: -18px !important;
  }
  .mx-lg-n24px {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }
  .mx-lg-n25px {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .mx-lg-n30px {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .mx-lg-n32px {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }
  .mx-lg-n57px {
    margin-right: -57px !important;
    margin-left: -57px !important;
  }
  .mx-lg-nn15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-lg-nn5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-lg-n3vh {
    margin-right: -3vh !important;
    margin-left: -3vh !important;
  }
  .mx-lg-n10vh {
    margin-right: -10vh !important;
    margin-left: -10vh !important;
  }
  .mx-lg-n1 {
    margin-right: -0.6667rem !important;
    margin-left: -0.6667rem !important;
  }
  .mx-lg-n2 {
    margin-right: -1.3334rem !important;
    margin-left: -1.3334rem !important;
  }
  .mx-lg-n3 {
    margin-right: -2.0001rem !important;
    margin-left: -2.0001rem !important;
  }
  .mx-lg-n4 {
    margin-right: -2.6668rem !important;
    margin-left: -2.6668rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3.3335rem !important;
    margin-left: -3.3335rem !important;
  }
  .mx-lg-n6 {
    margin-right: -4.0002rem !important;
    margin-left: -4.0002rem !important;
  }
  .mx-lg-n7 {
    margin-right: -4.6669rem !important;
    margin-left: -4.6669rem !important;
  }
  .mx-lg-n8 {
    margin-right: -5.3336rem !important;
    margin-left: -5.3336rem !important;
  }
  .mx-lg-n9 {
    margin-right: -6.0003rem !important;
    margin-left: -6.0003rem !important;
  }
  .mx-lg-n10 {
    margin-right: -6.667rem !important;
    margin-left: -6.667rem !important;
  }
  .mx-lg-ngutter {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }
  .mx-lg-nout-container {
    margin-right: -calc(50% - 50vw) !important;
    margin-left: -calc(50% - 50vw) !important;
  }
  .my-lg-nsm {
    margin-top: -0.33335rem !important;
    margin-bottom: -0.33335rem !important;
  }
  .my-lg-n3px {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .my-lg-n4px {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .my-lg-n8px {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .my-lg-n12px {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .my-lg-n15px {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-lg-n16px {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .my-lg-n18px {
    margin-top: -18px !important;
    margin-bottom: -18px !important;
  }
  .my-lg-n24px {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .my-lg-n25px {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .my-lg-n30px {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .my-lg-n32px {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .my-lg-n57px {
    margin-top: -57px !important;
    margin-bottom: -57px !important;
  }
  .my-lg-nn15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-lg-nn5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-lg-n3vh {
    margin-top: -3vh !important;
    margin-bottom: -3vh !important;
  }
  .my-lg-n10vh {
    margin-top: -10vh !important;
    margin-bottom: -10vh !important;
  }
  .my-lg-n1 {
    margin-top: -0.6667rem !important;
    margin-bottom: -0.6667rem !important;
  }
  .my-lg-n2 {
    margin-top: -1.3334rem !important;
    margin-bottom: -1.3334rem !important;
  }
  .my-lg-n3 {
    margin-top: -2.0001rem !important;
    margin-bottom: -2.0001rem !important;
  }
  .my-lg-n4 {
    margin-top: -2.6668rem !important;
    margin-bottom: -2.6668rem !important;
  }
  .my-lg-n5 {
    margin-top: -3.3335rem !important;
    margin-bottom: -3.3335rem !important;
  }
  .my-lg-n6 {
    margin-top: -4.0002rem !important;
    margin-bottom: -4.0002rem !important;
  }
  .my-lg-n7 {
    margin-top: -4.6669rem !important;
    margin-bottom: -4.6669rem !important;
  }
  .my-lg-n8 {
    margin-top: -5.3336rem !important;
    margin-bottom: -5.3336rem !important;
  }
  .my-lg-n9 {
    margin-top: -6.0003rem !important;
    margin-bottom: -6.0003rem !important;
  }
  .my-lg-n10 {
    margin-top: -6.667rem !important;
    margin-bottom: -6.667rem !important;
  }
  .my-lg-ngutter {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .my-lg-nout-container {
    margin-top: -calc(50% - 50vw) !important;
    margin-bottom: -calc(50% - 50vw) !important;
  }
  .mt-lg-nsm {
    margin-top: -0.33335rem !important;
  }
  .mt-lg-n3px {
    margin-top: -3px !important;
  }
  .mt-lg-n4px {
    margin-top: -4px !important;
  }
  .mt-lg-n8px {
    margin-top: -8px !important;
  }
  .mt-lg-n12px {
    margin-top: -12px !important;
  }
  .mt-lg-n15px {
    margin-top: -15px !important;
  }
  .mt-lg-n16px {
    margin-top: -16px !important;
  }
  .mt-lg-n18px {
    margin-top: -18px !important;
  }
  .mt-lg-n24px {
    margin-top: -24px !important;
  }
  .mt-lg-n25px {
    margin-top: -25px !important;
  }
  .mt-lg-n30px {
    margin-top: -30px !important;
  }
  .mt-lg-n32px {
    margin-top: -32px !important;
  }
  .mt-lg-n57px {
    margin-top: -57px !important;
  }
  .mt-lg-nn15 {
    margin-top: 15px !important;
  }
  .mt-lg-nn5 {
    margin-top: 5px !important;
  }
  .mt-lg-n3vh {
    margin-top: -3vh !important;
  }
  .mt-lg-n10vh {
    margin-top: -10vh !important;
  }
  .mt-lg-n1 {
    margin-top: -0.6667rem !important;
  }
  .mt-lg-n2 {
    margin-top: -1.3334rem !important;
  }
  .mt-lg-n3 {
    margin-top: -2.0001rem !important;
  }
  .mt-lg-n4 {
    margin-top: -2.6668rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3.3335rem !important;
  }
  .mt-lg-n6 {
    margin-top: -4.0002rem !important;
  }
  .mt-lg-n7 {
    margin-top: -4.6669rem !important;
  }
  .mt-lg-n8 {
    margin-top: -5.3336rem !important;
  }
  .mt-lg-n9 {
    margin-top: -6.0003rem !important;
  }
  .mt-lg-n10 {
    margin-top: -6.667rem !important;
  }
  .mt-lg-ngutter {
    margin-top: -24px !important;
  }
  .mt-lg-nout-container {
    margin-top: -calc(50% - 50vw) !important;
  }
  .me-lg-nsm {
    margin-right: -0.33335rem !important;
  }
  .me-lg-n3px {
    margin-right: -3px !important;
  }
  .me-lg-n4px {
    margin-right: -4px !important;
  }
  .me-lg-n8px {
    margin-right: -8px !important;
  }
  .me-lg-n12px {
    margin-right: -12px !important;
  }
  .me-lg-n15px {
    margin-right: -15px !important;
  }
  .me-lg-n16px {
    margin-right: -16px !important;
  }
  .me-lg-n18px {
    margin-right: -18px !important;
  }
  .me-lg-n24px {
    margin-right: -24px !important;
  }
  .me-lg-n25px {
    margin-right: -25px !important;
  }
  .me-lg-n30px {
    margin-right: -30px !important;
  }
  .me-lg-n32px {
    margin-right: -32px !important;
  }
  .me-lg-n57px {
    margin-right: -57px !important;
  }
  .me-lg-nn15 {
    margin-right: 15px !important;
  }
  .me-lg-nn5 {
    margin-right: 5px !important;
  }
  .me-lg-n3vh {
    margin-right: -3vh !important;
  }
  .me-lg-n10vh {
    margin-right: -10vh !important;
  }
  .me-lg-n1 {
    margin-right: -0.6667rem !important;
  }
  .me-lg-n2 {
    margin-right: -1.3334rem !important;
  }
  .me-lg-n3 {
    margin-right: -2.0001rem !important;
  }
  .me-lg-n4 {
    margin-right: -2.6668rem !important;
  }
  .me-lg-n5 {
    margin-right: -3.3335rem !important;
  }
  .me-lg-n6 {
    margin-right: -4.0002rem !important;
  }
  .me-lg-n7 {
    margin-right: -4.6669rem !important;
  }
  .me-lg-n8 {
    margin-right: -5.3336rem !important;
  }
  .me-lg-n9 {
    margin-right: -6.0003rem !important;
  }
  .me-lg-n10 {
    margin-right: -6.667rem !important;
  }
  .me-lg-ngutter {
    margin-right: -24px !important;
  }
  .me-lg-nout-container {
    margin-right: -calc(50% - 50vw) !important;
  }
  .mb-lg-nsm {
    margin-bottom: -0.33335rem !important;
  }
  .mb-lg-n3px {
    margin-bottom: -3px !important;
  }
  .mb-lg-n4px {
    margin-bottom: -4px !important;
  }
  .mb-lg-n8px {
    margin-bottom: -8px !important;
  }
  .mb-lg-n12px {
    margin-bottom: -12px !important;
  }
  .mb-lg-n15px {
    margin-bottom: -15px !important;
  }
  .mb-lg-n16px {
    margin-bottom: -16px !important;
  }
  .mb-lg-n18px {
    margin-bottom: -18px !important;
  }
  .mb-lg-n24px {
    margin-bottom: -24px !important;
  }
  .mb-lg-n25px {
    margin-bottom: -25px !important;
  }
  .mb-lg-n30px {
    margin-bottom: -30px !important;
  }
  .mb-lg-n32px {
    margin-bottom: -32px !important;
  }
  .mb-lg-n57px {
    margin-bottom: -57px !important;
  }
  .mb-lg-nn15 {
    margin-bottom: 15px !important;
  }
  .mb-lg-nn5 {
    margin-bottom: 5px !important;
  }
  .mb-lg-n3vh {
    margin-bottom: -3vh !important;
  }
  .mb-lg-n10vh {
    margin-bottom: -10vh !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.6667rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -1.3334rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -2.0001rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -2.6668rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3.3335rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -4.0002rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -4.6669rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -5.3336rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -6.0003rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -6.667rem !important;
  }
  .mb-lg-ngutter {
    margin-bottom: -24px !important;
  }
  .mb-lg-nout-container {
    margin-bottom: -calc(50% - 50vw) !important;
  }
  .ms-lg-nsm {
    margin-left: -0.33335rem !important;
  }
  .ms-lg-n3px {
    margin-left: -3px !important;
  }
  .ms-lg-n4px {
    margin-left: -4px !important;
  }
  .ms-lg-n8px {
    margin-left: -8px !important;
  }
  .ms-lg-n12px {
    margin-left: -12px !important;
  }
  .ms-lg-n15px {
    margin-left: -15px !important;
  }
  .ms-lg-n16px {
    margin-left: -16px !important;
  }
  .ms-lg-n18px {
    margin-left: -18px !important;
  }
  .ms-lg-n24px {
    margin-left: -24px !important;
  }
  .ms-lg-n25px {
    margin-left: -25px !important;
  }
  .ms-lg-n30px {
    margin-left: -30px !important;
  }
  .ms-lg-n32px {
    margin-left: -32px !important;
  }
  .ms-lg-n57px {
    margin-left: -57px !important;
  }
  .ms-lg-nn15 {
    margin-left: 15px !important;
  }
  .ms-lg-nn5 {
    margin-left: 5px !important;
  }
  .ms-lg-n3vh {
    margin-left: -3vh !important;
  }
  .ms-lg-n10vh {
    margin-left: -10vh !important;
  }
  .ms-lg-n1 {
    margin-left: -0.6667rem !important;
  }
  .ms-lg-n2 {
    margin-left: -1.3334rem !important;
  }
  .ms-lg-n3 {
    margin-left: -2.0001rem !important;
  }
  .ms-lg-n4 {
    margin-left: -2.6668rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3.3335rem !important;
  }
  .ms-lg-n6 {
    margin-left: -4.0002rem !important;
  }
  .ms-lg-n7 {
    margin-left: -4.6669rem !important;
  }
  .ms-lg-n8 {
    margin-left: -5.3336rem !important;
  }
  .ms-lg-n9 {
    margin-left: -6.0003rem !important;
  }
  .ms-lg-n10 {
    margin-left: -6.667rem !important;
  }
  .ms-lg-ngutter {
    margin-left: -24px !important;
  }
  .ms-lg-nout-container {
    margin-left: -calc(50% - 50vw) !important;
  }
  .list-style-lg-none {
    list-style: none !important;
  }
  .list-style-lg-circle {
    list-style: circle !important;
  }
  .list-style-lg-disc {
    list-style: disc !important;
  }
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .font-xl-sans-serif {
    font-family: "arial-regular", sans-serif !important;
  }
  .font-xl-sans-serif-italic {
    font-family: "arial-italic", sans-serif !important;
  }
  .font-xl-sans-serif-bolditalic {
    font-family: "arial-bolditalic", sans-serif !important;
  }
  .font-xl-sans-serif-bold {
    font-family: "arial-bold", sans-serif !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .bg-xl-primary {
    --bs-bg-opacity: 1;
    background-color: #0a3255 !important;
  }
  .bg-xl-primary-light {
    --bs-bg-opacity: 1;
    background-color: rgba(10, 50, 85, 0.5) !important;
  }
  .bg-xl-secondary {
    --bs-bg-opacity: 1;
    background-color: #da262e !important;
  }
  .bg-xl-secondary-light {
    --bs-bg-opacity: 1;
    background-color: rgba(218, 38, 46, 0.5) !important;
  }
  .bg-xl-westar {
    --bs-bg-opacity: 1;
    background-color: #ddd8ce !important;
  }
  .bg-xl-swirl {
    --bs-bg-opacity: 1;
    background-color: #d7cdca !important;
  }
  .bg-xl-light {
    --bs-bg-opacity: 1;
    background-color: #fbfbfb !important;
  }
  .bg-xl-success {
    --bs-bg-opacity: 1;
    background-color: #1acf82 !important;
  }
  .bg-xl-gray-100 {
    --bs-bg-opacity: 1;
    background-color: #9a9ea6 !important;
  }
  .bg-xl-gray-200 {
    --bs-bg-opacity: 1;
    background-color: #f6f5f5 !important;
  }
  .bg-xl-gray-300 {
    --bs-bg-opacity: 1;
    background-color: #d0cfcd !important;
  }
  .bg-xl-gray-400 {
    --bs-bg-opacity: 1;
    background-color: #efefef !important;
  }
  .bg-xl-gray-500 {
    --bs-bg-opacity: 1;
    background-color: #2a2644 !important;
  }
  .bg-xl-gray-600 {
    --bs-bg-opacity: 1;
    background-color: #e3e3e3 !important;
  }
  .bg-xl-gray-800 {
    --bs-bg-opacity: 1;
    background-color: #e0e0e0 !important;
  }
  .bg-xl-gray-900 {
    --bs-bg-opacity: 1;
    background-color: #c4c4c4 !important;
  }
  .bg-xl-body {
    --bs-bg-opacity: 1;
    background-color: #0a3255 !important;
  }
  .bg-xl-info {
    --bs-bg-opacity: 1;
    background-color: #60b8d0 !important;
  }
  .bg-xl-info-100 {
    --bs-bg-opacity: 1;
    background-color: #0083c1 !important;
  }
  .bg-xl-info-200 {
    --bs-bg-opacity: 1;
    background-color: #52b1c5 !important;
  }
  .bg-xl-info-300 {
    --bs-bg-opacity: 1;
    background-color: #97d1dc !important;
  }
  .bg-xl-info-400 {
    --bs-bg-opacity: 1;
    background-color: #40a7be !important;
  }
  .bg-xl-warning {
    --bs-bg-opacity: 1;
    background-color: #d3ad57 !important;
  }
  .bg-xl-danger {
    --bs-bg-opacity: 1;
    background-color: #d7282f !important;
  }
  .bg-xl-dark {
    --bs-bg-opacity: 1;
    background-color: #e9e9e9 !important;
  }
  .bg-xl-black {
    --bs-bg-opacity: 1;
    background-color: #000 !important;
  }
  .bg-xl-red {
    --bs-bg-opacity: 1;
    background-color: #d7282f !important;
  }
  .bg-xl-white {
    --bs-bg-opacity: 1;
    background-color: #ffffff !important;
  }
  .bg-xl-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .text-xl-primary {
    color: #0a3255 !important;
  }
  .text-xl-primary-light {
    color: rgba(10, 50, 85, 0.5) !important;
  }
  .text-xl-secondary {
    color: #da262e !important;
  }
  .text-xl-secondary-light {
    color: rgba(218, 38, 46, 0.5) !important;
  }
  .text-xl-westar {
    color: #ddd8ce !important;
  }
  .text-xl-swirl {
    color: #d7cdca !important;
  }
  .text-xl-light {
    color: #fbfbfb !important;
  }
  .text-xl-success {
    color: #1acf82 !important;
  }
  .text-xl-gray-100 {
    color: #9a9ea6 !important;
  }
  .text-xl-gray-200 {
    color: #f6f5f5 !important;
  }
  .text-xl-gray-300 {
    color: #d0cfcd !important;
  }
  .text-xl-gray-400 {
    color: #efefef !important;
  }
  .text-xl-gray-500 {
    color: #2a2644 !important;
  }
  .text-xl-gray-600 {
    color: #e3e3e3 !important;
  }
  .text-xl-gray-800 {
    color: #e0e0e0 !important;
  }
  .text-xl-gray-900 {
    color: #c4c4c4 !important;
  }
  .text-xl-body {
    color: #0a3255 !important;
  }
  .text-xl-info {
    color: #60b8d0 !important;
  }
  .text-xl-info-100 {
    color: #0083c1 !important;
  }
  .text-xl-info-200 {
    color: #52b1c5 !important;
  }
  .text-xl-info-300 {
    color: #97d1dc !important;
  }
  .text-xl-info-400 {
    color: #40a7be !important;
  }
  .text-xl-warning {
    color: #d3ad57 !important;
  }
  .text-xl-danger {
    color: #d7282f !important;
  }
  .text-xl-dark {
    color: #e9e9e9 !important;
  }
  .text-xl-black {
    color: #000 !important;
  }
  .text-xl-red {
    color: #d7282f !important;
  }
  .text-xl-white {
    color: #ffffff !important;
  }
  .text-xl-transparent {
    color: transparent !important;
  }
  .m-xl-sm {
    margin: 0.33335rem !important;
  }
  .m-xl-3px {
    margin: 3px !important;
  }
  .m-xl-4px {
    margin: 4px !important;
  }
  .m-xl-8px {
    margin: 8px !important;
  }
  .m-xl-12px {
    margin: 12px !important;
  }
  .m-xl-15px {
    margin: 15px !important;
  }
  .m-xl-16px {
    margin: 16px !important;
  }
  .m-xl-18px {
    margin: 18px !important;
  }
  .m-xl-24px {
    margin: 24px !important;
  }
  .m-xl-25px {
    margin: 25px !important;
  }
  .m-xl-30px {
    margin: 30px !important;
  }
  .m-xl-32px {
    margin: 32px !important;
  }
  .m-xl-57px {
    margin: 57px !important;
  }
  .m-xl-n15 {
    margin: -15px !important;
  }
  .m-xl-n5 {
    margin: -5px !important;
  }
  .m-xl-3vh {
    margin: 3vh !important;
  }
  .m-xl-10vh {
    margin: 10vh !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.6667rem !important;
  }
  .m-xl-2 {
    margin: 1.3334rem !important;
  }
  .m-xl-3 {
    margin: 2.0001rem !important;
  }
  .m-xl-4 {
    margin: 2.6668rem !important;
  }
  .m-xl-5 {
    margin: 3.3335rem !important;
  }
  .m-xl-6 {
    margin: 4.0002rem !important;
  }
  .m-xl-7 {
    margin: 4.6669rem !important;
  }
  .m-xl-8 {
    margin: 5.3336rem !important;
  }
  .m-xl-9 {
    margin: 6.0003rem !important;
  }
  .m-xl-10 {
    margin: 6.667rem !important;
  }
  .m-xl-gutter {
    margin: 24px !important;
  }
  .m-xl-out-container {
    margin: calc(50% - 50vw) !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-sm {
    margin-right: 0.33335rem !important;
    margin-left: 0.33335rem !important;
  }
  .mx-xl-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-xl-4px {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .mx-xl-8px {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .mx-xl-12px {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .mx-xl-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-xl-16px {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .mx-xl-18px {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
  .mx-xl-24px {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  .mx-xl-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-xl-30px {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-xl-32px {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
  .mx-xl-57px {
    margin-right: 57px !important;
    margin-left: 57px !important;
  }
  .mx-xl-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-xl-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-xl-3vh {
    margin-right: 3vh !important;
    margin-left: 3vh !important;
  }
  .mx-xl-10vh {
    margin-right: 10vh !important;
    margin-left: 10vh !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.6667rem !important;
    margin-left: 0.6667rem !important;
  }
  .mx-xl-2 {
    margin-right: 1.3334rem !important;
    margin-left: 1.3334rem !important;
  }
  .mx-xl-3 {
    margin-right: 2.0001rem !important;
    margin-left: 2.0001rem !important;
  }
  .mx-xl-4 {
    margin-right: 2.6668rem !important;
    margin-left: 2.6668rem !important;
  }
  .mx-xl-5 {
    margin-right: 3.3335rem !important;
    margin-left: 3.3335rem !important;
  }
  .mx-xl-6 {
    margin-right: 4.0002rem !important;
    margin-left: 4.0002rem !important;
  }
  .mx-xl-7 {
    margin-right: 4.6669rem !important;
    margin-left: 4.6669rem !important;
  }
  .mx-xl-8 {
    margin-right: 5.3336rem !important;
    margin-left: 5.3336rem !important;
  }
  .mx-xl-9 {
    margin-right: 6.0003rem !important;
    margin-left: 6.0003rem !important;
  }
  .mx-xl-10 {
    margin-right: 6.667rem !important;
    margin-left: 6.667rem !important;
  }
  .mx-xl-gutter {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  .mx-xl-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-sm {
    margin-top: 0.33335rem !important;
    margin-bottom: 0.33335rem !important;
  }
  .my-xl-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-xl-4px {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .my-xl-8px {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .my-xl-12px {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .my-xl-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-xl-16px {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .my-xl-18px {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .my-xl-24px {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .my-xl-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-xl-30px {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-xl-32px {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .my-xl-57px {
    margin-top: 57px !important;
    margin-bottom: 57px !important;
  }
  .my-xl-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-xl-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-xl-3vh {
    margin-top: 3vh !important;
    margin-bottom: 3vh !important;
  }
  .my-xl-10vh {
    margin-top: 10vh !important;
    margin-bottom: 10vh !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.6667rem !important;
    margin-bottom: 0.6667rem !important;
  }
  .my-xl-2 {
    margin-top: 1.3334rem !important;
    margin-bottom: 1.3334rem !important;
  }
  .my-xl-3 {
    margin-top: 2.0001rem !important;
    margin-bottom: 2.0001rem !important;
  }
  .my-xl-4 {
    margin-top: 2.6668rem !important;
    margin-bottom: 2.6668rem !important;
  }
  .my-xl-5 {
    margin-top: 3.3335rem !important;
    margin-bottom: 3.3335rem !important;
  }
  .my-xl-6 {
    margin-top: 4.0002rem !important;
    margin-bottom: 4.0002rem !important;
  }
  .my-xl-7 {
    margin-top: 4.6669rem !important;
    margin-bottom: 4.6669rem !important;
  }
  .my-xl-8 {
    margin-top: 5.3336rem !important;
    margin-bottom: 5.3336rem !important;
  }
  .my-xl-9 {
    margin-top: 6.0003rem !important;
    margin-bottom: 6.0003rem !important;
  }
  .my-xl-10 {
    margin-top: 6.667rem !important;
    margin-bottom: 6.667rem !important;
  }
  .my-xl-gutter {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .my-xl-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-sm {
    margin-top: 0.33335rem !important;
  }
  .mt-xl-3px {
    margin-top: 3px !important;
  }
  .mt-xl-4px {
    margin-top: 4px !important;
  }
  .mt-xl-8px {
    margin-top: 8px !important;
  }
  .mt-xl-12px {
    margin-top: 12px !important;
  }
  .mt-xl-15px {
    margin-top: 15px !important;
  }
  .mt-xl-16px {
    margin-top: 16px !important;
  }
  .mt-xl-18px {
    margin-top: 18px !important;
  }
  .mt-xl-24px {
    margin-top: 24px !important;
  }
  .mt-xl-25px {
    margin-top: 25px !important;
  }
  .mt-xl-30px {
    margin-top: 30px !important;
  }
  .mt-xl-32px {
    margin-top: 32px !important;
  }
  .mt-xl-57px {
    margin-top: 57px !important;
  }
  .mt-xl-n15 {
    margin-top: -15px !important;
  }
  .mt-xl-n5 {
    margin-top: -5px !important;
  }
  .mt-xl-3vh {
    margin-top: 3vh !important;
  }
  .mt-xl-10vh {
    margin-top: 10vh !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.6667rem !important;
  }
  .mt-xl-2 {
    margin-top: 1.3334rem !important;
  }
  .mt-xl-3 {
    margin-top: 2.0001rem !important;
  }
  .mt-xl-4 {
    margin-top: 2.6668rem !important;
  }
  .mt-xl-5 {
    margin-top: 3.3335rem !important;
  }
  .mt-xl-6 {
    margin-top: 4.0002rem !important;
  }
  .mt-xl-7 {
    margin-top: 4.6669rem !important;
  }
  .mt-xl-8 {
    margin-top: 5.3336rem !important;
  }
  .mt-xl-9 {
    margin-top: 6.0003rem !important;
  }
  .mt-xl-10 {
    margin-top: 6.667rem !important;
  }
  .mt-xl-gutter {
    margin-top: 24px !important;
  }
  .mt-xl-out-container {
    margin-top: calc(50% - 50vw) !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-sm {
    margin-right: 0.33335rem !important;
  }
  .me-xl-3px {
    margin-right: 3px !important;
  }
  .me-xl-4px {
    margin-right: 4px !important;
  }
  .me-xl-8px {
    margin-right: 8px !important;
  }
  .me-xl-12px {
    margin-right: 12px !important;
  }
  .me-xl-15px {
    margin-right: 15px !important;
  }
  .me-xl-16px {
    margin-right: 16px !important;
  }
  .me-xl-18px {
    margin-right: 18px !important;
  }
  .me-xl-24px {
    margin-right: 24px !important;
  }
  .me-xl-25px {
    margin-right: 25px !important;
  }
  .me-xl-30px {
    margin-right: 30px !important;
  }
  .me-xl-32px {
    margin-right: 32px !important;
  }
  .me-xl-57px {
    margin-right: 57px !important;
  }
  .me-xl-n15 {
    margin-right: -15px !important;
  }
  .me-xl-n5 {
    margin-right: -5px !important;
  }
  .me-xl-3vh {
    margin-right: 3vh !important;
  }
  .me-xl-10vh {
    margin-right: 10vh !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.6667rem !important;
  }
  .me-xl-2 {
    margin-right: 1.3334rem !important;
  }
  .me-xl-3 {
    margin-right: 2.0001rem !important;
  }
  .me-xl-4 {
    margin-right: 2.6668rem !important;
  }
  .me-xl-5 {
    margin-right: 3.3335rem !important;
  }
  .me-xl-6 {
    margin-right: 4.0002rem !important;
  }
  .me-xl-7 {
    margin-right: 4.6669rem !important;
  }
  .me-xl-8 {
    margin-right: 5.3336rem !important;
  }
  .me-xl-9 {
    margin-right: 6.0003rem !important;
  }
  .me-xl-10 {
    margin-right: 6.667rem !important;
  }
  .me-xl-gutter {
    margin-right: 24px !important;
  }
  .me-xl-out-container {
    margin-right: calc(50% - 50vw) !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-sm {
    margin-bottom: 0.33335rem !important;
  }
  .mb-xl-3px {
    margin-bottom: 3px !important;
  }
  .mb-xl-4px {
    margin-bottom: 4px !important;
  }
  .mb-xl-8px {
    margin-bottom: 8px !important;
  }
  .mb-xl-12px {
    margin-bottom: 12px !important;
  }
  .mb-xl-15px {
    margin-bottom: 15px !important;
  }
  .mb-xl-16px {
    margin-bottom: 16px !important;
  }
  .mb-xl-18px {
    margin-bottom: 18px !important;
  }
  .mb-xl-24px {
    margin-bottom: 24px !important;
  }
  .mb-xl-25px {
    margin-bottom: 25px !important;
  }
  .mb-xl-30px {
    margin-bottom: 30px !important;
  }
  .mb-xl-32px {
    margin-bottom: 32px !important;
  }
  .mb-xl-57px {
    margin-bottom: 57px !important;
  }
  .mb-xl-n15 {
    margin-bottom: -15px !important;
  }
  .mb-xl-n5 {
    margin-bottom: -5px !important;
  }
  .mb-xl-3vh {
    margin-bottom: 3vh !important;
  }
  .mb-xl-10vh {
    margin-bottom: 10vh !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.6667rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 1.3334rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 2.0001rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 2.6668rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3.3335rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 4.0002rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 4.6669rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 5.3336rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 6.0003rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 6.667rem !important;
  }
  .mb-xl-gutter {
    margin-bottom: 24px !important;
  }
  .mb-xl-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-sm {
    margin-left: 0.33335rem !important;
  }
  .ms-xl-3px {
    margin-left: 3px !important;
  }
  .ms-xl-4px {
    margin-left: 4px !important;
  }
  .ms-xl-8px {
    margin-left: 8px !important;
  }
  .ms-xl-12px {
    margin-left: 12px !important;
  }
  .ms-xl-15px {
    margin-left: 15px !important;
  }
  .ms-xl-16px {
    margin-left: 16px !important;
  }
  .ms-xl-18px {
    margin-left: 18px !important;
  }
  .ms-xl-24px {
    margin-left: 24px !important;
  }
  .ms-xl-25px {
    margin-left: 25px !important;
  }
  .ms-xl-30px {
    margin-left: 30px !important;
  }
  .ms-xl-32px {
    margin-left: 32px !important;
  }
  .ms-xl-57px {
    margin-left: 57px !important;
  }
  .ms-xl-n15 {
    margin-left: -15px !important;
  }
  .ms-xl-n5 {
    margin-left: -5px !important;
  }
  .ms-xl-3vh {
    margin-left: 3vh !important;
  }
  .ms-xl-10vh {
    margin-left: 10vh !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.6667rem !important;
  }
  .ms-xl-2 {
    margin-left: 1.3334rem !important;
  }
  .ms-xl-3 {
    margin-left: 2.0001rem !important;
  }
  .ms-xl-4 {
    margin-left: 2.6668rem !important;
  }
  .ms-xl-5 {
    margin-left: 3.3335rem !important;
  }
  .ms-xl-6 {
    margin-left: 4.0002rem !important;
  }
  .ms-xl-7 {
    margin-left: 4.6669rem !important;
  }
  .ms-xl-8 {
    margin-left: 5.3336rem !important;
  }
  .ms-xl-9 {
    margin-left: 6.0003rem !important;
  }
  .ms-xl-10 {
    margin-left: 6.667rem !important;
  }
  .ms-xl-gutter {
    margin-left: 24px !important;
  }
  .ms-xl-out-container {
    margin-left: calc(50% - 50vw) !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-sm {
    padding: 0.33335rem !important;
  }
  .p-xl-3px {
    padding: 3px !important;
  }
  .p-xl-4px {
    padding: 4px !important;
  }
  .p-xl-8px {
    padding: 8px !important;
  }
  .p-xl-12px {
    padding: 12px !important;
  }
  .p-xl-15px {
    padding: 15px !important;
  }
  .p-xl-16px {
    padding: 16px !important;
  }
  .p-xl-18px {
    padding: 18px !important;
  }
  .p-xl-24px {
    padding: 24px !important;
  }
  .p-xl-25px {
    padding: 25px !important;
  }
  .p-xl-30px {
    padding: 30px !important;
  }
  .p-xl-32px {
    padding: 32px !important;
  }
  .p-xl-57px {
    padding: 57px !important;
  }
  .p-xl-n15 {
    padding: -15px !important;
  }
  .p-xl-n5 {
    padding: -5px !important;
  }
  .p-xl-3vh {
    padding: 3vh !important;
  }
  .p-xl-10vh {
    padding: 10vh !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.6667rem !important;
  }
  .p-xl-2 {
    padding: 1.3334rem !important;
  }
  .p-xl-3 {
    padding: 2.0001rem !important;
  }
  .p-xl-4 {
    padding: 2.6668rem !important;
  }
  .p-xl-5 {
    padding: 3.3335rem !important;
  }
  .p-xl-6 {
    padding: 4.0002rem !important;
  }
  .p-xl-7 {
    padding: 4.6669rem !important;
  }
  .p-xl-8 {
    padding: 5.3336rem !important;
  }
  .p-xl-9 {
    padding: 6.0003rem !important;
  }
  .p-xl-10 {
    padding: 6.667rem !important;
  }
  .p-xl-gutter {
    padding: 24px !important;
  }
  .p-xl-out-container {
    padding: calc(50% - 50vw) !important;
  }
  .px-xl-sm {
    padding-right: 0.33335rem !important;
    padding-left: 0.33335rem !important;
  }
  .px-xl-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-xl-4px {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .px-xl-8px {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .px-xl-12px {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .px-xl-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-xl-16px {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .px-xl-18px {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
  .px-xl-24px {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .px-xl-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-xl-30px {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-xl-32px {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
  .px-xl-57px {
    padding-right: 57px !important;
    padding-left: 57px !important;
  }
  .px-xl-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }
  .px-xl-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }
  .px-xl-3vh {
    padding-right: 3vh !important;
    padding-left: 3vh !important;
  }
  .px-xl-10vh {
    padding-right: 10vh !important;
    padding-left: 10vh !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.6667rem !important;
    padding-left: 0.6667rem !important;
  }
  .px-xl-2 {
    padding-right: 1.3334rem !important;
    padding-left: 1.3334rem !important;
  }
  .px-xl-3 {
    padding-right: 2.0001rem !important;
    padding-left: 2.0001rem !important;
  }
  .px-xl-4 {
    padding-right: 2.6668rem !important;
    padding-left: 2.6668rem !important;
  }
  .px-xl-5 {
    padding-right: 3.3335rem !important;
    padding-left: 3.3335rem !important;
  }
  .px-xl-6 {
    padding-right: 4.0002rem !important;
    padding-left: 4.0002rem !important;
  }
  .px-xl-7 {
    padding-right: 4.6669rem !important;
    padding-left: 4.6669rem !important;
  }
  .px-xl-8 {
    padding-right: 5.3336rem !important;
    padding-left: 5.3336rem !important;
  }
  .px-xl-9 {
    padding-right: 6.0003rem !important;
    padding-left: 6.0003rem !important;
  }
  .px-xl-10 {
    padding-right: 6.667rem !important;
    padding-left: 6.667rem !important;
  }
  .px-xl-gutter {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .px-xl-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }
  .py-xl-sm {
    padding-top: 0.33335rem !important;
    padding-bottom: 0.33335rem !important;
  }
  .py-xl-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-xl-4px {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .py-xl-8px {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .py-xl-12px {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .py-xl-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-xl-16px {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .py-xl-18px {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .py-xl-24px {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-xl-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-xl-30px {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-xl-32px {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .py-xl-57px {
    padding-top: 57px !important;
    padding-bottom: 57px !important;
  }
  .py-xl-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }
  .py-xl-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }
  .py-xl-3vh {
    padding-top: 3vh !important;
    padding-bottom: 3vh !important;
  }
  .py-xl-10vh {
    padding-top: 10vh !important;
    padding-bottom: 10vh !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.6667rem !important;
    padding-bottom: 0.6667rem !important;
  }
  .py-xl-2 {
    padding-top: 1.3334rem !important;
    padding-bottom: 1.3334rem !important;
  }
  .py-xl-3 {
    padding-top: 2.0001rem !important;
    padding-bottom: 2.0001rem !important;
  }
  .py-xl-4 {
    padding-top: 2.6668rem !important;
    padding-bottom: 2.6668rem !important;
  }
  .py-xl-5 {
    padding-top: 3.3335rem !important;
    padding-bottom: 3.3335rem !important;
  }
  .py-xl-6 {
    padding-top: 4.0002rem !important;
    padding-bottom: 4.0002rem !important;
  }
  .py-xl-7 {
    padding-top: 4.6669rem !important;
    padding-bottom: 4.6669rem !important;
  }
  .py-xl-8 {
    padding-top: 5.3336rem !important;
    padding-bottom: 5.3336rem !important;
  }
  .py-xl-9 {
    padding-top: 6.0003rem !important;
    padding-bottom: 6.0003rem !important;
  }
  .py-xl-10 {
    padding-top: 6.667rem !important;
    padding-bottom: 6.667rem !important;
  }
  .py-xl-gutter {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-xl-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }
  .pt-xl-sm {
    padding-top: 0.33335rem !important;
  }
  .pt-xl-3px {
    padding-top: 3px !important;
  }
  .pt-xl-4px {
    padding-top: 4px !important;
  }
  .pt-xl-8px {
    padding-top: 8px !important;
  }
  .pt-xl-12px {
    padding-top: 12px !important;
  }
  .pt-xl-15px {
    padding-top: 15px !important;
  }
  .pt-xl-16px {
    padding-top: 16px !important;
  }
  .pt-xl-18px {
    padding-top: 18px !important;
  }
  .pt-xl-24px {
    padding-top: 24px !important;
  }
  .pt-xl-25px {
    padding-top: 25px !important;
  }
  .pt-xl-30px {
    padding-top: 30px !important;
  }
  .pt-xl-32px {
    padding-top: 32px !important;
  }
  .pt-xl-57px {
    padding-top: 57px !important;
  }
  .pt-xl-n15 {
    padding-top: -15px !important;
  }
  .pt-xl-n5 {
    padding-top: -5px !important;
  }
  .pt-xl-3vh {
    padding-top: 3vh !important;
  }
  .pt-xl-10vh {
    padding-top: 10vh !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.6667rem !important;
  }
  .pt-xl-2 {
    padding-top: 1.3334rem !important;
  }
  .pt-xl-3 {
    padding-top: 2.0001rem !important;
  }
  .pt-xl-4 {
    padding-top: 2.6668rem !important;
  }
  .pt-xl-5 {
    padding-top: 3.3335rem !important;
  }
  .pt-xl-6 {
    padding-top: 4.0002rem !important;
  }
  .pt-xl-7 {
    padding-top: 4.6669rem !important;
  }
  .pt-xl-8 {
    padding-top: 5.3336rem !important;
  }
  .pt-xl-9 {
    padding-top: 6.0003rem !important;
  }
  .pt-xl-10 {
    padding-top: 6.667rem !important;
  }
  .pt-xl-gutter {
    padding-top: 24px !important;
  }
  .pt-xl-out-container {
    padding-top: calc(50% - 50vw) !important;
  }
  .pe-xl-sm {
    padding-right: 0.33335rem !important;
  }
  .pe-xl-3px {
    padding-right: 3px !important;
  }
  .pe-xl-4px {
    padding-right: 4px !important;
  }
  .pe-xl-8px {
    padding-right: 8px !important;
  }
  .pe-xl-12px {
    padding-right: 12px !important;
  }
  .pe-xl-15px {
    padding-right: 15px !important;
  }
  .pe-xl-16px {
    padding-right: 16px !important;
  }
  .pe-xl-18px {
    padding-right: 18px !important;
  }
  .pe-xl-24px {
    padding-right: 24px !important;
  }
  .pe-xl-25px {
    padding-right: 25px !important;
  }
  .pe-xl-30px {
    padding-right: 30px !important;
  }
  .pe-xl-32px {
    padding-right: 32px !important;
  }
  .pe-xl-57px {
    padding-right: 57px !important;
  }
  .pe-xl-n15 {
    padding-right: -15px !important;
  }
  .pe-xl-n5 {
    padding-right: -5px !important;
  }
  .pe-xl-3vh {
    padding-right: 3vh !important;
  }
  .pe-xl-10vh {
    padding-right: 10vh !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.6667rem !important;
  }
  .pe-xl-2 {
    padding-right: 1.3334rem !important;
  }
  .pe-xl-3 {
    padding-right: 2.0001rem !important;
  }
  .pe-xl-4 {
    padding-right: 2.6668rem !important;
  }
  .pe-xl-5 {
    padding-right: 3.3335rem !important;
  }
  .pe-xl-6 {
    padding-right: 4.0002rem !important;
  }
  .pe-xl-7 {
    padding-right: 4.6669rem !important;
  }
  .pe-xl-8 {
    padding-right: 5.3336rem !important;
  }
  .pe-xl-9 {
    padding-right: 6.0003rem !important;
  }
  .pe-xl-10 {
    padding-right: 6.667rem !important;
  }
  .pe-xl-gutter {
    padding-right: 24px !important;
  }
  .pe-xl-out-container {
    padding-right: calc(50% - 50vw) !important;
  }
  .pb-xl-sm {
    padding-bottom: 0.33335rem !important;
  }
  .pb-xl-3px {
    padding-bottom: 3px !important;
  }
  .pb-xl-4px {
    padding-bottom: 4px !important;
  }
  .pb-xl-8px {
    padding-bottom: 8px !important;
  }
  .pb-xl-12px {
    padding-bottom: 12px !important;
  }
  .pb-xl-15px {
    padding-bottom: 15px !important;
  }
  .pb-xl-16px {
    padding-bottom: 16px !important;
  }
  .pb-xl-18px {
    padding-bottom: 18px !important;
  }
  .pb-xl-24px {
    padding-bottom: 24px !important;
  }
  .pb-xl-25px {
    padding-bottom: 25px !important;
  }
  .pb-xl-30px {
    padding-bottom: 30px !important;
  }
  .pb-xl-32px {
    padding-bottom: 32px !important;
  }
  .pb-xl-57px {
    padding-bottom: 57px !important;
  }
  .pb-xl-n15 {
    padding-bottom: -15px !important;
  }
  .pb-xl-n5 {
    padding-bottom: -5px !important;
  }
  .pb-xl-3vh {
    padding-bottom: 3vh !important;
  }
  .pb-xl-10vh {
    padding-bottom: 10vh !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.6667rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 1.3334rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 2.0001rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 2.6668rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3.3335rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 4.0002rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 4.6669rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 5.3336rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 6.0003rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 6.667rem !important;
  }
  .pb-xl-gutter {
    padding-bottom: 24px !important;
  }
  .pb-xl-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }
  .ps-xl-sm {
    padding-left: 0.33335rem !important;
  }
  .ps-xl-3px {
    padding-left: 3px !important;
  }
  .ps-xl-4px {
    padding-left: 4px !important;
  }
  .ps-xl-8px {
    padding-left: 8px !important;
  }
  .ps-xl-12px {
    padding-left: 12px !important;
  }
  .ps-xl-15px {
    padding-left: 15px !important;
  }
  .ps-xl-16px {
    padding-left: 16px !important;
  }
  .ps-xl-18px {
    padding-left: 18px !important;
  }
  .ps-xl-24px {
    padding-left: 24px !important;
  }
  .ps-xl-25px {
    padding-left: 25px !important;
  }
  .ps-xl-30px {
    padding-left: 30px !important;
  }
  .ps-xl-32px {
    padding-left: 32px !important;
  }
  .ps-xl-57px {
    padding-left: 57px !important;
  }
  .ps-xl-n15 {
    padding-left: -15px !important;
  }
  .ps-xl-n5 {
    padding-left: -5px !important;
  }
  .ps-xl-3vh {
    padding-left: 3vh !important;
  }
  .ps-xl-10vh {
    padding-left: 10vh !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.6667rem !important;
  }
  .ps-xl-2 {
    padding-left: 1.3334rem !important;
  }
  .ps-xl-3 {
    padding-left: 2.0001rem !important;
  }
  .ps-xl-4 {
    padding-left: 2.6668rem !important;
  }
  .ps-xl-5 {
    padding-left: 3.3335rem !important;
  }
  .ps-xl-6 {
    padding-left: 4.0002rem !important;
  }
  .ps-xl-7 {
    padding-left: 4.6669rem !important;
  }
  .ps-xl-8 {
    padding-left: 5.3336rem !important;
  }
  .ps-xl-9 {
    padding-left: 6.0003rem !important;
  }
  .ps-xl-10 {
    padding-left: 6.667rem !important;
  }
  .ps-xl-gutter {
    padding-left: 24px !important;
  }
  .ps-xl-out-container {
    padding-left: calc(50% - 50vw) !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-sm {
    gap: 0.33335rem !important;
  }
  .gap-xl-3px {
    gap: 3px !important;
  }
  .gap-xl-4px {
    gap: 4px !important;
  }
  .gap-xl-8px {
    gap: 8px !important;
  }
  .gap-xl-12px {
    gap: 12px !important;
  }
  .gap-xl-15px {
    gap: 15px !important;
  }
  .gap-xl-16px {
    gap: 16px !important;
  }
  .gap-xl-18px {
    gap: 18px !important;
  }
  .gap-xl-24px {
    gap: 24px !important;
  }
  .gap-xl-25px {
    gap: 25px !important;
  }
  .gap-xl-30px {
    gap: 30px !important;
  }
  .gap-xl-32px {
    gap: 32px !important;
  }
  .gap-xl-57px {
    gap: 57px !important;
  }
  .gap-xl-n15 {
    gap: -15px !important;
  }
  .gap-xl-n5 {
    gap: -5px !important;
  }
  .gap-xl-3vh {
    gap: 3vh !important;
  }
  .gap-xl-10vh {
    gap: 10vh !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.6667rem !important;
  }
  .gap-xl-2 {
    gap: 1.3334rem !important;
  }
  .gap-xl-3 {
    gap: 2.0001rem !important;
  }
  .gap-xl-4 {
    gap: 2.6668rem !important;
  }
  .gap-xl-5 {
    gap: 3.3335rem !important;
  }
  .gap-xl-6 {
    gap: 4.0002rem !important;
  }
  .gap-xl-7 {
    gap: 4.6669rem !important;
  }
  .gap-xl-8 {
    gap: 5.3336rem !important;
  }
  .gap-xl-9 {
    gap: 6.0003rem !important;
  }
  .gap-xl-10 {
    gap: 6.667rem !important;
  }
  .gap-xl-gutter {
    gap: 24px !important;
  }
  .gap-xl-out-container {
    gap: calc(50% - 50vw) !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .vh-xl-30 {
    height: 30vh !important;
  }
  .vh-xl-40 {
    height: 40vh !important;
  }
  .vh-xl-50 {
    height: 50vh !important;
  }
  .vh-xl-60 {
    height: 60vh !important;
  }
  .vh-xl-65 {
    height: 65vh !important;
  }
  .vh-xl-70 {
    height: 70vh !important;
  }
  .vh-xl-80 {
    height: 80vh !important;
  }
  .vh-xl-100 {
    height: 100vh !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-35 {
    width: 35% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-45 {
    width: 45% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-55 {
    width: 55% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-64 {
    width: 64% !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .w-xl-70 {
    width: 70% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-82 {
    width: 82% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-20px {
    width: 20px !important;
  }
  .w-xl-80px {
    width: 80px !important;
  }
  .w-xl-icon {
    width: 24px !important;
  }
  .w-xl-fit {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-20px {
    height: 20px !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .h-xl-icon {
    height: 24px !important;
  }
  .h-xl-400 {
    height: 400px !important;
  }
  .h-xl-550 {
    height: 550px !important;
  }
  .h-xl-fit {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .fs-xl-1 {
    font-size: 3.5rem !important;
  }
  .fs-xl-2 {
    font-size: 2rem !important;
  }
  .fs-xl-3 {
    font-size: 1.5rem !important;
  }
  .fs-xl-4 {
    font-size: 1.25rem !important;
  }
  .fs-xl-5 {
    font-size: 1.125rem !important;
  }
  .fs-xl-6 {
    font-size: 1rem !important;
  }
  .fs-xl-7 {
    font-size: 0.875rem !important;
  }
  .fs-xl-22 {
    font-size: 1.375rem !important;
  }
  .fs-xl-40 {
    font-size: 2.5rem !important;
  }
  .fs-xl-48 {
    font-size: 3rem !important;
  }
  .fs-xl-lg {
    font-size: 3.375rem !important;
  }
  .fs-xl-xl {
    font-size: 5rem !important;
  }
  .fs-xl-sm {
    font-size: 0.75rem !important;
  }
  .fs-xl-icon {
    font-size: 1.5625rem !important;
  }
  .lh-xl-1 {
    line-height: 1 !important;
  }
  .lh-xl-sm {
    line-height: 1.25 !important;
  }
  .lh-xl-base {
    line-height: 1.5 !important;
  }
  .lh-xl-md {
    line-height: 1.65 !important;
  }
  .lh-xl-lg {
    line-height: 2 !important;
  }
  .lh-xl-3 {
    line-height: 3 !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .order-xl-unset {
    order: unset !important;
  }
  .cursor-xl-auto {
    cursor: auto !important;
  }
  .cursor-xl-pointer {
    cursor: pointer !important;
  }
  .cursor-xl-grab {
    cursor: -webkit-grab !important;
    cursor: grab !important;
  }
  .border-xl {
    border: 1px solid #d0cfcd !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-xl-2 {
    border: 2px solid !important;
  }
  .border-top-xl {
    border-top: 1px solid #d0cfcd !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-start-xl {
    border-left: 1px solid #d0cfcd !important;
  }
  .border-start-xl-0 {
    border-left: 0 !important;
  }
  .border-end-xl {
    border-right: 1px solid #d0cfcd !important;
  }
  .border-end-xl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xl {
    border-bottom: 1px solid #d0cfcd !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .shadow-xl {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  }
  .shadow-xl-small {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xl-large {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05) !important;
  }
  .shadow-xl-none {
    box-shadow: none !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .text-decoration-xl-none {
    text-decoration: none !important;
  }
  .text-decoration-xl-underline {
    text-decoration: underline !important;
  }
  .text-decoration-xl-line-through {
    text-decoration: line-through !important;
  }
  .text-xl-lowercase {
    text-transform: lowercase !important;
  }
  .text-xl-uppercase {
    text-transform: uppercase !important;
  }
  .text-xl-capitalize {
    text-transform: capitalize !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
  .top-xl-0 {
    top: 0 !important;
  }
  .top-xl-25 {
    top: 25% !important;
  }
  .top-xl-50 {
    top: 50% !important;
  }
  .top-xl-75 {
    top: 75% !important;
  }
  .top-xl-100 {
    top: 100% !important;
  }
  .top-xl-10 {
    top: 10px !important;
  }
  .top-xl-20 {
    top: 20px !important;
  }
  .top-xl-30 {
    top: 30px !important;
  }
  .top-xl-n10 {
    top: -10px !important;
  }
  .top-xl-n20 {
    top: -20px !important;
  }
  .top-xl-n8 {
    top: -8px !important;
  }
  .top-xl-7 {
    top: 7px !important;
  }
  .top-xl-13 {
    top: 13px !important;
  }
  .top-xl-15 {
    top: 15px !important;
  }
  .top-xl-n3 {
    top: -3px !important;
  }
  .top-xl-5 {
    top: 5px !important;
  }
  .top-xl-2 {
    top: 2px !important;
  }
  .bottom-xl-0 {
    bottom: 0 !important;
  }
  .bottom-xl-25 {
    bottom: 25% !important;
  }
  .bottom-xl-50 {
    bottom: 50% !important;
  }
  .bottom-xl-75 {
    bottom: 75% !important;
  }
  .bottom-xl-100 {
    bottom: 100% !important;
  }
  .bottom-xl-10 {
    bottom: 10px !important;
  }
  .bottom-xl-20 {
    bottom: 20px !important;
  }
  .bottom-xl-30 {
    bottom: 30px !important;
  }
  .bottom-xl-n10 {
    bottom: -10px !important;
  }
  .bottom-xl-n20 {
    bottom: -20px !important;
  }
  .bottom-xl-n8 {
    bottom: -8px !important;
  }
  .bottom-xl-7 {
    bottom: 7px !important;
  }
  .bottom-xl-13 {
    bottom: 13px !important;
  }
  .bottom-xl-15 {
    bottom: 15px !important;
  }
  .bottom-xl-n3 {
    bottom: -3px !important;
  }
  .bottom-xl-5 {
    bottom: 5px !important;
  }
  .bottom-xl-2 {
    bottom: 2px !important;
  }
  .end-xl-0 {
    right: 0 !important;
  }
  .end-xl-25 {
    right: 25% !important;
  }
  .end-xl-50 {
    right: 50% !important;
  }
  .end-xl-75 {
    right: 75% !important;
  }
  .end-xl-100 {
    right: 100% !important;
  }
  .end-xl-10 {
    right: 10px !important;
  }
  .end-xl-20 {
    right: 20px !important;
  }
  .end-xl-30 {
    right: 30px !important;
  }
  .end-xl-n10 {
    right: -10px !important;
  }
  .end-xl-n20 {
    right: -20px !important;
  }
  .end-xl-n8 {
    right: -8px !important;
  }
  .end-xl-7 {
    right: 7px !important;
  }
  .end-xl-13 {
    right: 13px !important;
  }
  .end-xl-15 {
    right: 15px !important;
  }
  .end-xl-n3 {
    right: -3px !important;
  }
  .end-xl-5 {
    right: 5px !important;
  }
  .end-xl-2 {
    right: 2px !important;
  }
  .translate-middle-xl {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-xl-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-xl-y {
    transform: translateY(-50%) !important;
  }
  .ls-xl-1 {
    letter-spacing: 1px !important;
  }
  .ls-xl-sm {
    letter-spacing: 1.4px !important;
  }
  .ls-xl-button {
    letter-spacing: 1.5px !important;
  }
  .ls-xl-2 {
    letter-spacing: 2px !important;
  }
  .ls-xl-3 {
    letter-spacing: 3px !important;
  }
  .ls-xl-4 {
    letter-spacing: 4px !important;
  }
  .ls-xl-5 {
    letter-spacing: 5px !important;
  }
  .mx-xl-nsm {
    margin-right: -0.33335rem !important;
    margin-left: -0.33335rem !important;
  }
  .mx-xl-n3px {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .mx-xl-n4px {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }
  .mx-xl-n8px {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }
  .mx-xl-n12px {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }
  .mx-xl-n15px {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-xl-n16px {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }
  .mx-xl-n18px {
    margin-right: -18px !important;
    margin-left: -18px !important;
  }
  .mx-xl-n24px {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }
  .mx-xl-n25px {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .mx-xl-n30px {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .mx-xl-n32px {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }
  .mx-xl-n57px {
    margin-right: -57px !important;
    margin-left: -57px !important;
  }
  .mx-xl-nn15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-xl-nn5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-xl-n3vh {
    margin-right: -3vh !important;
    margin-left: -3vh !important;
  }
  .mx-xl-n10vh {
    margin-right: -10vh !important;
    margin-left: -10vh !important;
  }
  .mx-xl-n1 {
    margin-right: -0.6667rem !important;
    margin-left: -0.6667rem !important;
  }
  .mx-xl-n2 {
    margin-right: -1.3334rem !important;
    margin-left: -1.3334rem !important;
  }
  .mx-xl-n3 {
    margin-right: -2.0001rem !important;
    margin-left: -2.0001rem !important;
  }
  .mx-xl-n4 {
    margin-right: -2.6668rem !important;
    margin-left: -2.6668rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3.3335rem !important;
    margin-left: -3.3335rem !important;
  }
  .mx-xl-n6 {
    margin-right: -4.0002rem !important;
    margin-left: -4.0002rem !important;
  }
  .mx-xl-n7 {
    margin-right: -4.6669rem !important;
    margin-left: -4.6669rem !important;
  }
  .mx-xl-n8 {
    margin-right: -5.3336rem !important;
    margin-left: -5.3336rem !important;
  }
  .mx-xl-n9 {
    margin-right: -6.0003rem !important;
    margin-left: -6.0003rem !important;
  }
  .mx-xl-n10 {
    margin-right: -6.667rem !important;
    margin-left: -6.667rem !important;
  }
  .mx-xl-ngutter {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }
  .mx-xl-nout-container {
    margin-right: -calc(50% - 50vw) !important;
    margin-left: -calc(50% - 50vw) !important;
  }
  .my-xl-nsm {
    margin-top: -0.33335rem !important;
    margin-bottom: -0.33335rem !important;
  }
  .my-xl-n3px {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .my-xl-n4px {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .my-xl-n8px {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .my-xl-n12px {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .my-xl-n15px {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-xl-n16px {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .my-xl-n18px {
    margin-top: -18px !important;
    margin-bottom: -18px !important;
  }
  .my-xl-n24px {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .my-xl-n25px {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .my-xl-n30px {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .my-xl-n32px {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .my-xl-n57px {
    margin-top: -57px !important;
    margin-bottom: -57px !important;
  }
  .my-xl-nn15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-xl-nn5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-xl-n3vh {
    margin-top: -3vh !important;
    margin-bottom: -3vh !important;
  }
  .my-xl-n10vh {
    margin-top: -10vh !important;
    margin-bottom: -10vh !important;
  }
  .my-xl-n1 {
    margin-top: -0.6667rem !important;
    margin-bottom: -0.6667rem !important;
  }
  .my-xl-n2 {
    margin-top: -1.3334rem !important;
    margin-bottom: -1.3334rem !important;
  }
  .my-xl-n3 {
    margin-top: -2.0001rem !important;
    margin-bottom: -2.0001rem !important;
  }
  .my-xl-n4 {
    margin-top: -2.6668rem !important;
    margin-bottom: -2.6668rem !important;
  }
  .my-xl-n5 {
    margin-top: -3.3335rem !important;
    margin-bottom: -3.3335rem !important;
  }
  .my-xl-n6 {
    margin-top: -4.0002rem !important;
    margin-bottom: -4.0002rem !important;
  }
  .my-xl-n7 {
    margin-top: -4.6669rem !important;
    margin-bottom: -4.6669rem !important;
  }
  .my-xl-n8 {
    margin-top: -5.3336rem !important;
    margin-bottom: -5.3336rem !important;
  }
  .my-xl-n9 {
    margin-top: -6.0003rem !important;
    margin-bottom: -6.0003rem !important;
  }
  .my-xl-n10 {
    margin-top: -6.667rem !important;
    margin-bottom: -6.667rem !important;
  }
  .my-xl-ngutter {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .my-xl-nout-container {
    margin-top: -calc(50% - 50vw) !important;
    margin-bottom: -calc(50% - 50vw) !important;
  }
  .mt-xl-nsm {
    margin-top: -0.33335rem !important;
  }
  .mt-xl-n3px {
    margin-top: -3px !important;
  }
  .mt-xl-n4px {
    margin-top: -4px !important;
  }
  .mt-xl-n8px {
    margin-top: -8px !important;
  }
  .mt-xl-n12px {
    margin-top: -12px !important;
  }
  .mt-xl-n15px {
    margin-top: -15px !important;
  }
  .mt-xl-n16px {
    margin-top: -16px !important;
  }
  .mt-xl-n18px {
    margin-top: -18px !important;
  }
  .mt-xl-n24px {
    margin-top: -24px !important;
  }
  .mt-xl-n25px {
    margin-top: -25px !important;
  }
  .mt-xl-n30px {
    margin-top: -30px !important;
  }
  .mt-xl-n32px {
    margin-top: -32px !important;
  }
  .mt-xl-n57px {
    margin-top: -57px !important;
  }
  .mt-xl-nn15 {
    margin-top: 15px !important;
  }
  .mt-xl-nn5 {
    margin-top: 5px !important;
  }
  .mt-xl-n3vh {
    margin-top: -3vh !important;
  }
  .mt-xl-n10vh {
    margin-top: -10vh !important;
  }
  .mt-xl-n1 {
    margin-top: -0.6667rem !important;
  }
  .mt-xl-n2 {
    margin-top: -1.3334rem !important;
  }
  .mt-xl-n3 {
    margin-top: -2.0001rem !important;
  }
  .mt-xl-n4 {
    margin-top: -2.6668rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3.3335rem !important;
  }
  .mt-xl-n6 {
    margin-top: -4.0002rem !important;
  }
  .mt-xl-n7 {
    margin-top: -4.6669rem !important;
  }
  .mt-xl-n8 {
    margin-top: -5.3336rem !important;
  }
  .mt-xl-n9 {
    margin-top: -6.0003rem !important;
  }
  .mt-xl-n10 {
    margin-top: -6.667rem !important;
  }
  .mt-xl-ngutter {
    margin-top: -24px !important;
  }
  .mt-xl-nout-container {
    margin-top: -calc(50% - 50vw) !important;
  }
  .me-xl-nsm {
    margin-right: -0.33335rem !important;
  }
  .me-xl-n3px {
    margin-right: -3px !important;
  }
  .me-xl-n4px {
    margin-right: -4px !important;
  }
  .me-xl-n8px {
    margin-right: -8px !important;
  }
  .me-xl-n12px {
    margin-right: -12px !important;
  }
  .me-xl-n15px {
    margin-right: -15px !important;
  }
  .me-xl-n16px {
    margin-right: -16px !important;
  }
  .me-xl-n18px {
    margin-right: -18px !important;
  }
  .me-xl-n24px {
    margin-right: -24px !important;
  }
  .me-xl-n25px {
    margin-right: -25px !important;
  }
  .me-xl-n30px {
    margin-right: -30px !important;
  }
  .me-xl-n32px {
    margin-right: -32px !important;
  }
  .me-xl-n57px {
    margin-right: -57px !important;
  }
  .me-xl-nn15 {
    margin-right: 15px !important;
  }
  .me-xl-nn5 {
    margin-right: 5px !important;
  }
  .me-xl-n3vh {
    margin-right: -3vh !important;
  }
  .me-xl-n10vh {
    margin-right: -10vh !important;
  }
  .me-xl-n1 {
    margin-right: -0.6667rem !important;
  }
  .me-xl-n2 {
    margin-right: -1.3334rem !important;
  }
  .me-xl-n3 {
    margin-right: -2.0001rem !important;
  }
  .me-xl-n4 {
    margin-right: -2.6668rem !important;
  }
  .me-xl-n5 {
    margin-right: -3.3335rem !important;
  }
  .me-xl-n6 {
    margin-right: -4.0002rem !important;
  }
  .me-xl-n7 {
    margin-right: -4.6669rem !important;
  }
  .me-xl-n8 {
    margin-right: -5.3336rem !important;
  }
  .me-xl-n9 {
    margin-right: -6.0003rem !important;
  }
  .me-xl-n10 {
    margin-right: -6.667rem !important;
  }
  .me-xl-ngutter {
    margin-right: -24px !important;
  }
  .me-xl-nout-container {
    margin-right: -calc(50% - 50vw) !important;
  }
  .mb-xl-nsm {
    margin-bottom: -0.33335rem !important;
  }
  .mb-xl-n3px {
    margin-bottom: -3px !important;
  }
  .mb-xl-n4px {
    margin-bottom: -4px !important;
  }
  .mb-xl-n8px {
    margin-bottom: -8px !important;
  }
  .mb-xl-n12px {
    margin-bottom: -12px !important;
  }
  .mb-xl-n15px {
    margin-bottom: -15px !important;
  }
  .mb-xl-n16px {
    margin-bottom: -16px !important;
  }
  .mb-xl-n18px {
    margin-bottom: -18px !important;
  }
  .mb-xl-n24px {
    margin-bottom: -24px !important;
  }
  .mb-xl-n25px {
    margin-bottom: -25px !important;
  }
  .mb-xl-n30px {
    margin-bottom: -30px !important;
  }
  .mb-xl-n32px {
    margin-bottom: -32px !important;
  }
  .mb-xl-n57px {
    margin-bottom: -57px !important;
  }
  .mb-xl-nn15 {
    margin-bottom: 15px !important;
  }
  .mb-xl-nn5 {
    margin-bottom: 5px !important;
  }
  .mb-xl-n3vh {
    margin-bottom: -3vh !important;
  }
  .mb-xl-n10vh {
    margin-bottom: -10vh !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.6667rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -1.3334rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -2.0001rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -2.6668rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3.3335rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -4.0002rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -4.6669rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -5.3336rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -6.0003rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -6.667rem !important;
  }
  .mb-xl-ngutter {
    margin-bottom: -24px !important;
  }
  .mb-xl-nout-container {
    margin-bottom: -calc(50% - 50vw) !important;
  }
  .ms-xl-nsm {
    margin-left: -0.33335rem !important;
  }
  .ms-xl-n3px {
    margin-left: -3px !important;
  }
  .ms-xl-n4px {
    margin-left: -4px !important;
  }
  .ms-xl-n8px {
    margin-left: -8px !important;
  }
  .ms-xl-n12px {
    margin-left: -12px !important;
  }
  .ms-xl-n15px {
    margin-left: -15px !important;
  }
  .ms-xl-n16px {
    margin-left: -16px !important;
  }
  .ms-xl-n18px {
    margin-left: -18px !important;
  }
  .ms-xl-n24px {
    margin-left: -24px !important;
  }
  .ms-xl-n25px {
    margin-left: -25px !important;
  }
  .ms-xl-n30px {
    margin-left: -30px !important;
  }
  .ms-xl-n32px {
    margin-left: -32px !important;
  }
  .ms-xl-n57px {
    margin-left: -57px !important;
  }
  .ms-xl-nn15 {
    margin-left: 15px !important;
  }
  .ms-xl-nn5 {
    margin-left: 5px !important;
  }
  .ms-xl-n3vh {
    margin-left: -3vh !important;
  }
  .ms-xl-n10vh {
    margin-left: -10vh !important;
  }
  .ms-xl-n1 {
    margin-left: -0.6667rem !important;
  }
  .ms-xl-n2 {
    margin-left: -1.3334rem !important;
  }
  .ms-xl-n3 {
    margin-left: -2.0001rem !important;
  }
  .ms-xl-n4 {
    margin-left: -2.6668rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3.3335rem !important;
  }
  .ms-xl-n6 {
    margin-left: -4.0002rem !important;
  }
  .ms-xl-n7 {
    margin-left: -4.6669rem !important;
  }
  .ms-xl-n8 {
    margin-left: -5.3336rem !important;
  }
  .ms-xl-n9 {
    margin-left: -6.0003rem !important;
  }
  .ms-xl-n10 {
    margin-left: -6.667rem !important;
  }
  .ms-xl-ngutter {
    margin-left: -24px !important;
  }
  .ms-xl-nout-container {
    margin-left: -calc(50% - 50vw) !important;
  }
  .list-style-xl-none {
    list-style: none !important;
  }
  .list-style-xl-circle {
    list-style: circle !important;
  }
  .list-style-xl-disc {
    list-style: disc !important;
  }
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1600px) {
  .font-xxl-sans-serif {
    font-family: "arial-regular", sans-serif !important;
  }
  .font-xxl-sans-serif-italic {
    font-family: "arial-italic", sans-serif !important;
  }
  .font-xxl-sans-serif-bolditalic {
    font-family: "arial-bolditalic", sans-serif !important;
  }
  .font-xxl-sans-serif-bold {
    font-family: "arial-bold", sans-serif !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .bg-xxl-primary {
    --bs-bg-opacity: 1;
    background-color: #0a3255 !important;
  }
  .bg-xxl-primary-light {
    --bs-bg-opacity: 1;
    background-color: rgba(10, 50, 85, 0.5) !important;
  }
  .bg-xxl-secondary {
    --bs-bg-opacity: 1;
    background-color: #da262e !important;
  }
  .bg-xxl-secondary-light {
    --bs-bg-opacity: 1;
    background-color: rgba(218, 38, 46, 0.5) !important;
  }
  .bg-xxl-westar {
    --bs-bg-opacity: 1;
    background-color: #ddd8ce !important;
  }
  .bg-xxl-swirl {
    --bs-bg-opacity: 1;
    background-color: #d7cdca !important;
  }
  .bg-xxl-light {
    --bs-bg-opacity: 1;
    background-color: #fbfbfb !important;
  }
  .bg-xxl-success {
    --bs-bg-opacity: 1;
    background-color: #1acf82 !important;
  }
  .bg-xxl-gray-100 {
    --bs-bg-opacity: 1;
    background-color: #9a9ea6 !important;
  }
  .bg-xxl-gray-200 {
    --bs-bg-opacity: 1;
    background-color: #f6f5f5 !important;
  }
  .bg-xxl-gray-300 {
    --bs-bg-opacity: 1;
    background-color: #d0cfcd !important;
  }
  .bg-xxl-gray-400 {
    --bs-bg-opacity: 1;
    background-color: #efefef !important;
  }
  .bg-xxl-gray-500 {
    --bs-bg-opacity: 1;
    background-color: #2a2644 !important;
  }
  .bg-xxl-gray-600 {
    --bs-bg-opacity: 1;
    background-color: #e3e3e3 !important;
  }
  .bg-xxl-gray-800 {
    --bs-bg-opacity: 1;
    background-color: #e0e0e0 !important;
  }
  .bg-xxl-gray-900 {
    --bs-bg-opacity: 1;
    background-color: #c4c4c4 !important;
  }
  .bg-xxl-body {
    --bs-bg-opacity: 1;
    background-color: #0a3255 !important;
  }
  .bg-xxl-info {
    --bs-bg-opacity: 1;
    background-color: #60b8d0 !important;
  }
  .bg-xxl-info-100 {
    --bs-bg-opacity: 1;
    background-color: #0083c1 !important;
  }
  .bg-xxl-info-200 {
    --bs-bg-opacity: 1;
    background-color: #52b1c5 !important;
  }
  .bg-xxl-info-300 {
    --bs-bg-opacity: 1;
    background-color: #97d1dc !important;
  }
  .bg-xxl-info-400 {
    --bs-bg-opacity: 1;
    background-color: #40a7be !important;
  }
  .bg-xxl-warning {
    --bs-bg-opacity: 1;
    background-color: #d3ad57 !important;
  }
  .bg-xxl-danger {
    --bs-bg-opacity: 1;
    background-color: #d7282f !important;
  }
  .bg-xxl-dark {
    --bs-bg-opacity: 1;
    background-color: #e9e9e9 !important;
  }
  .bg-xxl-black {
    --bs-bg-opacity: 1;
    background-color: #000 !important;
  }
  .bg-xxl-red {
    --bs-bg-opacity: 1;
    background-color: #d7282f !important;
  }
  .bg-xxl-white {
    --bs-bg-opacity: 1;
    background-color: #ffffff !important;
  }
  .bg-xxl-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .text-xxl-primary {
    color: #0a3255 !important;
  }
  .text-xxl-primary-light {
    color: rgba(10, 50, 85, 0.5) !important;
  }
  .text-xxl-secondary {
    color: #da262e !important;
  }
  .text-xxl-secondary-light {
    color: rgba(218, 38, 46, 0.5) !important;
  }
  .text-xxl-westar {
    color: #ddd8ce !important;
  }
  .text-xxl-swirl {
    color: #d7cdca !important;
  }
  .text-xxl-light {
    color: #fbfbfb !important;
  }
  .text-xxl-success {
    color: #1acf82 !important;
  }
  .text-xxl-gray-100 {
    color: #9a9ea6 !important;
  }
  .text-xxl-gray-200 {
    color: #f6f5f5 !important;
  }
  .text-xxl-gray-300 {
    color: #d0cfcd !important;
  }
  .text-xxl-gray-400 {
    color: #efefef !important;
  }
  .text-xxl-gray-500 {
    color: #2a2644 !important;
  }
  .text-xxl-gray-600 {
    color: #e3e3e3 !important;
  }
  .text-xxl-gray-800 {
    color: #e0e0e0 !important;
  }
  .text-xxl-gray-900 {
    color: #c4c4c4 !important;
  }
  .text-xxl-body {
    color: #0a3255 !important;
  }
  .text-xxl-info {
    color: #60b8d0 !important;
  }
  .text-xxl-info-100 {
    color: #0083c1 !important;
  }
  .text-xxl-info-200 {
    color: #52b1c5 !important;
  }
  .text-xxl-info-300 {
    color: #97d1dc !important;
  }
  .text-xxl-info-400 {
    color: #40a7be !important;
  }
  .text-xxl-warning {
    color: #d3ad57 !important;
  }
  .text-xxl-danger {
    color: #d7282f !important;
  }
  .text-xxl-dark {
    color: #e9e9e9 !important;
  }
  .text-xxl-black {
    color: #000 !important;
  }
  .text-xxl-red {
    color: #d7282f !important;
  }
  .text-xxl-white {
    color: #ffffff !important;
  }
  .text-xxl-transparent {
    color: transparent !important;
  }
  .m-xxl-sm {
    margin: 0.33335rem !important;
  }
  .m-xxl-3px {
    margin: 3px !important;
  }
  .m-xxl-4px {
    margin: 4px !important;
  }
  .m-xxl-8px {
    margin: 8px !important;
  }
  .m-xxl-12px {
    margin: 12px !important;
  }
  .m-xxl-15px {
    margin: 15px !important;
  }
  .m-xxl-16px {
    margin: 16px !important;
  }
  .m-xxl-18px {
    margin: 18px !important;
  }
  .m-xxl-24px {
    margin: 24px !important;
  }
  .m-xxl-25px {
    margin: 25px !important;
  }
  .m-xxl-30px {
    margin: 30px !important;
  }
  .m-xxl-32px {
    margin: 32px !important;
  }
  .m-xxl-57px {
    margin: 57px !important;
  }
  .m-xxl-n15 {
    margin: -15px !important;
  }
  .m-xxl-n5 {
    margin: -5px !important;
  }
  .m-xxl-3vh {
    margin: 3vh !important;
  }
  .m-xxl-10vh {
    margin: 10vh !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.6667rem !important;
  }
  .m-xxl-2 {
    margin: 1.3334rem !important;
  }
  .m-xxl-3 {
    margin: 2.0001rem !important;
  }
  .m-xxl-4 {
    margin: 2.6668rem !important;
  }
  .m-xxl-5 {
    margin: 3.3335rem !important;
  }
  .m-xxl-6 {
    margin: 4.0002rem !important;
  }
  .m-xxl-7 {
    margin: 4.6669rem !important;
  }
  .m-xxl-8 {
    margin: 5.3336rem !important;
  }
  .m-xxl-9 {
    margin: 6.0003rem !important;
  }
  .m-xxl-10 {
    margin: 6.667rem !important;
  }
  .m-xxl-gutter {
    margin: 24px !important;
  }
  .m-xxl-out-container {
    margin: calc(50% - 50vw) !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-sm {
    margin-right: 0.33335rem !important;
    margin-left: 0.33335rem !important;
  }
  .mx-xxl-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-xxl-4px {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .mx-xxl-8px {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .mx-xxl-12px {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .mx-xxl-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-xxl-16px {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .mx-xxl-18px {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
  .mx-xxl-24px {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  .mx-xxl-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-xxl-30px {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-xxl-32px {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
  .mx-xxl-57px {
    margin-right: 57px !important;
    margin-left: 57px !important;
  }
  .mx-xxl-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-xxl-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-xxl-3vh {
    margin-right: 3vh !important;
    margin-left: 3vh !important;
  }
  .mx-xxl-10vh {
    margin-right: 10vh !important;
    margin-left: 10vh !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.6667rem !important;
    margin-left: 0.6667rem !important;
  }
  .mx-xxl-2 {
    margin-right: 1.3334rem !important;
    margin-left: 1.3334rem !important;
  }
  .mx-xxl-3 {
    margin-right: 2.0001rem !important;
    margin-left: 2.0001rem !important;
  }
  .mx-xxl-4 {
    margin-right: 2.6668rem !important;
    margin-left: 2.6668rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3.3335rem !important;
    margin-left: 3.3335rem !important;
  }
  .mx-xxl-6 {
    margin-right: 4.0002rem !important;
    margin-left: 4.0002rem !important;
  }
  .mx-xxl-7 {
    margin-right: 4.6669rem !important;
    margin-left: 4.6669rem !important;
  }
  .mx-xxl-8 {
    margin-right: 5.3336rem !important;
    margin-left: 5.3336rem !important;
  }
  .mx-xxl-9 {
    margin-right: 6.0003rem !important;
    margin-left: 6.0003rem !important;
  }
  .mx-xxl-10 {
    margin-right: 6.667rem !important;
    margin-left: 6.667rem !important;
  }
  .mx-xxl-gutter {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  .mx-xxl-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-sm {
    margin-top: 0.33335rem !important;
    margin-bottom: 0.33335rem !important;
  }
  .my-xxl-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-xxl-4px {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .my-xxl-8px {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .my-xxl-12px {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .my-xxl-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-xxl-16px {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .my-xxl-18px {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .my-xxl-24px {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .my-xxl-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-xxl-30px {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-xxl-32px {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .my-xxl-57px {
    margin-top: 57px !important;
    margin-bottom: 57px !important;
  }
  .my-xxl-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-xxl-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-xxl-3vh {
    margin-top: 3vh !important;
    margin-bottom: 3vh !important;
  }
  .my-xxl-10vh {
    margin-top: 10vh !important;
    margin-bottom: 10vh !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.6667rem !important;
    margin-bottom: 0.6667rem !important;
  }
  .my-xxl-2 {
    margin-top: 1.3334rem !important;
    margin-bottom: 1.3334rem !important;
  }
  .my-xxl-3 {
    margin-top: 2.0001rem !important;
    margin-bottom: 2.0001rem !important;
  }
  .my-xxl-4 {
    margin-top: 2.6668rem !important;
    margin-bottom: 2.6668rem !important;
  }
  .my-xxl-5 {
    margin-top: 3.3335rem !important;
    margin-bottom: 3.3335rem !important;
  }
  .my-xxl-6 {
    margin-top: 4.0002rem !important;
    margin-bottom: 4.0002rem !important;
  }
  .my-xxl-7 {
    margin-top: 4.6669rem !important;
    margin-bottom: 4.6669rem !important;
  }
  .my-xxl-8 {
    margin-top: 5.3336rem !important;
    margin-bottom: 5.3336rem !important;
  }
  .my-xxl-9 {
    margin-top: 6.0003rem !important;
    margin-bottom: 6.0003rem !important;
  }
  .my-xxl-10 {
    margin-top: 6.667rem !important;
    margin-bottom: 6.667rem !important;
  }
  .my-xxl-gutter {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .my-xxl-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-sm {
    margin-top: 0.33335rem !important;
  }
  .mt-xxl-3px {
    margin-top: 3px !important;
  }
  .mt-xxl-4px {
    margin-top: 4px !important;
  }
  .mt-xxl-8px {
    margin-top: 8px !important;
  }
  .mt-xxl-12px {
    margin-top: 12px !important;
  }
  .mt-xxl-15px {
    margin-top: 15px !important;
  }
  .mt-xxl-16px {
    margin-top: 16px !important;
  }
  .mt-xxl-18px {
    margin-top: 18px !important;
  }
  .mt-xxl-24px {
    margin-top: 24px !important;
  }
  .mt-xxl-25px {
    margin-top: 25px !important;
  }
  .mt-xxl-30px {
    margin-top: 30px !important;
  }
  .mt-xxl-32px {
    margin-top: 32px !important;
  }
  .mt-xxl-57px {
    margin-top: 57px !important;
  }
  .mt-xxl-n15 {
    margin-top: -15px !important;
  }
  .mt-xxl-n5 {
    margin-top: -5px !important;
  }
  .mt-xxl-3vh {
    margin-top: 3vh !important;
  }
  .mt-xxl-10vh {
    margin-top: 10vh !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.6667rem !important;
  }
  .mt-xxl-2 {
    margin-top: 1.3334rem !important;
  }
  .mt-xxl-3 {
    margin-top: 2.0001rem !important;
  }
  .mt-xxl-4 {
    margin-top: 2.6668rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3.3335rem !important;
  }
  .mt-xxl-6 {
    margin-top: 4.0002rem !important;
  }
  .mt-xxl-7 {
    margin-top: 4.6669rem !important;
  }
  .mt-xxl-8 {
    margin-top: 5.3336rem !important;
  }
  .mt-xxl-9 {
    margin-top: 6.0003rem !important;
  }
  .mt-xxl-10 {
    margin-top: 6.667rem !important;
  }
  .mt-xxl-gutter {
    margin-top: 24px !important;
  }
  .mt-xxl-out-container {
    margin-top: calc(50% - 50vw) !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-sm {
    margin-right: 0.33335rem !important;
  }
  .me-xxl-3px {
    margin-right: 3px !important;
  }
  .me-xxl-4px {
    margin-right: 4px !important;
  }
  .me-xxl-8px {
    margin-right: 8px !important;
  }
  .me-xxl-12px {
    margin-right: 12px !important;
  }
  .me-xxl-15px {
    margin-right: 15px !important;
  }
  .me-xxl-16px {
    margin-right: 16px !important;
  }
  .me-xxl-18px {
    margin-right: 18px !important;
  }
  .me-xxl-24px {
    margin-right: 24px !important;
  }
  .me-xxl-25px {
    margin-right: 25px !important;
  }
  .me-xxl-30px {
    margin-right: 30px !important;
  }
  .me-xxl-32px {
    margin-right: 32px !important;
  }
  .me-xxl-57px {
    margin-right: 57px !important;
  }
  .me-xxl-n15 {
    margin-right: -15px !important;
  }
  .me-xxl-n5 {
    margin-right: -5px !important;
  }
  .me-xxl-3vh {
    margin-right: 3vh !important;
  }
  .me-xxl-10vh {
    margin-right: 10vh !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.6667rem !important;
  }
  .me-xxl-2 {
    margin-right: 1.3334rem !important;
  }
  .me-xxl-3 {
    margin-right: 2.0001rem !important;
  }
  .me-xxl-4 {
    margin-right: 2.6668rem !important;
  }
  .me-xxl-5 {
    margin-right: 3.3335rem !important;
  }
  .me-xxl-6 {
    margin-right: 4.0002rem !important;
  }
  .me-xxl-7 {
    margin-right: 4.6669rem !important;
  }
  .me-xxl-8 {
    margin-right: 5.3336rem !important;
  }
  .me-xxl-9 {
    margin-right: 6.0003rem !important;
  }
  .me-xxl-10 {
    margin-right: 6.667rem !important;
  }
  .me-xxl-gutter {
    margin-right: 24px !important;
  }
  .me-xxl-out-container {
    margin-right: calc(50% - 50vw) !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-sm {
    margin-bottom: 0.33335rem !important;
  }
  .mb-xxl-3px {
    margin-bottom: 3px !important;
  }
  .mb-xxl-4px {
    margin-bottom: 4px !important;
  }
  .mb-xxl-8px {
    margin-bottom: 8px !important;
  }
  .mb-xxl-12px {
    margin-bottom: 12px !important;
  }
  .mb-xxl-15px {
    margin-bottom: 15px !important;
  }
  .mb-xxl-16px {
    margin-bottom: 16px !important;
  }
  .mb-xxl-18px {
    margin-bottom: 18px !important;
  }
  .mb-xxl-24px {
    margin-bottom: 24px !important;
  }
  .mb-xxl-25px {
    margin-bottom: 25px !important;
  }
  .mb-xxl-30px {
    margin-bottom: 30px !important;
  }
  .mb-xxl-32px {
    margin-bottom: 32px !important;
  }
  .mb-xxl-57px {
    margin-bottom: 57px !important;
  }
  .mb-xxl-n15 {
    margin-bottom: -15px !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -5px !important;
  }
  .mb-xxl-3vh {
    margin-bottom: 3vh !important;
  }
  .mb-xxl-10vh {
    margin-bottom: 10vh !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.6667rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 1.3334rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 2.0001rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 2.6668rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3.3335rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 4.0002rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 4.6669rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 5.3336rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 6.0003rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 6.667rem !important;
  }
  .mb-xxl-gutter {
    margin-bottom: 24px !important;
  }
  .mb-xxl-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-sm {
    margin-left: 0.33335rem !important;
  }
  .ms-xxl-3px {
    margin-left: 3px !important;
  }
  .ms-xxl-4px {
    margin-left: 4px !important;
  }
  .ms-xxl-8px {
    margin-left: 8px !important;
  }
  .ms-xxl-12px {
    margin-left: 12px !important;
  }
  .ms-xxl-15px {
    margin-left: 15px !important;
  }
  .ms-xxl-16px {
    margin-left: 16px !important;
  }
  .ms-xxl-18px {
    margin-left: 18px !important;
  }
  .ms-xxl-24px {
    margin-left: 24px !important;
  }
  .ms-xxl-25px {
    margin-left: 25px !important;
  }
  .ms-xxl-30px {
    margin-left: 30px !important;
  }
  .ms-xxl-32px {
    margin-left: 32px !important;
  }
  .ms-xxl-57px {
    margin-left: 57px !important;
  }
  .ms-xxl-n15 {
    margin-left: -15px !important;
  }
  .ms-xxl-n5 {
    margin-left: -5px !important;
  }
  .ms-xxl-3vh {
    margin-left: 3vh !important;
  }
  .ms-xxl-10vh {
    margin-left: 10vh !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.6667rem !important;
  }
  .ms-xxl-2 {
    margin-left: 1.3334rem !important;
  }
  .ms-xxl-3 {
    margin-left: 2.0001rem !important;
  }
  .ms-xxl-4 {
    margin-left: 2.6668rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3.3335rem !important;
  }
  .ms-xxl-6 {
    margin-left: 4.0002rem !important;
  }
  .ms-xxl-7 {
    margin-left: 4.6669rem !important;
  }
  .ms-xxl-8 {
    margin-left: 5.3336rem !important;
  }
  .ms-xxl-9 {
    margin-left: 6.0003rem !important;
  }
  .ms-xxl-10 {
    margin-left: 6.667rem !important;
  }
  .ms-xxl-gutter {
    margin-left: 24px !important;
  }
  .ms-xxl-out-container {
    margin-left: calc(50% - 50vw) !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-sm {
    padding: 0.33335rem !important;
  }
  .p-xxl-3px {
    padding: 3px !important;
  }
  .p-xxl-4px {
    padding: 4px !important;
  }
  .p-xxl-8px {
    padding: 8px !important;
  }
  .p-xxl-12px {
    padding: 12px !important;
  }
  .p-xxl-15px {
    padding: 15px !important;
  }
  .p-xxl-16px {
    padding: 16px !important;
  }
  .p-xxl-18px {
    padding: 18px !important;
  }
  .p-xxl-24px {
    padding: 24px !important;
  }
  .p-xxl-25px {
    padding: 25px !important;
  }
  .p-xxl-30px {
    padding: 30px !important;
  }
  .p-xxl-32px {
    padding: 32px !important;
  }
  .p-xxl-57px {
    padding: 57px !important;
  }
  .p-xxl-n15 {
    padding: -15px !important;
  }
  .p-xxl-n5 {
    padding: -5px !important;
  }
  .p-xxl-3vh {
    padding: 3vh !important;
  }
  .p-xxl-10vh {
    padding: 10vh !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.6667rem !important;
  }
  .p-xxl-2 {
    padding: 1.3334rem !important;
  }
  .p-xxl-3 {
    padding: 2.0001rem !important;
  }
  .p-xxl-4 {
    padding: 2.6668rem !important;
  }
  .p-xxl-5 {
    padding: 3.3335rem !important;
  }
  .p-xxl-6 {
    padding: 4.0002rem !important;
  }
  .p-xxl-7 {
    padding: 4.6669rem !important;
  }
  .p-xxl-8 {
    padding: 5.3336rem !important;
  }
  .p-xxl-9 {
    padding: 6.0003rem !important;
  }
  .p-xxl-10 {
    padding: 6.667rem !important;
  }
  .p-xxl-gutter {
    padding: 24px !important;
  }
  .p-xxl-out-container {
    padding: calc(50% - 50vw) !important;
  }
  .px-xxl-sm {
    padding-right: 0.33335rem !important;
    padding-left: 0.33335rem !important;
  }
  .px-xxl-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-xxl-4px {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .px-xxl-8px {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .px-xxl-12px {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .px-xxl-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-xxl-16px {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .px-xxl-18px {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
  .px-xxl-24px {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .px-xxl-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-xxl-30px {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-xxl-32px {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
  .px-xxl-57px {
    padding-right: 57px !important;
    padding-left: 57px !important;
  }
  .px-xxl-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }
  .px-xxl-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }
  .px-xxl-3vh {
    padding-right: 3vh !important;
    padding-left: 3vh !important;
  }
  .px-xxl-10vh {
    padding-right: 10vh !important;
    padding-left: 10vh !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.6667rem !important;
    padding-left: 0.6667rem !important;
  }
  .px-xxl-2 {
    padding-right: 1.3334rem !important;
    padding-left: 1.3334rem !important;
  }
  .px-xxl-3 {
    padding-right: 2.0001rem !important;
    padding-left: 2.0001rem !important;
  }
  .px-xxl-4 {
    padding-right: 2.6668rem !important;
    padding-left: 2.6668rem !important;
  }
  .px-xxl-5 {
    padding-right: 3.3335rem !important;
    padding-left: 3.3335rem !important;
  }
  .px-xxl-6 {
    padding-right: 4.0002rem !important;
    padding-left: 4.0002rem !important;
  }
  .px-xxl-7 {
    padding-right: 4.6669rem !important;
    padding-left: 4.6669rem !important;
  }
  .px-xxl-8 {
    padding-right: 5.3336rem !important;
    padding-left: 5.3336rem !important;
  }
  .px-xxl-9 {
    padding-right: 6.0003rem !important;
    padding-left: 6.0003rem !important;
  }
  .px-xxl-10 {
    padding-right: 6.667rem !important;
    padding-left: 6.667rem !important;
  }
  .px-xxl-gutter {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .px-xxl-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }
  .py-xxl-sm {
    padding-top: 0.33335rem !important;
    padding-bottom: 0.33335rem !important;
  }
  .py-xxl-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-xxl-4px {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .py-xxl-8px {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .py-xxl-12px {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .py-xxl-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-xxl-16px {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .py-xxl-18px {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .py-xxl-24px {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-xxl-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-xxl-30px {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-xxl-32px {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .py-xxl-57px {
    padding-top: 57px !important;
    padding-bottom: 57px !important;
  }
  .py-xxl-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }
  .py-xxl-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }
  .py-xxl-3vh {
    padding-top: 3vh !important;
    padding-bottom: 3vh !important;
  }
  .py-xxl-10vh {
    padding-top: 10vh !important;
    padding-bottom: 10vh !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.6667rem !important;
    padding-bottom: 0.6667rem !important;
  }
  .py-xxl-2 {
    padding-top: 1.3334rem !important;
    padding-bottom: 1.3334rem !important;
  }
  .py-xxl-3 {
    padding-top: 2.0001rem !important;
    padding-bottom: 2.0001rem !important;
  }
  .py-xxl-4 {
    padding-top: 2.6668rem !important;
    padding-bottom: 2.6668rem !important;
  }
  .py-xxl-5 {
    padding-top: 3.3335rem !important;
    padding-bottom: 3.3335rem !important;
  }
  .py-xxl-6 {
    padding-top: 4.0002rem !important;
    padding-bottom: 4.0002rem !important;
  }
  .py-xxl-7 {
    padding-top: 4.6669rem !important;
    padding-bottom: 4.6669rem !important;
  }
  .py-xxl-8 {
    padding-top: 5.3336rem !important;
    padding-bottom: 5.3336rem !important;
  }
  .py-xxl-9 {
    padding-top: 6.0003rem !important;
    padding-bottom: 6.0003rem !important;
  }
  .py-xxl-10 {
    padding-top: 6.667rem !important;
    padding-bottom: 6.667rem !important;
  }
  .py-xxl-gutter {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-xxl-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }
  .pt-xxl-sm {
    padding-top: 0.33335rem !important;
  }
  .pt-xxl-3px {
    padding-top: 3px !important;
  }
  .pt-xxl-4px {
    padding-top: 4px !important;
  }
  .pt-xxl-8px {
    padding-top: 8px !important;
  }
  .pt-xxl-12px {
    padding-top: 12px !important;
  }
  .pt-xxl-15px {
    padding-top: 15px !important;
  }
  .pt-xxl-16px {
    padding-top: 16px !important;
  }
  .pt-xxl-18px {
    padding-top: 18px !important;
  }
  .pt-xxl-24px {
    padding-top: 24px !important;
  }
  .pt-xxl-25px {
    padding-top: 25px !important;
  }
  .pt-xxl-30px {
    padding-top: 30px !important;
  }
  .pt-xxl-32px {
    padding-top: 32px !important;
  }
  .pt-xxl-57px {
    padding-top: 57px !important;
  }
  .pt-xxl-n15 {
    padding-top: -15px !important;
  }
  .pt-xxl-n5 {
    padding-top: -5px !important;
  }
  .pt-xxl-3vh {
    padding-top: 3vh !important;
  }
  .pt-xxl-10vh {
    padding-top: 10vh !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.6667rem !important;
  }
  .pt-xxl-2 {
    padding-top: 1.3334rem !important;
  }
  .pt-xxl-3 {
    padding-top: 2.0001rem !important;
  }
  .pt-xxl-4 {
    padding-top: 2.6668rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3.3335rem !important;
  }
  .pt-xxl-6 {
    padding-top: 4.0002rem !important;
  }
  .pt-xxl-7 {
    padding-top: 4.6669rem !important;
  }
  .pt-xxl-8 {
    padding-top: 5.3336rem !important;
  }
  .pt-xxl-9 {
    padding-top: 6.0003rem !important;
  }
  .pt-xxl-10 {
    padding-top: 6.667rem !important;
  }
  .pt-xxl-gutter {
    padding-top: 24px !important;
  }
  .pt-xxl-out-container {
    padding-top: calc(50% - 50vw) !important;
  }
  .pe-xxl-sm {
    padding-right: 0.33335rem !important;
  }
  .pe-xxl-3px {
    padding-right: 3px !important;
  }
  .pe-xxl-4px {
    padding-right: 4px !important;
  }
  .pe-xxl-8px {
    padding-right: 8px !important;
  }
  .pe-xxl-12px {
    padding-right: 12px !important;
  }
  .pe-xxl-15px {
    padding-right: 15px !important;
  }
  .pe-xxl-16px {
    padding-right: 16px !important;
  }
  .pe-xxl-18px {
    padding-right: 18px !important;
  }
  .pe-xxl-24px {
    padding-right: 24px !important;
  }
  .pe-xxl-25px {
    padding-right: 25px !important;
  }
  .pe-xxl-30px {
    padding-right: 30px !important;
  }
  .pe-xxl-32px {
    padding-right: 32px !important;
  }
  .pe-xxl-57px {
    padding-right: 57px !important;
  }
  .pe-xxl-n15 {
    padding-right: -15px !important;
  }
  .pe-xxl-n5 {
    padding-right: -5px !important;
  }
  .pe-xxl-3vh {
    padding-right: 3vh !important;
  }
  .pe-xxl-10vh {
    padding-right: 10vh !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.6667rem !important;
  }
  .pe-xxl-2 {
    padding-right: 1.3334rem !important;
  }
  .pe-xxl-3 {
    padding-right: 2.0001rem !important;
  }
  .pe-xxl-4 {
    padding-right: 2.6668rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3.3335rem !important;
  }
  .pe-xxl-6 {
    padding-right: 4.0002rem !important;
  }
  .pe-xxl-7 {
    padding-right: 4.6669rem !important;
  }
  .pe-xxl-8 {
    padding-right: 5.3336rem !important;
  }
  .pe-xxl-9 {
    padding-right: 6.0003rem !important;
  }
  .pe-xxl-10 {
    padding-right: 6.667rem !important;
  }
  .pe-xxl-gutter {
    padding-right: 24px !important;
  }
  .pe-xxl-out-container {
    padding-right: calc(50% - 50vw) !important;
  }
  .pb-xxl-sm {
    padding-bottom: 0.33335rem !important;
  }
  .pb-xxl-3px {
    padding-bottom: 3px !important;
  }
  .pb-xxl-4px {
    padding-bottom: 4px !important;
  }
  .pb-xxl-8px {
    padding-bottom: 8px !important;
  }
  .pb-xxl-12px {
    padding-bottom: 12px !important;
  }
  .pb-xxl-15px {
    padding-bottom: 15px !important;
  }
  .pb-xxl-16px {
    padding-bottom: 16px !important;
  }
  .pb-xxl-18px {
    padding-bottom: 18px !important;
  }
  .pb-xxl-24px {
    padding-bottom: 24px !important;
  }
  .pb-xxl-25px {
    padding-bottom: 25px !important;
  }
  .pb-xxl-30px {
    padding-bottom: 30px !important;
  }
  .pb-xxl-32px {
    padding-bottom: 32px !important;
  }
  .pb-xxl-57px {
    padding-bottom: 57px !important;
  }
  .pb-xxl-n15 {
    padding-bottom: -15px !important;
  }
  .pb-xxl-n5 {
    padding-bottom: -5px !important;
  }
  .pb-xxl-3vh {
    padding-bottom: 3vh !important;
  }
  .pb-xxl-10vh {
    padding-bottom: 10vh !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.6667rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 1.3334rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 2.0001rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 2.6668rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3.3335rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 4.0002rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 4.6669rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 5.3336rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 6.0003rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 6.667rem !important;
  }
  .pb-xxl-gutter {
    padding-bottom: 24px !important;
  }
  .pb-xxl-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }
  .ps-xxl-sm {
    padding-left: 0.33335rem !important;
  }
  .ps-xxl-3px {
    padding-left: 3px !important;
  }
  .ps-xxl-4px {
    padding-left: 4px !important;
  }
  .ps-xxl-8px {
    padding-left: 8px !important;
  }
  .ps-xxl-12px {
    padding-left: 12px !important;
  }
  .ps-xxl-15px {
    padding-left: 15px !important;
  }
  .ps-xxl-16px {
    padding-left: 16px !important;
  }
  .ps-xxl-18px {
    padding-left: 18px !important;
  }
  .ps-xxl-24px {
    padding-left: 24px !important;
  }
  .ps-xxl-25px {
    padding-left: 25px !important;
  }
  .ps-xxl-30px {
    padding-left: 30px !important;
  }
  .ps-xxl-32px {
    padding-left: 32px !important;
  }
  .ps-xxl-57px {
    padding-left: 57px !important;
  }
  .ps-xxl-n15 {
    padding-left: -15px !important;
  }
  .ps-xxl-n5 {
    padding-left: -5px !important;
  }
  .ps-xxl-3vh {
    padding-left: 3vh !important;
  }
  .ps-xxl-10vh {
    padding-left: 10vh !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.6667rem !important;
  }
  .ps-xxl-2 {
    padding-left: 1.3334rem !important;
  }
  .ps-xxl-3 {
    padding-left: 2.0001rem !important;
  }
  .ps-xxl-4 {
    padding-left: 2.6668rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3.3335rem !important;
  }
  .ps-xxl-6 {
    padding-left: 4.0002rem !important;
  }
  .ps-xxl-7 {
    padding-left: 4.6669rem !important;
  }
  .ps-xxl-8 {
    padding-left: 5.3336rem !important;
  }
  .ps-xxl-9 {
    padding-left: 6.0003rem !important;
  }
  .ps-xxl-10 {
    padding-left: 6.667rem !important;
  }
  .ps-xxl-gutter {
    padding-left: 24px !important;
  }
  .ps-xxl-out-container {
    padding-left: calc(50% - 50vw) !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-sm {
    gap: 0.33335rem !important;
  }
  .gap-xxl-3px {
    gap: 3px !important;
  }
  .gap-xxl-4px {
    gap: 4px !important;
  }
  .gap-xxl-8px {
    gap: 8px !important;
  }
  .gap-xxl-12px {
    gap: 12px !important;
  }
  .gap-xxl-15px {
    gap: 15px !important;
  }
  .gap-xxl-16px {
    gap: 16px !important;
  }
  .gap-xxl-18px {
    gap: 18px !important;
  }
  .gap-xxl-24px {
    gap: 24px !important;
  }
  .gap-xxl-25px {
    gap: 25px !important;
  }
  .gap-xxl-30px {
    gap: 30px !important;
  }
  .gap-xxl-32px {
    gap: 32px !important;
  }
  .gap-xxl-57px {
    gap: 57px !important;
  }
  .gap-xxl-n15 {
    gap: -15px !important;
  }
  .gap-xxl-n5 {
    gap: -5px !important;
  }
  .gap-xxl-3vh {
    gap: 3vh !important;
  }
  .gap-xxl-10vh {
    gap: 10vh !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.6667rem !important;
  }
  .gap-xxl-2 {
    gap: 1.3334rem !important;
  }
  .gap-xxl-3 {
    gap: 2.0001rem !important;
  }
  .gap-xxl-4 {
    gap: 2.6668rem !important;
  }
  .gap-xxl-5 {
    gap: 3.3335rem !important;
  }
  .gap-xxl-6 {
    gap: 4.0002rem !important;
  }
  .gap-xxl-7 {
    gap: 4.6669rem !important;
  }
  .gap-xxl-8 {
    gap: 5.3336rem !important;
  }
  .gap-xxl-9 {
    gap: 6.0003rem !important;
  }
  .gap-xxl-10 {
    gap: 6.667rem !important;
  }
  .gap-xxl-gutter {
    gap: 24px !important;
  }
  .gap-xxl-out-container {
    gap: calc(50% - 50vw) !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .vh-xxl-30 {
    height: 30vh !important;
  }
  .vh-xxl-40 {
    height: 40vh !important;
  }
  .vh-xxl-50 {
    height: 50vh !important;
  }
  .vh-xxl-60 {
    height: 60vh !important;
  }
  .vh-xxl-65 {
    height: 65vh !important;
  }
  .vh-xxl-70 {
    height: 70vh !important;
  }
  .vh-xxl-80 {
    height: 80vh !important;
  }
  .vh-xxl-100 {
    height: 100vh !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-35 {
    width: 35% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-45 {
    width: 45% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-55 {
    width: 55% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-64 {
    width: 64% !important;
  }
  .w-xxl-65 {
    width: 65% !important;
  }
  .w-xxl-70 {
    width: 70% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-82 {
    width: 82% !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .w-xxl-20px {
    width: 20px !important;
  }
  .w-xxl-80px {
    width: 80px !important;
  }
  .w-xxl-icon {
    width: 24px !important;
  }
  .w-xxl-fit {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .h-xxl-20px {
    height: 20px !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .h-xxl-icon {
    height: 24px !important;
  }
  .h-xxl-400 {
    height: 400px !important;
  }
  .h-xxl-550 {
    height: 550px !important;
  }
  .h-xxl-fit {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .fs-xxl-1 {
    font-size: 3.5rem !important;
  }
  .fs-xxl-2 {
    font-size: 2rem !important;
  }
  .fs-xxl-3 {
    font-size: 1.5rem !important;
  }
  .fs-xxl-4 {
    font-size: 1.25rem !important;
  }
  .fs-xxl-5 {
    font-size: 1.125rem !important;
  }
  .fs-xxl-6 {
    font-size: 1rem !important;
  }
  .fs-xxl-7 {
    font-size: 0.875rem !important;
  }
  .fs-xxl-22 {
    font-size: 1.375rem !important;
  }
  .fs-xxl-40 {
    font-size: 2.5rem !important;
  }
  .fs-xxl-48 {
    font-size: 3rem !important;
  }
  .fs-xxl-lg {
    font-size: 3.375rem !important;
  }
  .fs-xxl-xl {
    font-size: 5rem !important;
  }
  .fs-xxl-sm {
    font-size: 0.75rem !important;
  }
  .fs-xxl-icon {
    font-size: 1.5625rem !important;
  }
  .lh-xxl-1 {
    line-height: 1 !important;
  }
  .lh-xxl-sm {
    line-height: 1.25 !important;
  }
  .lh-xxl-base {
    line-height: 1.5 !important;
  }
  .lh-xxl-md {
    line-height: 1.65 !important;
  }
  .lh-xxl-lg {
    line-height: 2 !important;
  }
  .lh-xxl-3 {
    line-height: 3 !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .order-xxl-unset {
    order: unset !important;
  }
  .cursor-xxl-auto {
    cursor: auto !important;
  }
  .cursor-xxl-pointer {
    cursor: pointer !important;
  }
  .cursor-xxl-grab {
    cursor: -webkit-grab !important;
    cursor: grab !important;
  }
  .border-xxl {
    border: 1px solid #d0cfcd !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-xxl-2 {
    border: 2px solid !important;
  }
  .border-top-xxl {
    border-top: 1px solid #d0cfcd !important;
  }
  .border-top-xxl-0 {
    border-top: 0 !important;
  }
  .border-start-xxl {
    border-left: 1px solid #d0cfcd !important;
  }
  .border-start-xxl-0 {
    border-left: 0 !important;
  }
  .border-end-xxl {
    border-right: 1px solid #d0cfcd !important;
  }
  .border-end-xxl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xxl {
    border-bottom: 1px solid #d0cfcd !important;
  }
  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }
  .shadow-xxl {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  }
  .shadow-xxl-small {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xxl-large {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05) !important;
  }
  .shadow-xxl-none {
    box-shadow: none !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
  .text-decoration-xxl-none {
    text-decoration: none !important;
  }
  .text-decoration-xxl-underline {
    text-decoration: underline !important;
  }
  .text-decoration-xxl-line-through {
    text-decoration: line-through !important;
  }
  .text-xxl-lowercase {
    text-transform: lowercase !important;
  }
  .text-xxl-uppercase {
    text-transform: uppercase !important;
  }
  .text-xxl-capitalize {
    text-transform: capitalize !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: sticky !important;
  }
  .top-xxl-0 {
    top: 0 !important;
  }
  .top-xxl-25 {
    top: 25% !important;
  }
  .top-xxl-50 {
    top: 50% !important;
  }
  .top-xxl-75 {
    top: 75% !important;
  }
  .top-xxl-100 {
    top: 100% !important;
  }
  .top-xxl-10 {
    top: 10px !important;
  }
  .top-xxl-20 {
    top: 20px !important;
  }
  .top-xxl-30 {
    top: 30px !important;
  }
  .top-xxl-n10 {
    top: -10px !important;
  }
  .top-xxl-n20 {
    top: -20px !important;
  }
  .top-xxl-n8 {
    top: -8px !important;
  }
  .top-xxl-7 {
    top: 7px !important;
  }
  .top-xxl-13 {
    top: 13px !important;
  }
  .top-xxl-15 {
    top: 15px !important;
  }
  .top-xxl-n3 {
    top: -3px !important;
  }
  .top-xxl-5 {
    top: 5px !important;
  }
  .top-xxl-2 {
    top: 2px !important;
  }
  .bottom-xxl-0 {
    bottom: 0 !important;
  }
  .bottom-xxl-25 {
    bottom: 25% !important;
  }
  .bottom-xxl-50 {
    bottom: 50% !important;
  }
  .bottom-xxl-75 {
    bottom: 75% !important;
  }
  .bottom-xxl-100 {
    bottom: 100% !important;
  }
  .bottom-xxl-10 {
    bottom: 10px !important;
  }
  .bottom-xxl-20 {
    bottom: 20px !important;
  }
  .bottom-xxl-30 {
    bottom: 30px !important;
  }
  .bottom-xxl-n10 {
    bottom: -10px !important;
  }
  .bottom-xxl-n20 {
    bottom: -20px !important;
  }
  .bottom-xxl-n8 {
    bottom: -8px !important;
  }
  .bottom-xxl-7 {
    bottom: 7px !important;
  }
  .bottom-xxl-13 {
    bottom: 13px !important;
  }
  .bottom-xxl-15 {
    bottom: 15px !important;
  }
  .bottom-xxl-n3 {
    bottom: -3px !important;
  }
  .bottom-xxl-5 {
    bottom: 5px !important;
  }
  .bottom-xxl-2 {
    bottom: 2px !important;
  }
  .end-xxl-0 {
    right: 0 !important;
  }
  .end-xxl-25 {
    right: 25% !important;
  }
  .end-xxl-50 {
    right: 50% !important;
  }
  .end-xxl-75 {
    right: 75% !important;
  }
  .end-xxl-100 {
    right: 100% !important;
  }
  .end-xxl-10 {
    right: 10px !important;
  }
  .end-xxl-20 {
    right: 20px !important;
  }
  .end-xxl-30 {
    right: 30px !important;
  }
  .end-xxl-n10 {
    right: -10px !important;
  }
  .end-xxl-n20 {
    right: -20px !important;
  }
  .end-xxl-n8 {
    right: -8px !important;
  }
  .end-xxl-7 {
    right: 7px !important;
  }
  .end-xxl-13 {
    right: 13px !important;
  }
  .end-xxl-15 {
    right: 15px !important;
  }
  .end-xxl-n3 {
    right: -3px !important;
  }
  .end-xxl-5 {
    right: 5px !important;
  }
  .end-xxl-2 {
    right: 2px !important;
  }
  .translate-middle-xxl {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-xxl-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-xxl-y {
    transform: translateY(-50%) !important;
  }
  .ls-xxl-1 {
    letter-spacing: 1px !important;
  }
  .ls-xxl-sm {
    letter-spacing: 1.4px !important;
  }
  .ls-xxl-button {
    letter-spacing: 1.5px !important;
  }
  .ls-xxl-2 {
    letter-spacing: 2px !important;
  }
  .ls-xxl-3 {
    letter-spacing: 3px !important;
  }
  .ls-xxl-4 {
    letter-spacing: 4px !important;
  }
  .ls-xxl-5 {
    letter-spacing: 5px !important;
  }
  .mx-xxl-nsm {
    margin-right: -0.33335rem !important;
    margin-left: -0.33335rem !important;
  }
  .mx-xxl-n3px {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .mx-xxl-n4px {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }
  .mx-xxl-n8px {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }
  .mx-xxl-n12px {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }
  .mx-xxl-n15px {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-xxl-n16px {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }
  .mx-xxl-n18px {
    margin-right: -18px !important;
    margin-left: -18px !important;
  }
  .mx-xxl-n24px {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }
  .mx-xxl-n25px {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .mx-xxl-n30px {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .mx-xxl-n32px {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }
  .mx-xxl-n57px {
    margin-right: -57px !important;
    margin-left: -57px !important;
  }
  .mx-xxl-nn15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-xxl-nn5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-xxl-n3vh {
    margin-right: -3vh !important;
    margin-left: -3vh !important;
  }
  .mx-xxl-n10vh {
    margin-right: -10vh !important;
    margin-left: -10vh !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.6667rem !important;
    margin-left: -0.6667rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -1.3334rem !important;
    margin-left: -1.3334rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -2.0001rem !important;
    margin-left: -2.0001rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -2.6668rem !important;
    margin-left: -2.6668rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3.3335rem !important;
    margin-left: -3.3335rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -4.0002rem !important;
    margin-left: -4.0002rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -4.6669rem !important;
    margin-left: -4.6669rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -5.3336rem !important;
    margin-left: -5.3336rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -6.0003rem !important;
    margin-left: -6.0003rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -6.667rem !important;
    margin-left: -6.667rem !important;
  }
  .mx-xxl-ngutter {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }
  .mx-xxl-nout-container {
    margin-right: -calc(50% - 50vw) !important;
    margin-left: -calc(50% - 50vw) !important;
  }
  .my-xxl-nsm {
    margin-top: -0.33335rem !important;
    margin-bottom: -0.33335rem !important;
  }
  .my-xxl-n3px {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .my-xxl-n4px {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .my-xxl-n8px {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .my-xxl-n12px {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .my-xxl-n15px {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-xxl-n16px {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .my-xxl-n18px {
    margin-top: -18px !important;
    margin-bottom: -18px !important;
  }
  .my-xxl-n24px {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .my-xxl-n25px {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .my-xxl-n30px {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .my-xxl-n32px {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .my-xxl-n57px {
    margin-top: -57px !important;
    margin-bottom: -57px !important;
  }
  .my-xxl-nn15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-xxl-nn5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-xxl-n3vh {
    margin-top: -3vh !important;
    margin-bottom: -3vh !important;
  }
  .my-xxl-n10vh {
    margin-top: -10vh !important;
    margin-bottom: -10vh !important;
  }
  .my-xxl-n1 {
    margin-top: -0.6667rem !important;
    margin-bottom: -0.6667rem !important;
  }
  .my-xxl-n2 {
    margin-top: -1.3334rem !important;
    margin-bottom: -1.3334rem !important;
  }
  .my-xxl-n3 {
    margin-top: -2.0001rem !important;
    margin-bottom: -2.0001rem !important;
  }
  .my-xxl-n4 {
    margin-top: -2.6668rem !important;
    margin-bottom: -2.6668rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3.3335rem !important;
    margin-bottom: -3.3335rem !important;
  }
  .my-xxl-n6 {
    margin-top: -4.0002rem !important;
    margin-bottom: -4.0002rem !important;
  }
  .my-xxl-n7 {
    margin-top: -4.6669rem !important;
    margin-bottom: -4.6669rem !important;
  }
  .my-xxl-n8 {
    margin-top: -5.3336rem !important;
    margin-bottom: -5.3336rem !important;
  }
  .my-xxl-n9 {
    margin-top: -6.0003rem !important;
    margin-bottom: -6.0003rem !important;
  }
  .my-xxl-n10 {
    margin-top: -6.667rem !important;
    margin-bottom: -6.667rem !important;
  }
  .my-xxl-ngutter {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .my-xxl-nout-container {
    margin-top: -calc(50% - 50vw) !important;
    margin-bottom: -calc(50% - 50vw) !important;
  }
  .mt-xxl-nsm {
    margin-top: -0.33335rem !important;
  }
  .mt-xxl-n3px {
    margin-top: -3px !important;
  }
  .mt-xxl-n4px {
    margin-top: -4px !important;
  }
  .mt-xxl-n8px {
    margin-top: -8px !important;
  }
  .mt-xxl-n12px {
    margin-top: -12px !important;
  }
  .mt-xxl-n15px {
    margin-top: -15px !important;
  }
  .mt-xxl-n16px {
    margin-top: -16px !important;
  }
  .mt-xxl-n18px {
    margin-top: -18px !important;
  }
  .mt-xxl-n24px {
    margin-top: -24px !important;
  }
  .mt-xxl-n25px {
    margin-top: -25px !important;
  }
  .mt-xxl-n30px {
    margin-top: -30px !important;
  }
  .mt-xxl-n32px {
    margin-top: -32px !important;
  }
  .mt-xxl-n57px {
    margin-top: -57px !important;
  }
  .mt-xxl-nn15 {
    margin-top: 15px !important;
  }
  .mt-xxl-nn5 {
    margin-top: 5px !important;
  }
  .mt-xxl-n3vh {
    margin-top: -3vh !important;
  }
  .mt-xxl-n10vh {
    margin-top: -10vh !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.6667rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -1.3334rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -2.0001rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -2.6668rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3.3335rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -4.0002rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -4.6669rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -5.3336rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -6.0003rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -6.667rem !important;
  }
  .mt-xxl-ngutter {
    margin-top: -24px !important;
  }
  .mt-xxl-nout-container {
    margin-top: -calc(50% - 50vw) !important;
  }
  .me-xxl-nsm {
    margin-right: -0.33335rem !important;
  }
  .me-xxl-n3px {
    margin-right: -3px !important;
  }
  .me-xxl-n4px {
    margin-right: -4px !important;
  }
  .me-xxl-n8px {
    margin-right: -8px !important;
  }
  .me-xxl-n12px {
    margin-right: -12px !important;
  }
  .me-xxl-n15px {
    margin-right: -15px !important;
  }
  .me-xxl-n16px {
    margin-right: -16px !important;
  }
  .me-xxl-n18px {
    margin-right: -18px !important;
  }
  .me-xxl-n24px {
    margin-right: -24px !important;
  }
  .me-xxl-n25px {
    margin-right: -25px !important;
  }
  .me-xxl-n30px {
    margin-right: -30px !important;
  }
  .me-xxl-n32px {
    margin-right: -32px !important;
  }
  .me-xxl-n57px {
    margin-right: -57px !important;
  }
  .me-xxl-nn15 {
    margin-right: 15px !important;
  }
  .me-xxl-nn5 {
    margin-right: 5px !important;
  }
  .me-xxl-n3vh {
    margin-right: -3vh !important;
  }
  .me-xxl-n10vh {
    margin-right: -10vh !important;
  }
  .me-xxl-n1 {
    margin-right: -0.6667rem !important;
  }
  .me-xxl-n2 {
    margin-right: -1.3334rem !important;
  }
  .me-xxl-n3 {
    margin-right: -2.0001rem !important;
  }
  .me-xxl-n4 {
    margin-right: -2.6668rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3.3335rem !important;
  }
  .me-xxl-n6 {
    margin-right: -4.0002rem !important;
  }
  .me-xxl-n7 {
    margin-right: -4.6669rem !important;
  }
  .me-xxl-n8 {
    margin-right: -5.3336rem !important;
  }
  .me-xxl-n9 {
    margin-right: -6.0003rem !important;
  }
  .me-xxl-n10 {
    margin-right: -6.667rem !important;
  }
  .me-xxl-ngutter {
    margin-right: -24px !important;
  }
  .me-xxl-nout-container {
    margin-right: -calc(50% - 50vw) !important;
  }
  .mb-xxl-nsm {
    margin-bottom: -0.33335rem !important;
  }
  .mb-xxl-n3px {
    margin-bottom: -3px !important;
  }
  .mb-xxl-n4px {
    margin-bottom: -4px !important;
  }
  .mb-xxl-n8px {
    margin-bottom: -8px !important;
  }
  .mb-xxl-n12px {
    margin-bottom: -12px !important;
  }
  .mb-xxl-n15px {
    margin-bottom: -15px !important;
  }
  .mb-xxl-n16px {
    margin-bottom: -16px !important;
  }
  .mb-xxl-n18px {
    margin-bottom: -18px !important;
  }
  .mb-xxl-n24px {
    margin-bottom: -24px !important;
  }
  .mb-xxl-n25px {
    margin-bottom: -25px !important;
  }
  .mb-xxl-n30px {
    margin-bottom: -30px !important;
  }
  .mb-xxl-n32px {
    margin-bottom: -32px !important;
  }
  .mb-xxl-n57px {
    margin-bottom: -57px !important;
  }
  .mb-xxl-nn15 {
    margin-bottom: 15px !important;
  }
  .mb-xxl-nn5 {
    margin-bottom: 5px !important;
  }
  .mb-xxl-n3vh {
    margin-bottom: -3vh !important;
  }
  .mb-xxl-n10vh {
    margin-bottom: -10vh !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.6667rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -1.3334rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -2.0001rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -2.6668rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3.3335rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -4.0002rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -4.6669rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -5.3336rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -6.0003rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -6.667rem !important;
  }
  .mb-xxl-ngutter {
    margin-bottom: -24px !important;
  }
  .mb-xxl-nout-container {
    margin-bottom: -calc(50% - 50vw) !important;
  }
  .ms-xxl-nsm {
    margin-left: -0.33335rem !important;
  }
  .ms-xxl-n3px {
    margin-left: -3px !important;
  }
  .ms-xxl-n4px {
    margin-left: -4px !important;
  }
  .ms-xxl-n8px {
    margin-left: -8px !important;
  }
  .ms-xxl-n12px {
    margin-left: -12px !important;
  }
  .ms-xxl-n15px {
    margin-left: -15px !important;
  }
  .ms-xxl-n16px {
    margin-left: -16px !important;
  }
  .ms-xxl-n18px {
    margin-left: -18px !important;
  }
  .ms-xxl-n24px {
    margin-left: -24px !important;
  }
  .ms-xxl-n25px {
    margin-left: -25px !important;
  }
  .ms-xxl-n30px {
    margin-left: -30px !important;
  }
  .ms-xxl-n32px {
    margin-left: -32px !important;
  }
  .ms-xxl-n57px {
    margin-left: -57px !important;
  }
  .ms-xxl-nn15 {
    margin-left: 15px !important;
  }
  .ms-xxl-nn5 {
    margin-left: 5px !important;
  }
  .ms-xxl-n3vh {
    margin-left: -3vh !important;
  }
  .ms-xxl-n10vh {
    margin-left: -10vh !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.6667rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -1.3334rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -2.0001rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -2.6668rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3.3335rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -4.0002rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -4.6669rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -5.3336rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -6.0003rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -6.667rem !important;
  }
  .ms-xxl-ngutter {
    margin-left: -24px !important;
  }
  .ms-xxl-nout-container {
    margin-left: -calc(50% - 50vw) !important;
  }
  .list-style-xxl-none {
    list-style: none !important;
  }
  .list-style-xxl-circle {
    list-style: circle !important;
  }
  .list-style-xxl-disc {
    list-style: disc !important;
  }
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

@media (min-width: 769px) {
  .navbar-expand-mdlg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-mdlg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-mdlg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-mdlg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-mdlg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-mdlg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-mdlg .navbar-toggler {
    display: none;
  }
  .navbar-expand-mdlg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-mdlg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-mdlg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

@media (min-width: 769px) {
  .list-group-horizontal-mdlg {
    flex-direction: row;
  }
  .list-group-horizontal-mdlg > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-mdlg > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-mdlg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-mdlg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-mdlg > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 769px) {
  .offcanvas-mdlg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-mdlg .offcanvas-header {
    display: none;
  }
  .offcanvas-mdlg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (min-width: 1600px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (min-width: 769px) {
  .dropdown-menu-mdlg-start {
    --bs-position: start;
  }
  .dropdown-menu-mdlg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-mdlg-end {
    --bs-position: end;
  }
  .dropdown-menu-mdlg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1600px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}


@media (min-width: 992px) {
  body.admin-bar header {
    top: 32px !important;
  }
  body header {
    height: 100px;
    transition: all 0.5s ease;
  }
  body header .header-wrapper {
    padding-bottom: 0;
    transition: all 0.5s ease;
  }
  body header .header-wrapper ul.menu-wrap > li > a {
    margin-right: 0;
    font-size: 0.875rem;
    font-weight: 700;
    color: #0a3255;
    transition: all 0.5s ease;
    position: relative;
  }
  body header .header-wrapper ul.menu-wrap > li > a::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    transition: all 0.5s ease;
  }
  body header .header-wrapper ul.menu-wrap > li:last-child a {
    margin-right: 0;
    padding-right: 0;
  }
  body header .header-wrapper ul.menu-wrap > li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
  }
  body header .header-wrapper ul.menu-wrap > li.menu-item-has-children > a {
    position: relative;
  }
  body header .header-wrapper ul.menu-wrap > li.menu-item-has-children > a::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 8px;
    height: 5px;
    background-size: contain;
  }
  body header .header-wrapper ul.menu-wrap > li.current_page_item a, body header .header-wrapper ul.menu-wrap > li.current-page-ancestor a, body header .header-wrapper ul.menu-wrap > li.current-menu-item a, body header .header-wrapper ul.menu-wrap > li.current-post-ancestor a, body header .header-wrapper ul.menu-wrap > li:hover a {
    color: #d7282f;
  }
  body header .header-wrapper ul.menu-wrap > li.current_page_item a::after, body header .header-wrapper ul.menu-wrap > li.current-page-ancestor a::after, body header .header-wrapper ul.menu-wrap > li.current-menu-item a::after, body header .header-wrapper ul.menu-wrap > li.current-post-ancestor a::after, body header .header-wrapper ul.menu-wrap > li:hover a::after {
    background-color: #d7282f;
  }
  body header.small .header-wrapper {
    padding-top: 0;
  }
  body header.small .header-wrapper ul.menu-wrap > li a {
    padding: 26px 20px 26px 0;
  }
  body header.small .header-wrapper .sub-menu {
    top: 70px;
  }
  body header.small .logo {
    padding: 0;
  }
  body header.small .logo img {
    width: 110px;
  }
  body header.small .collapse-search {
    top: 40px;
  }
  body header.hide {
    top: -100px !important;
  }
  body header .language-switcher {
    position: relative;
  }
  body .collapse-search {
    top: 100px;
  }
  body .collapse-search .backdrop {
    height: calc(100vh - 100px);
  }
  body .logo {
    transition: all 0.5s ease;
  }
  body .logo img {
    transition: all 0.5s ease;
  }
  body .search-wrapper input[type=search] {
    padding: 12px 55px 12px 22px;
  }
  body .search-wrapper .search-icon {
    right: 22px;
  }
  body .user-wrapper {
    width: 35px;
    height: 35px;
  }
}


@media (min-width: 1600px) {
  .home .properties-range-widget,
.who-we-are__container .properties-range-widget {
    position: relative;
  }
  .home .properties-range-widget::after,
.who-we-are__container .properties-range-widget::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/wp-content/themes/core5/images/Line.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .wpcf7 input {
    padding: 11px 22px;
  }
}

@media (min-width: 992px) {
  body {
    padding-top: 100px;
    margin-bottom: 0;
  }
  .modal-sm {
    max-width: 500px;
  }
  .home .properties-range-widget .wp-block-columns,
.who-we-are__container .properties-range-widget .wp-block-columns {
    gap: 0 !important;
  }
}